import React from 'react'
import '../css/homeform.css';

export default function Homeform(){
    return(
        <> 
            <div className="form_prnt pt25 pl35 pr35 pb35">
                <form className="custome_form">
                    <div className="form-row">
                        <div className="form-group col-md-6 pr20">
                            <label htmlFor="inputEmail4" className="font14 fw600">Your full name</label>
                            <input type="email" className="form-control" id="inputEmail4" placeholder="Enter full name" />
                        </div>
                        <div className="form-group col-md-6 pl20">
                            <label htmlFor="inputPassword4" className="font14 fw600">Email address</label>
                            <input type="text" className="form-control" id="inputPassword4" placeholder="Enter email address" />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6 pr20">
                            <label htmlFor="inlineFormInputGroup" className="font14 fw600">Mobile number</label>
                            <div className="input-group mb2">
                                <div className="input-group-prepend">
                                <div className="input-group-text fw500 font14 color212">+91-</div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Username" />
                            </div>
                        </div>
                        <div className="form-group col-md-6 pl20">
                            <label htmlFor="inputPassword4" className="font14 fw600">Date of birth</label>
                            <input type="text" className="form-control" id="inputPassword4" placeholder="Select the date" />
                            <span className="calendarIcon" />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6 pr20">
                            <label htmlFor="inputEmail4" className="font14 fw600">PAN number</label>
                            <input type="email" className="form-control" id="inputEmail4" placeholder="Enter PAN number" />
                        </div>
                    </div>
                </form>
            </div>
            <div className="formdevider" />

            <div className="p35">
                <form className="custome_form">
                    <div className="form-row">
                        <div className="form-group col-md-6 pr20">
                            <label htmlFor="inputEmail4" className="font14 fw600">Your full name</label>
                            <input type="email" className="form-control" id="inputEmail4" placeholder="Enter full name" />
                        </div>
                        <div className="form-group col-md-6 pl20">
                            <label htmlFor="inputPassword4" className="font14 fw600">Email address</label>
                            <input type="text" className="form-control" id="inputPassword4" placeholder="Enter email address" />
                        </div>
                    </div>
                    
                    <div className="form-row">
                        <div className="form-group col-md-6 pr20">
                            <label htmlFor="inputEmail4" className="font14 fw600">PAN number</label>
                            <input type="email" className="form-control" id="inputEmail4" placeholder="Enter PAN number" />
                        </div>
                        <div className="form-group col-md-6 pl20">
                            <label htmlFor="inputEmail4" className="font14 fw600">PAN number</label>
                            <input type="email" className="form-control" id="inputEmail4" placeholder="Enter PAN number" />
                        </div>
                    </div>
                </form>    
            </div>
            <div className="formdevider" /> 

            <div className="p35">
                <div>
                    <div className="font14 color212 fw600">Select your life stage among these</div>
                    <ul className="steps_tag font14 fw500 color182 mt15">
                        <li>
                            <label>
                                <input type="radio" Name="00" checked />
                                <span className="rdo_text_box">
                                    Single
                                </span>
                            </label>
                        </li>
                        <li>
                            <label>
                                <input type="radio" Name="00" />
                                <span className="rdo_text_box">
                                    Married with kids
                                </span>
                            </label>
                        </li>
                        <li>
                            <label>
                                <input type="radio" Name="00" />
                                <span className="rdo_text_box">
                                    Married without kids
                                </span>
                            </label>
                        </li>
                        <li>
                            <label>
                                <input type="radio" Name="00" />
                                <span className="rdo_text_box">
                                    Single parent with kids
                                </span>
                            </label>
                        </li>
                    </ul>
                </div>

                <div className="mt35">
                    <div className="font14 color212 fw600">Number of children in the family</div>
                    <div className="mt15 quantity_wpr">
                        <button>-</button>
                        <input type="text" className="font14 fw500 ml15 mr15" value="00" />
                        <button>+</button>
                    </div>
                </div>
            </div>


        </>
    )
}