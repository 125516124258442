import React, { Fragment, useState } from 'react'

import GoalScreen1 from '../../components/wizard/GoalScreen1'
import GoalScreen2 from '../../components/wizard/GoalScreen2'
import GoalScreen3 from '../../components/wizard/GoalScreen3'
import { nextsubStep, prevsubStep, nextStep } from '../../components/global'

export default function Goals({ StepChange, steps }) {


    const [substep, setSubstep] = useState(1);
    const [goalType, setGoalType] = useState(null);
    // eslint-disable-next-line
    const [name, setName] = useState(sessionStorage.getItem('full_name'));

    return (
        <Fragment>
            {substep === 1 && (<GoalScreen1 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} clientName={name} />)}
            {substep === 2 && (<GoalScreen2 setSubstep={setSubstep} substep={substep} StepChange={StepChange} steps={steps} nextStep={nextStep} nextsubStep={nextsubStep} prevsubStep={prevsubStep} setGoalType={setGoalType} clientName={name} />)}
            {substep === 3 && (<GoalScreen3 setSubstep={setSubstep} substep={substep} StepChange={StepChange} steps={steps} nextStep={nextStep} prevsubStep={prevsubStep} goalType={goalType} setGoalType={setGoalType} clientName={name} />)}
        </Fragment>
    )
}
