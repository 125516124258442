import React from 'react'
import '../css/protectionform.css';

export default function Protectionform(){
    return(
        <> 
            <div className="p25">


            <div>
                    <div className="color212 font14 fw600">Monthly passive income required <span className="colorfa8">*</span>
                    </div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                            <li>
                                <label>
                                    <input type="radio" Name="01" />
                                    <span className="rdo_text_box">
                                        &#8377;80,000 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="01" checked />
                                    <span className="rdo_text_box">
                                        &#8377;1,00,000
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="01" />
                                    <span className="rdo_text_box">
                                        &#8377;1,20,000
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="01" />
                                    <span className="rdo_text_box">
                                        &#8377;1,50,000 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <form className="custome_form searchinput">
                                    <div className="form-row">
                                        <div className="form-group">
                                            <input type="email" className="form-control font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter desired amount" />
                                            <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                        </div>
                                    </div>
                                </form>
                            </li>
                        </ul>
                </div>

                <div>
                    <div className="color212 font14 fw600">Do you have Term Insurance? <span className="colorfa8">*</span>
                    </div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                            <li>
                                <label>
                                    <input type="radio" Name="00" />
                                    <span className="rdo_text_box">
                                        Yes 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="00" />
                                    <span className="rdo_text_box">
                                        No 
                                    </span>
                                </label>
                            </li>
                        </ul>
                </div>

                <div className="form_prnt mt20">
                    <form className="custome_form">
                        <div className="form-row">
                            <div className="col-md-6 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw600">Sum assured</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" />
                                    <select className="custom-select" id="inputGroupSelect01">
                                        <option selected>Lakhs</option>
                                        <option value="1">1 Lakh</option>
                                        <option value="2">2 Lakh</option>
                                        <option value="3">3 Lakh</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                            <div className="col-md-6 pl20">
                                <label htmlFor="inputEmail4" className="font14 fw600">Premium amount</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" />
                                    <select className="custom-select" id="inputGroupSelect01">
                                        <option selected>Yearly</option>
                                        <option value="1">1 Year</option>
                                        <option value="2">2 Year</option>
                                        <option value="3">3 Year</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                        </div>
                        
                        
                    </form>
                </div>

                <div className="formdevider mt35 mb35" />
                
                <div>
                    <div className="color212 font16"><b>Add a new goal</b> (select one of the lifestyle goals)
                    </div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                            <li>
                                <label>
                                    <input type="radio" Name="02" />
                                    <span className="rdo_text_box">
                                        Own a house 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="02" />
                                    <span className="rdo_text_box">
                                        Buy a car 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="02" />
                                    <span className="rdo_text_box">
                                        Big vacation
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="02" />
                                    <span className="rdo_text_box">
                                        Big wedding 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="02" />
                                    <span className="rdo_text_box">
                                        Add custom goal
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="02" checked />
                                    <span className="rdo_text_box">
                                        10 year wealth plan
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="02" />
                                    <span className="rdo_text_box">
                                        5 year wealth plan
                                    </span>
                                </label>
                            </li>
                        </ul>
                </div>

                <div>
                    <div className="color212 font14 fw600">Does your spouse have Term Insurance? <span className="colorfa8">*</span>
                    </div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                            <li>
                                <label>
                                    <input type="radio" Name="00" />
                                    <span className="rdo_text_box">
                                        Yes 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="00" />
                                    <span className="rdo_text_box">
                                        No 
                                    </span>
                                </label>
                            </li>
                        </ul>
                </div>

                <div>
                    <div className="color212 font14 fw600">Does your spouse have Term Insurance? <span className="colorfa8">*</span>
                    </div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                            <li>
                                <label>
                                    <input type="checkbox" Name="00" />
                                    <span className="rdo_text_box">
                                        Naveen 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="checkbox" Name="00" />
                                    <span className="rdo_text_box">
                                        Spouse 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="checkbox" Name="00" />
                                    <span className="rdo_text_box">
                                        Kids 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="checkbox" Name="00" />
                                    <span className="rdo_text_box">
                                        Dependents 
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="checkbox" Name="00" />
                                    <span className="rdo_text_box">
                                        All 
                                    </span>
                                </label>
                            </li>
                        </ul>
                </div>

                <div className="form_prnt mt20">
                    <form className="custome_form">
                        <div className="form-row">
                            <div className="col-md-6 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw600">Sum assured</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" />
                                    <select className="custom-select" id="inputGroupSelect01">
                                        <option selected>Lakhs</option>
                                        <option value="1">1 Lakh</option>
                                        <option value="2">2 Lakh</option>
                                        <option value="3">3 Lakh</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                            <div className="col-md-6 pl20">
                                <label htmlFor="inputEmail4" className="font14 fw600">Premium amount</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" />
                                    <select className="custom-select" id="inputGroupSelect01">
                                        <option selected>Yearly</option>
                                        <option value="1">1 Year</option>
                                        <option value="2">2 Year</option>
                                        <option value="3">3 Year</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                        </div>
                        
                        
                    </form>
                </div>

                <div className="pt25">
                    <div className="addincome_wpr">
                        <div className="font16 fw600 color212">Naveen family’s earned income</div>
                        <div>
                            <button className="addincome_btn font12 color212 fw600">Add Income</button>
                        </div>
                    </div>

                    <div className="form_prnt mt20">
                        <form className="custome_form">
                            <div className="form-row">
                                <div className="col-md-6 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Naveen’s income <span className="colorfa8">*</span></label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" value="24,00,000" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Yearly</option>
                                            <option value="1">1 Year</option>
                                            <option value="2">2 Year</option>
                                            <option value="3">3 Year</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <div className="col-md-6 pl20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Spouse income</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" value="0" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Yearly</option>
                                            <option value="1">1 Year</option>
                                            <option value="2">2 Year</option>
                                            <option value="3">3 Year</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                            </div>

                            <div className="formdevider mt35 mb35" />

                            <div className="addincome_wpr">
                                <div className="font16 fw600 color212">Naveen family’s earned income</div>
                                <div>
                                    <button className="addincome_btn font12 color212 fw600">Add Income</button>
                                </div>
                            </div>

                            <div className="form-row mt25">
                                <div className="col-md-6 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Spouse income</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" value="0" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Lakhs</option>
                                            <option value="1">1 Lakh</option>
                                            <option value="2">2 Lakh</option>
                                            <option value="3">3 Lakh</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <div className="col-md-6 pl20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">HUF</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" value="0" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Yearly</option>
                                            <option value="1">1 Year</option>
                                            <option value="2">2 Year</option>
                                            <option value="3">3 Year</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row mt25">
                                <div className="col-md-6 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Other income</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" value="0" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Lakhs</option>
                                            <option value="1">1 Lakh</option>
                                            <option value="2">2 Lakh</option>
                                            <option value="3">3 Lakh</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </>
    )
}