import React, { Fragment, useState } from 'react'
import axioInstance from '../axiosInstance';
import { useForm } from 'react-hook-form'
//import SweetAlert from 'sweetalert2'

export default function ResetFirst({ setLoginStep, setLoginType, setLoginPara, loginPara, setPopupContent,  setShow , setPopupIcon, setPopupTitle}) {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [email, setEmail] = useState(loginPara.email);
  const backbtn = () => {
    setLoginStep(1);
  }

  const handleChange = (e) =>{
    setEmail(e.target.value);
  }

  const onSubmit = data => {

    setButtonSpinner(true);

    axioInstance.post(`profile/view`, { email: data.emailAddress })
      .then(function (response) {

        console.log(response.data);

        if (response.data) {

          setLoginPara(response.data);

          const Otpdata = {
            "full_name": response.data.first_name,
            "email": data.emailAddress,
            "otp_send_on": 'email',
            "otp_for": "resetpassword"
          };

          axioInstance.post(`send-reset-password-otp`, Otpdata).then(
            function (response, data) {
              setButtonSpinner(false);
              setLoginType('forgotpasswordOtp');
              setLoginStep(2);
            }
          )


        } else {
          setButtonSpinner(false);
          setPopupIcon('errorIcon')
          setPopupTitle('Warning');
          setPopupContent("No User Found");
          setShow(true);
        }

      });

  }

  return (
    <Fragment>

      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Reset Password</div>
          <div className="font13 fw500 color626">We will send you a reset OTP on your Email</div>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={backbtn}><span className="blueleftarrow mr5" /> Back</button>
        </div>
        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="font14 fw600 color212">Registered Email<span className="colorfa8 ml5">*</span></div>
            <div className="mt12 mobilefield">
              <input type="text" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" value={email} onChange={handleChange}/>
            </div>
            <span className="text-danger font11"> {errors.emailAddress && errors.emailAddress.message}</span>

            <button type="submit" className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Continue"}</button>
          </form>
        </div>
      </div>
    </Fragment>
  )
}
