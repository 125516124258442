import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
//import axioInstance from '../../components/axiosInstance';
//import questions from './mock.json';
import questions from './finnfitqna.json';
import SideImg from '../../img/BasicProfile.png';
import axios from 'axios';

export default function QnaLast({ setSteps }) {


    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    
    const [checked, setChecked] = useState(true);
    const handleChange = () => {
        setChecked(!checked);
    };

    const [disableBtn, setDisableBtn] = useState(false);

    const onSubmit = data => {
        sessionStorage.setItem("QnaLast", JSON.stringify(data));

        const postData = JSON.stringify({
            QnaFirst: JSON.parse(sessionStorage.getItem('QnaFirst')),
            QnaJson: JSON.parse(sessionStorage.getItem('QnaJson')),
            QnaLast: JSON.parse(sessionStorage.getItem('QnaLast')),
            Qna: questions,
            ref_url:window.location.href
        });


        setDisableBtn(true);

        const params = new URLSearchParams(window.location.search);
        let f_type = params.get('f_type');

        let tallyapiUrl = '';
        if(f_type === 'testing'){
            tallyapiUrl = 'https://lmslive.finnovate.in/php/tallyapiuat.php';
        }else{
            tallyapiUrl = 'https://lmslive.finnovate.in/php/tallyapi.php';
        }

        axios.post(tallyapiUrl, postData).then(
            function (res) {
                sessionStorage.setItem("userData", JSON.stringify(res.data));
                sessionStorage.setItem("tallyso_finn_is", JSON.stringify(res.data.id));
                console.log(sessionStorage.getItem('tallyso_finn_is'));
                //setSteps(4);

                // Get the current URL query string
               
                const queryString = window.location.search;
                window.location.href = 'finnfit-thank-you' + queryString;


                /*var json = sessionStorage.getItem("userData");
                console.log(JSON.parse(json).score_breakup);*/
            }
        )
    }

    // const [authorization_ , setAuthorization]= useState(true); 



    return (
        <Fragment>

            <div className="mainwrapper">

                <div className='form_title'>
                    <div className="mt30 font20 fw600">Almost there</div>
                    <div className="font15 color495">We never spam. And don't call without your permission. </div>
                </div>

                <div className="row formWpr mt25">
                    <div className="col-lg-8 formouter">
                        <ul className="form_swipe_tab font12 fw600">

                        </ul>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="formtitle flex_center justify_center">
                                <div>
                                    <div className="font20 fw600">Get my FinnFit Report</div>
                                    <div className="font15 color495 ">You will receive the report on email.</div>
                                </div>
                                <div className='d-none d-md-block'>

                                    <button className="bluebtn font13 fw600" type='submit' disabled={(disableBtn === true) ? true : !isDirty && !isValid}>Save
                                        <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                        </svg></span></button>
                                </div>
                            </div>

                            {/* Form */}
                            <div className="form_prnt pt25 pl35 pr35 pb35">
                                <div className="custome_form">

                                    <div className="form-row">

                                        <div className="form-group col-md-6 pr20">
                                            <label htmlFor="emailAddress" className="font14 fw600">Email address</label>
                                            <input type="text" className="form-control" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                                            <span className="text-danger"> {errors.emailAddress && errors.emailAddress.message}</span>
                                        </div>

                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-6 pr20">
                                            <label htmlFor="mobileNumber" className="font14 fw600">Mobile number</label>
                                            <div className="input-group mb2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text fw500 font14 color212">+91</div>
                                                </div>
                                                <input type="tel" className="form-control" id="mobileNumber" name="mobileNumber" autoComplete='off' {...register("mobileNumber", { required: 'Mobile Number is required', pattern: { value: /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, message: 'Invalid Mobile Number' } })} placeholder="Mobile Number" maxLength={10} />

                                            </div>
                                            <span className="text-danger"> {errors.mobileNumber && errors.mobileNumber.message}</span>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12 pr20">
                                            <label htmlFor="authorization" className="font15 color495 " >
                                                <input type="checkbox" value="1" checked={checked} onClick={() => handleChange()}
                                                    name="authorization" {...register("authorization", { required: false })} /> I, hereby authorize Finnovate to contact me via call, WhatsApp, Email or sms to discuss further regarding my financial fitness.
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12 pr20">
                                            <label className="font15 color495 " style={{ 'color': 'gray' }}>
                                                Your information is safe and protected by encryption.
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="saveBtn d-block d-sm-none">
                                <button type="submit" className="btn btn-primary" disabled={(disableBtn === true) ? true : !isDirty && !isValid}>Save and Continue <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" stroke-width="1.30435" stroke-miterlimit="10"></path></svg></span></button>
                            </div>

                        </form>
                        {/* Form End */}

                    </div>

                    <div className="col-lg-4 qouteouter d-none d-md-block">
                        <div className="qoutetext font18 fw500 color212 pl45 pr45">
                            <div className="qoutesign">
                                &#8220;
                            </div>
                            FinnFit is a customized financial fitness program that partners with doctors so that you can become well-off and enjoy the fruits of your labour, into your winter years.
                        </div>


                        <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                    </div>
                </div>



            </div>

            <div className="row mt50">
                <div className="col-lg-12">

                    <p className='font11'>Disclaimer:</p>

                    <p className='font11'>This financial fitness score is provided for educational and informational purposes only and is not intended to provide investment, tax, or legal advice. The score is calculated based on the information you provide and is not a guarantee of future performance or success. Your personal financial situation is unique, and the score may not reflect your true financial health. It is important to seek professional advice before making any financial decisions. The score is not a substitute for professional judgment and is subject to change without notice. The provider of this score assumes no responsibility for any errors or omissions in the information provided. By using this score, you agree to hold harmless the provider and its affiliates for any losses or damages that may result from any inaccuracies or incompleteness of the information.</p>

                    <p className='font11'> Registration granted by SEBI, membership of BASL and certification from NISM in no way
                        guarantee performance of the intermediary or provide any assurance of returns to investors</p>
                    <p className='font11'>Mutual Fund investments are subject to market risks, read all scheme related documents carefully.</p>
                    <p className='font11'>Past performance is not indicative of future returns. Please consider your specific investment
                        requirements, risk tolerance, goal, time frame, risk and reward balance and the cost associated with the
                        investment before choosing a fund, or designing a portfolio that suits your needs. Performance and returns
                        of any investment portfolio can neither be predicted<br />
                        nor guaranteed.</p>
                    <p className='font11'>SEBI RIA Registration No: INA000013518 | Type of Registration: Non-Individual | Validity of registration:
                        June 19, 2019 - Perpetual | Address: The Summit Business Park, 7th Floor, Unit No. 703, Andheri - Kurla
                        Rd, Behind Guru Nanak Petrol Pump, Opp. PVR Cinema, Andheri East, Mumbai – 400069 | Contact No. 9819022600
                        | Principal Officer details: Nehal Mota, Email : nehal.mota@finnovate.in, Phone: 9152022446 |
                        Corresponding Local SEBI Office : SEBI Office Address: Office of Investor Assistance and Education, SEBI
                        Bhavan Plot No.C4-A, G Block, Bandra Kurla Complex, Bandra (E), Mumbai - 400 051</p>
                    <p className='font11'>AMFI Registered Distributor - ARN 52128 | Platform Partner : BSE (Member code 17548) | IRDAI Corporate
                        Agency : CA0703 | CIN: U67190MH2007PTC172272 | GST No : 27AABCF1124C2ZC | Website – <a href="http://www.finnovate.in" target="_blank" rel='noreferrer'>www.finnovate.in</a> Email – <a href="mailto:hello@finnovate.in">hello@finnovate.in</a></p>

                </div>

            </div>
        </Fragment>
    )
}
