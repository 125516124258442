import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';
import { Dropdown } from 'react-bootstrap'

export default function ReportProfilePlanStatus() {

  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedPlanner, setSelectedPlanner] = useState('ALL');
  const [selectedRelManager, setSelectedRelManager] = useState('ALL');

  // State for the input values
  const [filters, setFilters] = useState({
    plan_status: ''
  });

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {

      const params = new URLSearchParams(window.location.search);
      const plan_status = params.get('plan_status') ? params.get('plan_status') : '';

      var PostData = '';

      if(plan_status){
        PostData = {
          plan_status: plan_status
        }
      }
      axioInstance.post(`admin/plan-profiles-status`, PostData).then(
        (response, data) => {
          //console.log(response.data);
          setData(response.data.data);
          setTableRowsData(response.data.data);
          setLoading(false);
        }
      );


      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);

  const uniquePlanners = [...new Set(data.map(item => item.admin_name))].filter(planner => planner);

  const uniqueRelManager = [...new Set(data.map(item => item.primary_rel_manager))].filter(manager => manager);

  useEffect(() => {
    let filteredData = data;

    if (selectedPlanner !== 'ALL') {
      filteredData = filteredData.filter(item => item.admin_name === selectedPlanner);
    }

    if (selectedRelManager !== 'ALL') {
      filteredData = filteredData.filter(item => item.primary_rel_manager === selectedRelManager);
    }

    setTableRowsData(filteredData);
  }, [selectedPlanner, selectedRelManager, data]);

  const columns = [
    {
      name: 'ID',
      selector: row => row.profile_id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.full_name,
      sortable: true,
    },
    {
      name: 'Primary RelManager',
      selector: row => row.primary_rel_manager,
      sortable: true,
    },
    {
      name: 'Primary Planner',
      selector: row => row.admin_name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      name: 'Status Date',
      selector: row => row.status_created_date,
      sortable: true,
    },
    {
      name: 'Monthly',
      selector: row => row.monthly_amount,
      sortable: true,
    },
    {
      name: 'Yearly',
      selector: row => row.yearly_amount,
      sortable: true,
    },
    {
      name: 'Increase By',
      selector: row => row.increase_by,
      sortable: true,
    },
    {
      name: 'Created',
      selector: row => row.created_date,
      sortable: true,
    }
  ];


  // eslint-disable-next-line
  const [relManager, setRelManager] = useState([])


  const [isActive, setActive] = useState(false);



  if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    );
  }




  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [id]: value
    }));
  };

  const handleFilter = () => {

    setLoading(true);

    const queryParams = new URLSearchParams(filters).toString();
    const url = `plan-profiles-status?${queryParams}`;
    window.location.href = url;

  }



  return (
    <Fragment>
      <AdminDashboardHeader />

      <div className="innercontainer isPlanProfilesTimelinePage mb40">
        <div className="mainwrapper">
          <div className='form_title'><div className="mt30 font21 fw600 color384">PLAN Profile Status</div></div>

          <div className='row mt25'>



            <div className="col-md-3">
              <label htmlFor="relManager">REL Manager:</label>
              <select id="relManager" onChange={(e) => setSelectedRelManager(e.target.value)} value={selectedRelManager} className="form-control">
                <option value="ALL">ALL</option>
                {uniqueRelManager.map(manager => (
                  <option key={manager} value={manager}>{manager}</option>
                ))}
              </select>

            </div>

            <div className="col-md-3">
              <label htmlFor="primaryPlanner">Primary Planner:</label>
              <select id="primaryPlanner" onChange={(e) => setSelectedPlanner(e.target.value)} value={selectedPlanner} className="form-control">
                <option value="ALL">ALL</option>
                {uniquePlanners.map(planner => (
                  <option key={planner} value={planner}>{planner}</option>
                ))}
              </select>

            </div>

            <div className="col-md-3">
              <label htmlFor="plan_status">Plan Status:</label>
              <select id="plan_status" name='plan_status' className="form-control" onChange={handleInputChange}>
                <option value="">ALL</option>
                <option value="1">In Progess</option>
                <option value="3">Plan Completed</option>
                <option value="5">Shared</option>
                <option value="7">Freezed</option>
                <option value="9">Execution Done</option>
              </select>
            </div>

            <div className="col-md-1 mt-4">
              <button className="btn btn-primary" type='button' onClick={handleFilter}>Filter</button>
            </div>
            <div className="col-md-1 mt-4">
              <button className="btn btn-secondary">Reset</button>
            </div>





            <div className='col-md-3'>
              <Dropdown className="mt-3">
                <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots">
                  Plan Reports
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='admin-dashboard'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Admin Dashboard</Dropdown.Item>
                  <Dropdown.Item href='plan-profiles-timeline'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Profile Timeline</Dropdown.Item>
                  <Dropdown.Item href='plan-summary-report-drilldown-month'><i className="fas fa-bar-chart text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Summary Month Wise</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>


          </div>


          <div className="">
            <div className="dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableRowsData}
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        (isActive === true) && (<AddTestProfileSidebar setActive={setActive} />)
      }

    </Fragment>
  );
}