// PlanSummaryChart.js
import React from 'react';
import Chart from 'react-apexcharts';

const PlanSummaryChart = ({rawData}) => {
  const data = {
    months: rawData.months,
    execution: rawData.execution,
    freezed: rawData.freezed,
    new: rawData.new,
    shared: rawData.shared,
    completed:rawData.completed
  };

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.months,
    },
    yaxis: {
      title: {
        text: 'Total',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
  };

  const chartSeries = [
    {
      name: 'New',
      data: data.new,
    },
    {
      name: 'Completed',
      data: data.completed,
    },
    {
      name: 'Shared',
      data: data.shared,
    },
    {
      name: 'Freeze',
      data: data.freezed,
    },
    {
      name: 'Execution Done',
      data: data.execution,
    },
  ];

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default PlanSummaryChart;
