import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
import SweetAlert from 'sweetalert2'

export default function EditEducation({ setGoalType, goalId, planLatestStatus }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [state, setState] = useState(false);
  const [goalData, setGoalData] = useState([]);

  const Back = () => {
    setGoalType('');
  }
  const [validate, setValidate] = useState(null);

  useEffect(() => {

    setState(true);
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null && state !== false) {
      axioInstance.post(`goals/goalbyid`, { "profile_id": profile_id, "goal_id": goalId }).then(
        (response, data) => {


          setGoalData(response.data);

          let gData = JSON.parse(response.data.goal_data);

          const g_amount = (gData[0] * parseInt(gData[2]));
          const g_moneyDigit = g_amount.toString().length >= 8 ? 10000000 : 100000;

          const pg_amount = (gData[1] * parseInt(gData[3]));
          const pg_moneyDigit = g_amount.toString().length >= 8 ? 10000000 : 100000;

          setValidate({ goal_id: response.data.goal_id, plan_for: response.data.plan_for, profile_id: response.data.profile_id, profile_age: response.data.profile_age, amount: response.data.total_abcd / 100000, moneyDigits: g_moneyDigit, g_start_in: parseInt(response.data.required_after),  year: response.data.required_after, g_amount: g_amount / g_moneyDigit, pg_amount: pg_amount / pg_moneyDigit, gcurrencyDigit: g_moneyDigit, pgcurrencyDigit: pg_moneyDigit, gyears: gData[2], pgyears: gData[3], goal_name: response.data.goal_name, aspire: response.data.aspire });

        });
    }
  }, [state, goalId]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(validate);
    // eslint-disable-next-line
  }, [validate]);

  const onSubmit = education => {

    //const required_after_cal = 17 - education.profile_age;
    const required_after_cal = education.g_start_in;
    const required_after = required_after_cal < 1 ? 1 : required_after_cal;
    const required_till = required_after + 6;

    const postData = {
      "goal_type": "Education",
      "aspire": education.aspire,
      "goal_name": education.goal_name,
      "goal_id": education.goal_id,
      "profile_id": sessionStorage.getItem('profile_id'),
      "admin_id":sessionStorage.getItem("admin_id"),
      "plan_for": {
        "profile_details_id": education.plan_for,
        "current_age": education.age < 1 ? 1 : education.age,
        "relation": 'child',
      },
      "yearly_expense": {
        "0": education.g_amount * education.gcurrencyDigit,
        "1": education.pg_amount * education.pgcurrencyDigit
      },
      "no_years": {
        "0": education.gyears,
        "1": education.pgyears
      },
      "goal_params": {
        "age": education.profile_age < 1 ? 1 : education.profile_age,
        "post_inflation": 7,
        "post_return": 7,
        "goal_inflation": 7,
        "required_after": required_after,
        "required_till": required_till
      },
      "risk_profile": {
        "available": false,
        "pre_return": 8,
        "risk_profile_id": sessionStorage.getItem('profile_id')
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": parseInt(education.g_amount) + parseInt(education.pg_amount),
        "frequency": 12,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };

    axioInstance.post(`goals/add`, postData)
      .then(function (response, data) {

        const profile_id = sessionStorage.getItem('profile_id');
        const gpostData = { profile_id: profile_id };
        axioInstance.post(`goals/reset`, gpostData)
          .then(function (response) {
            axioInstance.post(`profile/summary-update`, { profile_id: profile_id }).then(
              (response, data) => {
                window.location.reload();
              }
            );
          })


      })


  }

  const DeleteGoal = (id) => {


    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this goal?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: true
    })
      .then((result) => {
        if (result.isConfirmed) {


          axioInstance.post(`goals/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'goal_id': id }).then(
            function (response) {

              axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                (response, data) => {
                  SweetAlert.fire(
                    'Goals',
                    'Successfully Deleted.',
                    'success'
                  ).then(function () {
                    window.location.href = "/dashboard";
                  });
                }
              );

            }
          )
        }
      });
  }

  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => DeleteGoal(goalData.goal_id)}>Delete</button>
            </div>
            <div className="font14 fw500 color182">
              Edit {goalData.goal_name} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_educationGoals pl25">
            <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
            <div className="font18 fw600 color182">{goalData.goal_name}</div>
            <div className="font14 fw500 color485">kids {goalData.goal_name} plan in your financial journey</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="expandtab font12 color353 fw500">{goalData.full_name} Education plan</div><br />
              <small className='text-danger'>{planLatestStatus === 7 && 'Plan Freezed'}</small>
              <input type={"hidden"} name="plan_for" {...register("plan_for")} />
              <input type={"hidden"} name="profile_id" {...register("profile_id")} />
              <input type={"hidden"} name="profile_age" {...register("profile_age")} />
              <input type={"hidden"} name="goal_id" {...register("goal_id")} />
              <input type={"hidden"} name="aspire" {...register("aspire")} />
              <input type={"hidden"} name="goal_name" {...register("goal_name")} />


              <div className="form-row">

                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Graduation Start In</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="g_start_in" {...register("g_start_in", { required: 'Required',  min:{value:1, message:"Minimum 1 Year"}, max:{value:20, message:'Maximum 20 Years'} })} maxLength={2} />
                    <select className="custom-select font14">
                      <option selected readonly>Year</option>
                    </select>
                  </div>
                  <span className="text-danger"> {errors.g_start_in && errors.g_start_in.message}</span>
                </div>

                <div className="formdevider mt25 mb25"></div>

                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Total Graduation Amount</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="g_amount" {...register("g_amount", { required: 'Required' })} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='gcurrencyDigit' {...register("gcurrencyDigit")}>
                      <option selected value="100000">Lakhs</option>
                      <option value="10000000">Crore</option>
                    </select>
                    <select className="custom-select font14" id="inputGroupSelect01" name='gyears' {...register("gyears")}>
                      <option value="5">For 5Y</option>
                      <option value="4">For 4Y</option>
                      <option selected value="3">For 3Y</option>
                      <option value="2">For 2Y</option>
                      <option value="1">For 1Y</option>
                      <option value="0">For 0Y</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.g_amount && errors.g_amount.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />

              <div className="form-row mt20">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Total Post Graduation Amount</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="pg_amount" {...register("pg_amount", { required: 'Required' })} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='pgcurrencyDigit' {...register("pgcurrencyDigit")}>
                      <option selected value="100000">Lakhs</option>
                      <option value="10000000">Crore</option>
                    </select>
                    <select className="custom-select font14" id="inputGroupSelect01" name='pgyears' {...register("pgyears")}>
                      <option value="3">For 3Y</option>
                      <option value="2">For 2Y</option>
                      <option selected value="1">For 1Y</option>
                      <option selected value="0">For 0Y</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.g_amount && errors.g_amount.message}</span>
                </div>
              </div>


              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                {planLatestStatus !== 7 && <button type="submit" className="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
