import React, { Fragment } from 'react'
import {  inWords } from '../../global';
import axioInstance from '../../axiosInstance';

export default function TermSuggestionSidebar({ toggleClass, setActive, insuranceDetails }) {

    console.log(insuranceDetails);
    const Back = () => {
        setActive(false);
    }

    const ChangeSelectOpt = (select_opt, goal_id) => {

        axioInstance.post(`goals/update-life-select-opt`, { profile_id: sessionStorage.getItem('profile_id'), select_opt: select_opt, goal_id: goal_id })
            .then(function (response) {
                //console.log(response.data)
                window.location.reload(true);
            });

    }

    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">
                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Rational behind suggestions
                        </div>
                        <div>
                            <button type="button" className="btn btn-primary btn-sm">Save</button>
                        </div>
                    </div>

                    <div className="pl20 pr20 pt20">
                        <span className="bigrupeecoinicon" />
                        <div className="mt15 color182 font14 fw500">Suggested based on <span className="fw700">Income</span>


                            <div className="apply_roi_right">
                                {
                                    insuranceDetails.select_opt === "income_grow" ? <span className="greentick" style={{ "visibility": "visible" }}></span> : <div className="previewtab font12 fw600 color212 mr25 applyBtn" style={{ "visibility": "visible" }} onClick={() => ChangeSelectOpt('income_grow', insuranceDetails.goal_id)}> Apply </div>
                                }
                            </div>


                        </div>
                        <div className="font22 fw600 color263 mt10">{inWords(insuranceDetails.suggestedoption2)}</div>
                        <div className="mt5 color6d7 font12 fw500">
                            Your Current Income <strong>{inWords(insuranceDetails.income)}</strong> will keep growing at <strong>{Math.round(insuranceDetails.income_grow * 100)}%</strong> for next <strong>{Math.round(insuranceDetails.grow_till, 0)}</strong> years.<br/>
                            The Assumed return during this period is <strong>{Math.round(insuranceDetails.post_return * 100)}%.</strong>
                        </div>
                        <div className="font12 fw600 color0d6 mt10">Contact Us <span className="bluerightarrow" /></div>

                        <div className="devider_line mt20 mb20" />

                        <span className="bigrupeecoinicon" />
                        <div className="mt15 color182 font14 fw500">Suggested based on <span className="fw700">Expense</span>
                            <div className="apply_roi_right">
                                {
                                    insuranceDetails.select_opt === "regular" ? <span className="greentick" style={{ "visibility": "visible" }}></span> : <div className="previewtab font12 fw600 color212 mr25 applyBtn" style={{ "visibility": "visible" }} onClick={() => ChangeSelectOpt('regular', insuranceDetails.goal_id)}> Apply </div>
                                }
                            </div>

                        </div>
                        <div className="font22 fw600 color263 mt10">{inWords(insuranceDetails.suggestedoption1)}</div>
                        <div className="mt5 color6d7 font12 fw500">
                            The allocated current expense of <strong>{inWords(insuranceDetails.total_abcd)}</strong> will keep growing at <strong>{Math.round(insuranceDetails.goal_inflation * 100, 2)}%</strong> for next <strong>{Math.round((insuranceDetails.life_expectancy - insuranceDetails.profile_age), 0)}</strong> years.<br/>
                            The loans/ liabilities allocated to you is <strong>{inWords(insuranceDetails.loan_distrubuted)}</strong>.<br/>
                            The Assumed return during this period is <strong>{Math.round(insuranceDetails.post_return * 100)}%.</strong>
                        </div>
                        <div className="font12 fw600 color0d6 mt10">Contact Us <span className="bluerightarrow" /></div>

                        <div className="devider_line mt20 mb20" />

                        <span className="goalicon" />
                        <div className="mt15 color182 font14 fw500">Suggested based on <span className="fw700">Goals</span></div>
                        <div className="font22 fw600 color263 mt10">-</div>
                        <div className="mt5 color6d7 font12 fw500">
                            Because the goal’s nature may increase from year to year and based on the current trend, we predict that you might have newer goals in future. We see that if you increase the cover, your goals can go undisturbed in the event of any undesired outcome.
                        </div>
                        <div className="font12 fw600 color0d6 mt10">Contact Us <span className="bluerightarrow" /></div>

                        <div className="devider_line mt20 mb20" />





                    </div>



                </div>
            </div>
        </Fragment>
    )
}
