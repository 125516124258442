import React from 'react'

export default function NormalQuestion({ question, setUserSlectedAns, setUserSlectedWeight, userSlectedAns, handleNext }) {

  const answerList = question.answers.map((answer) => {



    return (<>
      <li>
        <label htmlFor={answer.id}>
          <input type="radio" name={question.key} checked={false} value={answer.id} id={answer.id} onClick={() => handleNext(answer.id, question.id, question.question, answer.weight, question.category)} />
          <span className="rdo_text_box hover_effect">
            {answer.name}
          </span>
        </label>
      </li>
    </>)
  })

  return (<><div className="form-row">
    <div>
      <div className="color212 font14 fw600">{question.question} <span className="colorfa8">*</span>
      </div>
      <ul className="steps_tag font14 fw500 color182 mt15">
        {answerList}
      </ul>
    </div>
  </div></>)

}