import React, { useState, useEffect } from 'react'
import WelcomeNote from '../components/fpreports/WelcomeNote'
import Disclaimer from '../components/fpreports/Disclaimer'
import PersonalInformation from '../components/fpreports/PersonalInformation'
import CashflowAnalysis from '../components/fpreports/CashflowAnalysis'
import Assetswhatyouown from '../components/fpreports/Assetswhatyouown'
import NetworthAnalysis from '../components/fpreports/NetworthAnalysis'
import EmergencyFund from '../components/fpreports/EmergencyFund'
import LoanManagement from '../components/fpreports/LoanManagement'
import LifeInsuranceAnalysis from '../components/fpreports/LifeInsuranceAnalysis'
import HealthAnalysis from '../components/fpreports/HealthAnalysis'
import GoalSummary from '../components/fpreports/GoalSummary'
import DashboardHeader from '../components/layout/DashboardHeader'
import RiskProfileSummary from '../components/fpreports/RiskProfileSummary'

export default function FpReport() {

    const [active, setActive] = useState(1);

    /*const redirectPdf = () => {

        window.open('https://planapi.finnovate.in/fp-report/' + sessionStorage.getItem("profile_id"), "_blank");
    }*/

    useEffect(() => {
        document.body.classList.add('white_bg_fp_report');

        return function cleanup() {
            document.body.classList.remove('white_bg_fp_report');
        };
    }, []);

    return (
        <>
            <DashboardHeader />
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className="planSection_wpr row">
                        <div className="col-3 leftNav pt55 font14 fw500 color7B8">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                <a className="nav-link active" id="Welcomenote-tab" data-toggle="pill" href="#Welcomenote" role="tab" aria-controls="Welcomenote" aria-selected="true" onClick={() => setActive(1)}> <span className="welcomeNoteIcon mr10" /> Welcome note</a>

                                <a className="nav-link" id="Disclaimer-tab" data-toggle="pill" href="#Disclaimer" role="tab" aria-controls="Disclaimer" aria-selected="false" onClick={() => setActive(2)}><span className="disclaimerIcon mr10" /> Disclaimer</a>

                                <a className="nav-link" id="PersonalInformation-tab" data-toggle="pill" href="#PersonalInformation" role="tab" aria-controls="PersonalInformation" aria-selected="false" onClick={() => setActive(3)}><span className="personalInformationIcon mr10" /> Personal Information</a>

                                <a className="nav-link" id="CashflowAnalysis-tab" data-toggle="pill" href="#CashflowAnalysis" role="tab" aria-controls="CashflowAnalysis" aria-selected="false" onClick={() => setActive(4)}><span className="cashflowAnalysisIcon mr10" /> Cashflow Analysis</a>

                                <a className="nav-link" id="Whatyouown-tab" data-toggle="pill" href="#Whatyouown" role="tab" aria-controls="Whatyouown" aria-selected="false" onClick={() => setActive(5)}><span className="assetsIcon mr10" /> Assets - What you own</a>

                                <a className="nav-link" id="NetworthAnalysis-tab" data-toggle="pill" href="#NetworthAnalysis" role="tab" aria-controls="NetworthAnalysis" aria-selected="false" onClick={() => setActive(6)}><span className="networthAnalysisIcon mr10" /> Networth Analysis</a>

                                <a className="nav-link" id="EmergencyFund-tab" data-toggle="pill" href="#EmergencyFund" role="tab" aria-controls="EmergencyFund" aria-selected="false" onClick={() => setActive(7)}><span className="emergencyFundIcon mr10" /> Emergency Fund</a>

                                <a className="nav-link" id="LoanManagement-tab" data-toggle="pill" href="#LoanManagement" role="tab" aria-controls="LoanManagement" aria-selected="false" onClick={() => setActive(8)}><span className="loanManagementIcon mr10" /> Loan Management</a>

                                <a className="nav-link" id="LifeInsuranceAnalysis-tab" data-toggle="pill" href="#LifeInsuranceAnalysis" role="tab" aria-controls="LifeInsuranceAnalysis" aria-selected="false" onClick={() => setActive(9)}><span className="lifeAnalysisIcon mr10" /> Life Insurance Analysis</a>

                                <a className="nav-link" id="HealthAnalysis-tab" data-toggle="pill" href="#HealthAnalysis" role="tab" aria-controls="HealthAnalysis" aria-selected="false" onClick={() => setActive(10)}><span className="healthAnalysisIcon mr10" /> Health Analysis</a>

                                <a className="nav-link" id="GoalSummary-tab" data-toggle="pill" href="#GoalSummary" role="tab" aria-controls="GoalSummary" aria-selected="false" onClick={() => setActive(11)}><span className="goalSummaryIcon mr10" /> Goal Summary</a>

                                <a className="nav-link" id="RiskProfileSummary-tab" data-toggle="pill" href="#RiskProfileSummary" role="tab" aria-controls="RiskProfileSummary" aria-selected="false" onClick={() => setActive(12)}><span className="goalSummaryIcon mr10" /> Plan History</a>
                            </div>

                        </div>
                        <div className="col-9 righttabContent pl90 pt25">
                            <div className="tab-content" id="v-pills-tabContent">
                                {
                                    active === 1 && (<><div className="tab-pane fade show active" id="Welcomenote" role="tabpanel" aria-labelledby="Welcomenote-tab">
                                        <WelcomeNote />
                                    </div></>)
                                }

                                {
                                    active === 2 && (<><div className="tab-pane fade" id="Disclaimer" role="tabpanel" aria-labelledby="Disclaimer-tab">
                                        <Disclaimer />
                                    </div></>)
                                }


                                {
                                    active === 3 && (<><div className="tab-pane fade" id="PersonalInformation" role="tabpanel" aria-labelledby="PersonalInformation-tab">
                                        <PersonalInformation />
                                    </div></>)
                                }


                                {
                                    active === 4 && (<><div className="tab-pane fade" id="CashflowAnalysis" role="tabpanel" aria-labelledby="CashflowAnalysis-tab">
                                        <CashflowAnalysis />
                                    </div></>)
                                }


                                {
                                    active === 5 && (<><div className="tab-pane fade" id="Whatyouown" role="tabpanel" aria-labelledby="Whatyouown-tab">
                                        <Assetswhatyouown />
                                    </div></>)
                                }


                                {
                                    active === 6 && (<><div className="tab-pane fade" id="NetworthAnalysis" role="tabpanel" aria-labelledby="NetworthAnalysis-tab">
                                        <NetworthAnalysis />
                                    </div></>)
                                }


                                {
                                    active === 7 && (<><div className="tab-pane fade" id="EmergencyFund" role="tabpanel" aria-labelledby="EmergencyFund-tab">
                                        <EmergencyFund />
                                    </div></>)
                                }


                                {
                                    active === 8 && (<><div className="tab-pane fade" id="LoanManagement" role="tabpanel" aria-labelledby="LoanManagement-tab">
                                        <LoanManagement />
                                    </div></>)
                                }


                                {
                                    active === 9 && (<><div className="tab-pane fade" id="LifeInsuranceAnalysis" role="tabpanel" aria-labelledby="LifeInsuranceAnalysis-tab">
                                        <LifeInsuranceAnalysis />
                                    </div></>)
                                }


                                {
                                    active === 10 && (<><div className="tab-pane fade" id="HealthAnalysis" role="tabpanel" aria-labelledby="HealthAnalysis-tab">
                                        <HealthAnalysis />
                                    </div></>)
                                }


                                {
                                    active === 11 && (<><div className="tab-pane fade" id="GoalSummary" role="tabpanel" aria-labelledby="GoalSummary-tab">
                                        <GoalSummary />
                                    </div></>)
                                }

                                {
                                    active === 12 && (<><div className="tab-pane fade" id="RiskProfileSummary" role="tabpanel" aria-labelledby="RiskProfileSummary-tab">
                                        <RiskProfileSummary />
                                    </div></>)
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
