import React, { Fragment, useState } from 'react'
import ProtectionScreen1 from '../../components/wizard/ProtectionScreen1'
import ProtectionScreen2 from '../../components/wizard/ProtectionScreen2'

import { nextsubStep, prevsubStep, nextStep } from '../../components/global'

export default function Protection({ StepChange, steps, lifeStage, clientName }) {

    const [substep, setSubstep] = useState(1);

    // eslint-disable-next-line
    const [name, setName] = useState(sessionStorage.getItem('full_name'));

    return (
        <Fragment>
            {substep === 1 && (<ProtectionScreen1 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} lifeStage={lifeStage} clientName={name}/>)}
            {substep === 2 && (<ProtectionScreen2 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} StepChange={StepChange} steps={steps} nextStep={nextStep} prevsubStep={prevsubStep} lifeStage={lifeStage} clientName={name}/>)}
        </Fragment>
    )
}
