import React, { Fragment, useState } from 'react'
import NormalQuestion from './NormalQuestion'
import QnaFirst from './QnaFirst';
import QnaLast from './QnaLast';
import QnaResponse from './QnaResponse';
//import questions from './mock.json';
import questions from './finnfitqna.json';
import SideImg from '../../img/BasicProfile.png';

export default function Qna() {


  //eslint-disable-next-line
  const [questionIndex, setQuestionIndex] = useState(1);
  const [userSlectedAns, setUserSlectedAns] = useState(null);
  const [userSlectedWeight, setUserSlectedWeight] = useState(null);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);

  const handleNext = (ans_id, ques_id, ques_title, weight, category) => {


    console.log('location read', window.location);
    
    setQuestionIndex(questionIndex + 1);
    setUserSlectedAns(ans_id);
    setUserSlectedWeight(weight);

    const qna = questionsAndAnswers;
    qna.push({
      id: questions[questionIndex - 1].id,
      user_answer: ans_id,
      category: category
    });

    setUserSlectedAns(null);
    setUserSlectedWeight(null);
    setQuestionsAndAnswers(qna);

    if (questionIndex === 13) {
      setSteps(3);
      sessionStorage.setItem("QnaJson", JSON.stringify(questionsAndAnswers))
      return;
    }

  }


  const prevClick = () => {

    if (questionIndex === 1) return;
    setQuestionIndex(questionIndex - 1);
    setUserSlectedAns(null);
    setUserSlectedWeight(null);

  }


  const questionList = questions.filter(question =>
    question.itemId.toString() === questionIndex.toString()
  ).map((question) => {
    return <NormalQuestion question={question} setUserSlectedAns={setUserSlectedAns} setUserSlectedWeight={setUserSlectedWeight} userSlectedAns={userSlectedAns} userSlectedWeight={userSlectedWeight} handleNext={handleNext} />
  })

  const [steps, setSteps] = useState(1);
  //console.log(steps);
  return (


    <Fragment>

      <div className="innercontainer mb40">

        {steps === 1 && (<QnaFirst steps={steps} setSteps={setSteps} />)}
        {steps === 3 && (<QnaLast steps={steps} setSteps={setSteps} />)}
        {steps === 4 && (<QnaResponse steps={steps} setSteps={setSteps} />)}
        {steps === 2 && (
          <>
            <div className="mainwrapper">
              <div className='form_title'>
                <div className="mt30 font20 fw600">FinnFit Quiz </div>
                <div className="font15 color495">Few questions to understand your Financial Fitness Score</div>
              </div>
              <div className="row formWpr mt25">
                <div className="col-lg-8 formouter">
                  <ul className="form_swipe_tab font12 fw600">
                    <li>{questionIndex}</li>
                    { /*<li className="act">1</li>
                    <li>2</li>*/ }
                  </ul>
                  <div className="formtitle flex_center justify_center">
                    <div>
                      <div className="font20 fw600">Know your Financial Fitness Score</div>
                      <div className="font15 color495 ">Participate in the Quiz to Win your complimentary 30 Minute Session</div>
                    </div>
                    <div className='d-none d-md-block'>
                      <input type="button" className="bluebtn font13 fw600 mr-3" onClick={prevClick} value="Prev" />
                      <input type="button" className="bluebtn font13 fw600 mr-3" onClick={handleNext} value="Next" disabled={!userSlectedAns} />
                    </div>
                  </div>
                  {/* Form */}
                  <div className="p35">
                    <form className="custome_form">
                      {questionList}
                    </form>
                  </div>
                  {/* Form End */}
                </div>
                <div className="col-lg-4 qouteouter d-none d-md-block">
                  <div className="qoutetext font18 fw500 color212 pl45 pr45">
                    <div className="qoutesign">
                      &#8220;
                    </div>
                    Don’t worry about enclosing PAN details to finnovate. Your details will be secure and encrypted.
                  </div>
                  <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                </div>
              </div>
              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={prevClick}>Prev </button>
                <button type="button" className="btn btn-primary" onClick={handleNext} disabled={!userSlectedAns} >Next </button>
              </div>
            </div>

            <div className="row mt50">
              <div className="col-lg-12">

                <p className='font11'>Disclaimer:</p>

                <p className='font11'>This financial fitness score is provided for educational and informational purposes only and is not intended to provide investment, tax, or legal advice. The score is calculated based on the information you provide and is not a guarantee of future performance or success. Your personal financial situation is unique, and the score may not reflect your true financial health. It is important to seek professional advice before making any financial decisions. The score is not a substitute for professional judgment and is subject to change without notice. The provider of this score assumes no responsibility for any errors or omissions in the information provided. By using this score, you agree to hold harmless the provider and its affiliates for any losses or damages that may result from any inaccuracies or incompleteness of the information.</p>

                <p className='font11'> Registration granted by SEBI, membership of BASL and certification from NISM in no way
                  guarantee performance of the intermediary or provide any assurance of returns to investors</p>
                <p className='font11'>Mutual Fund investments are subject to market risks, read all scheme related documents carefully.</p>
                <p className='font11'>Past performance is not indicative of future returns. Please consider your specific investment
                  requirements, risk tolerance, goal, time frame, risk and reward balance and the cost associated with the
                  investment before choosing a fund, or designing a portfolio that suits your needs. Performance and returns
                  of any investment portfolio can neither be predicted<br />
                  nor guaranteed.</p>
                <p className='font11'>SEBI RIA Registration No: INA000013518 | Type of Registration: Non-Individual | Validity of registration:
                  June 19, 2019 - Perpetual | Address: The Summit Business Park, 7th Floor, Unit No. 703, Andheri - Kurla
                  Rd, Behind Guru Nanak Petrol Pump, Opp. PVR Cinema, Andheri East, Mumbai – 400069 | Contact No. 9819022600
                  | Principal Officer details: Nehal Mota, Email : nehal.mota@finnovate.in, Phone: 9152022446 |
                  Corresponding Local SEBI Office : SEBI Office Address: Office of Investor Assistance and Education, SEBI
                  Bhavan Plot No.C4-A, G Block, Bandra Kurla Complex, Bandra (E), Mumbai - 400 051</p>
                <p className='font11'>AMFI Registered Distributor - ARN 52128 | Platform Partner : BSE (Member code 17548) | IRDAI Corporate
                  Agency : CA0703 | CIN: U67190MH2007PTC172272 | GST No : 27AABCF1124C2ZC | Website – <a href="http://www.finnovate.in" target="_blank" rel='noreferrer'>www.finnovate.in</a> Email – <a href="mailto:hello@finnovate.in">hello@finnovate.in</a></p>

              </div>

            </div>
          </>
        )}

      </div>
    </Fragment>
  )
}
