import axios from 'axios';

const apiUrl = window.location.hostname === "localhost"
    ? "https://fpapi.finnovate.in/api/" : window.location.hostname === "plan.finnovate.in" ? "https://planapi.finnovate.in/api/" : "https://fpapi.finnovate.in/api/";

const token = sessionStorage.getItem('token');

const axioInstance = axios.create({
    baseURL: apiUrl, // Your API base URL
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': token ? `Bearer ${token}` : '', // Your token here
        'Content-Type': 'application/json', // Adjust content type as per your API requirements
    }
});

export default axioInstance;
