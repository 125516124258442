import React, { Fragment, useState } from 'react'

import CashflowScreen1 from '../../components/wizard/CashflowScreen1'
import CashflowScreen2 from '../../components/wizard/CashflowScreen2'
import CashflowScreen3 from '../../components/wizard/CashflowScreen3'
import CashflowScreen4 from '../../components/wizard/CashflowScreen4'

import { nextsubStep, prevsubStep, nextStep } from '../../components/global'

export default function Cashflow({ StepChange, steps }) {


    const [substep, setSubstep] = useState(1);
    // eslint-disable-next-line
    const [name, setName] = useState(sessionStorage.getItem('full_name'));

    const [incomeSelfParam, setIncomeSelfParam] = useState(0);
    const [incomeSpouseParam, setIncomeSpouseParam] = useState(0);

    return (
        <Fragment>
            {substep === 1 && (<CashflowScreen1 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} clientName={name} incomeSelfParam={incomeSelfParam} setIncomeSelfParam={setIncomeSelfParam} incomeSpouseParam={incomeSpouseParam} setIncomeSpouseParam={setIncomeSpouseParam}/>)}
            {substep === 2 && (<CashflowScreen2 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} prevsubStep={prevsubStep} clientName={name} incomeSelfParam={incomeSelfParam} setIncomeSelfParam={setIncomeSelfParam} incomeSpouseParam={incomeSpouseParam} setIncomeSpouseParam={setIncomeSpouseParam}/>)}
            {substep === 3 && (<CashflowScreen3 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} prevsubStep={prevsubStep} clientName={name}/>)}
            {substep === 4 && (<CashflowScreen4 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} StepChange={StepChange} steps={steps} nextStep={nextStep} prevsubStep={prevsubStep} clientName={name}/>)}
        </Fragment>
    )
}
