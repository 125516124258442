import React, { useState, useEffect } from 'react'
import axioInstance from '../axiosInstance';
import SweetAlert from 'sweetalert2'


export default function ViewRiskProfile({ toggleClass, setActive, riskDescription }) {

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const [calRiskAsset, setCalRiskAsset] = useState();
    // eslint-disable-next-line
    const [calSummaryAsset, setCalSummaryAsset] = useState();
    // eslint-disable-next-line
    const [roi, setRoi] = useState();
    // eslint-disable-next-line
    const [asset, setAsset] = useState();
    // eslint-disable-next-line
    const [riskAsset, setRiskAsset] = useState([]);

    const [riskEquity, setRiskEquity] = useState();
    const [riskDebt, setRiskDebt] = useState();

    const [roiOption, setRoiOption] = useState();


    const changeRoi = (roiOption, assetAlloc) => {

        const postData = {
            "roiOption": roiOption,
            "roi": assetAlloc,
            "profile_id": sessionStorage.getItem('profile_id')
        }

        axioInstance.post(`profile/update-roi`, postData).then(
            (response, data) => {

                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: 'ROI Successfully Updated.',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                }).then(function () {

                    const gpostData = { profile_id: sessionStorage.getItem('profile_id') };
                    axioInstance.post(`goals/reset`, gpostData)
                        .then(function (response) {
                            axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id'), roiChange: 1, roi: assetAlloc, asset_alloc: asset }).then(
                                (response, data) => {

                                    window.location.reload();

                                }
                            );
                        })


                });

            }
        );

    }

    const [equityBar, setEquityBar] = useState(0);
    const [debtBar, setDebtBar] = useState(0);
    const [otherBar, setOtherBar] = useState(0);
    const [calInvestmentAsset, setCalInvestmentAsset] = useState();

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.get(`profile/summary/${profile_id}`).then(
            (response, data) => {
                if (response.data.status === 100) {


                    setRoi(response.data.profgoalSummary.pre_return * 100);

                    setAsset(response.data.profgoalSummary.goal_asset_alloc);

                    setRoiOption(response.data.profgoalSummary.roiOption);
                    setRiskAsset(response.data.risk_profile);

                    if (response.data.risk_profile !== '') {
                        setRiskEquity(response.data.risk_profile.max_val);
                        setRiskDebt(100 - response.data.risk_profile.max_val);
                    } else {

                    }

                    const profile_id = sessionStorage.getItem('profile_id');

                    axioInstance.post(`summary/calculate-roi`, { profile_id: profile_id, asset_alloc: response.data.risk_profile.max_val }).then(
                        (response, data) => {
                            setCalRiskAsset((response.data.roi * 100).toFixed(2));
                        });

                    axioInstance.post(`summary/calculate-roi`, { profile_id: profile_id, asset_alloc: response.data.profgoalSummary.goal_asset_alloc }).then(
                        (response, data) => {
                            setCalSummaryAsset((response.data.roi * 100).toFixed(2));
                        });

                }
            });




        axioInstance.get(`investment/group-dashboard/${profile_id}`).then(
            (response, data) => {

                let find_equity = response.data.sum.find(product => product.name === "Equity");
                let find_debt = response.data.sum.find(product => product.name === "Debt");
                let find_others = response.data.sum.find(product => product.name === "Property");
                let gold_others = response.data.sum.find(product => product.name === "Gold");
                gold_others = gold_others ? gold_others.subTotal : 0;
                let otherSum = find_others ? find_others.subTotal : 0 + gold_others;

                setEquityBar(find_equity ? Math.round((find_equity.subTotal / response.data.final) * 100) : 0);
                setDebtBar(find_debt ? Math.round((find_debt.subTotal / response.data.final) * 100) : 0);
                setOtherBar(otherSum > 0 ? Math.round((otherSum / response.data.final) * 100) : 0);

                if (response.data.final > 0) {
                    axioInstance.post(`summary/calculate-roi`, { profile_id: profile_id, calculate_on_innvestment: '1', asset_alloc: '1', eq_perc: equityBar / 100, debt_perc: debtBar / 100, other_perc: otherBar / 100 }).then(
                        (response, data) => {
                            setCalInvestmentAsset((response.data.roi * 100).toFixed(2));
                        });
                }


            });


    }, [equityBar, debtBar, otherBar]);



    return (
        <div className="rightSide_menu_outer">
            <div className="rightSide_menu">
                <div className="rightSide_menu_header">
                    <div>
                        <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                    </div>
                    <div className="font14 fw500 color182">
                        Risk Profile
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div className="topBanner topBanner_riskProfile pl25">
                    <div className="font18 fw600 color182 mt50">Risk Profile</div>
                    <div className="font14 fw500 color485">{sessionStorage.getItem('full_name')} family’s Risk Profile</div>
                </div>
                <div className="pl20 pr20">


                    <div className="mt20">
                        <div className="font16 fw600 color182 mt50">Your current risk profile is <strong>{riskDescription}</strong></div>
                    </div>

                    <div className="formdevider mt35 mb35" />

                    <div className="cashflow_list pb15 mt20 mb50">
                        <div className="cashflow_list_title font15 fw500 color182">Asset Allocation As Per Risk
                            <div className="apply_roi_right">
                                {
                                    roiOption === "1" ? <span className="greentick" style={{ "visibility": "visible" }}></span> : <div className="previewtab font12 fw600 color212 mr25 applyBtn" style={{ "visibility": "visible" }} onClick={() => changeRoi(1, calRiskAsset)}> Apply </div>
                                }
                            </div>
                        </div>


                        <div className='cashflow_inflow_wpr cashflow_outflow_wpr'>
                            <div className="inflow_progressbar mt15"><progress value={riskDebt > 0 ? (100 - riskDebt) : 0} max="100"></progress></div>
                        </div>
                        <div className="flex_center justify_center mt8">
                            <div className="font12 fw500 color485">Equity {riskEquity > 0 ? Math.round(riskEquity) : 0}%</div>
                            <div className="font12 fw500 color485">Debt {riskDebt > 0 ? Math.round(riskDebt) : 0}%</div>
                        </div>

                        <div className="font14 fw400 color182 mt15">Expected rate of retrun <strong>{calRiskAsset}</strong></div>


                    </div>


                    <div className="cashflow_list pb15 mt20 mb50">
                        <div className="cashflow_list_title font15 fw500 color182">Asset Allocation as Per Goals
                            <div className="apply_roi_right">
                                {
                                    roiOption === "2" ? <span className="greentick" style={{ "visibility": "visible" }}></span> : <div className="previewtab font12 fw600 color212 mr25 applyBtn" style={{ "visibility": "visible" }} onClick={() => changeRoi(2, calSummaryAsset)}> Apply </div>
                                }
                            </div>
                        </div>


                        <div className='cashflow_inflow_wpr'>
                            <div className="inflow_progressbar mt15"><progress value={asset} max="100"></progress></div>
                        </div>
                        <div className="flex_center justify_center mt8">
                            <div className="font12 fw500 color485">Equity {Math.round(asset)}%</div>
                            <div className="font12 fw500 color485">Debt {Math.round(100 - asset)}%</div>
                        </div>
                        <div className="font14 fw400 color182 mt15">Expected rate of retrun <strong>{calSummaryAsset}</strong></div>

                    </div>


                    <div className="cashflow_list pb15 mt20">
                        <div className="cashflow_list_title font15 fw500 color182 mb-3">Asset Allocation as Current Investments
                            <div className="apply_roi_right">
                                {
                                    roiOption === "3" ? <span className="greentick" style={{ "visibility": "visible" }}></span> : <div className="previewtab font12 fw600 color212 mr25 applyBtn" style={{ "visibility": "visible" }} onClick={() => changeRoi(3, calInvestmentAsset)}> Apply </div>
                                }
                            </div>
                        </div>


                        <div className='cashflow_inflow_wpr'>

                            <div className="progress multi-progress">
                                <div className="progress-bar bg-equity" role="progressbar" style={{ 'width': equityBar + '%' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                <div className="progress-bar bg-debt" role="progressbar" style={{ 'width': otherBar + '%' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                <div className="progress-bar bg-other" role="progressbar" style={{ 'width': debtBar + '%' }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="flex_center justify_center mt8">
                            <div className="font12 fw500 color485">Equity {Math.round(equityBar)}%</div>
                            <div className="font12 fw500 color485">Others {Math.round(otherBar)}%</div>
                            <div className="font12 fw500 color485">Debt {Math.round(debtBar)}%</div>
                        </div>
                        <div className="font14 fw400 color182 mt15">Expected rate of retrun <strong>{calInvestmentAsset}</strong></div>

                    </div>

                    <div className="saveBtn backsaveBtn">
                        <button type="button" className="btn btn-primary" onClick={() => Back()}>Close </button>
                        <button type="submit" className="btn btn-primary" onClick={() => toggleClass(true, 'geneRisk')}>Reset Risk </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
