import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import Chart from 'react-apexcharts'
import axioInstance from '../axiosInstance';
import formatAmount from 'indian-currency-formatter';


export default function DashboardRight() {

    const [cashflow, setCashflow] = useState([]);

    // eslint-disable-next-line
    const [chartDataRetire, setChartDataRetire] = useState({
        series: [{
            name: 'series1',
            data: []
        }],
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#1761c5'],
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'string',
                categories: []
            }
        }
    })

    // eslint-disable-next-line
    const [goals, setGoals] = useState([]);
    // eslint-disable-next-line
    const [retirement, setRetirement] = useState([]);

    const [ontrack, setOntrack] = useState();
    const [total, setTotal] = useState();

    // eslint-disable-next-line
    const [goalPointer, setGoalPointer] = useState([]);

    // eslint-disable-next-line
    const [chartData, setChartData] = useState({
        series: [{
            name: 'series1',
            data: []
        }],
        options: {
            chart: {
                height: 350,
                type: 'area',
                id: 'assetDistribution',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#1761c5'],
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'string',
                categories: []
            }
        }
    });

    // eslint-disable-next-line
    const [chartData2, setChartData2] = useState({
        series: [{
            name: 'series1',
            data: []
        }],
        options: {
            chart: {
                height: 350,
                type: 'area',
                id: 'retireDistribution',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#1761c5'],
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'string',
                categories: []
            }
        }
    })




    const [graphLine, setGraphLine] = useState([]);
    const [graphLineRetire, setGraphLineRetire] = useState([]);
    const [annotationImg, setAnnotationImg] = useState([]);
    const [srno, setSrno] = useState([]);
    const [srnoretire, setSrnoretire] = useState([]);
    const [annotationImgRetire, setAnnotationImgRetire] = useState([]);

    const DownloadSVG = () => {

        // ApexCharts.exec('assetDistribution', 'hideSeries', 'Yearly');


        const chart = new Chart(
            document.querySelector("#chartFirst"),
        );



        console.log(chart);
        /* chart.render().then(() => {
          window.setTimeout(function() {
              chart.dataURI().then((uri) => {
                  console.log(uri);
              })
          }, 1000) 
      })
      
              console.log('in func');
              console.log(Chart.dataURI());*/



    };


    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.get(`profile/summary/${profile_id}`).then(
            (response, data) => {
                setGoals(response.data.goals);

                let find_retirement = response.data.goals.find(product => product.goal_type === "Retirement");
                setRetirement(find_retirement);

                setOntrack(response.data.on_track);
                setTotal(response.data.goal_count);
            });



        axioInstance.post(`cashflow-summary-graph`, { profile_id: profile_id }).then(
            (response, data) => {

                if (response.data.result_goal_summary.cashflow_opt === "1") {

                    setCashflow(response.data.cashflowOpt1Response.cashflow);

                } else if (response.data.result_goal_summary.cashflow_opt === "2") {

                    setCashflow(response.data.cashflowOpt2Response.cashflow);

                } else if (response.data.result_goal_summary.cashflow_opt === "3") {

                    setCashflow(response.data.cashflowOpt3Response.cashflow);

                } else {
                    setCashflow(response.data.cashflowOpt3Response.cashflow);
                }


                setGraphLine([{
                    name: 'Recommended',
                    data: response.data.cashflowOpt1Response.cashflowFirstPart.nettAmt
                }, {
                    name: 'Ongoing + surplus',
                    data: response.data.cashflowOpt2Response.cashflowFirstPart.nettAmt
                },
                {
                    name: 'Desired',
                    data: response.data.cashflowOpt3Response.cashflowFirstPart.nettAmt
                }]);

                setGraphLineRetire([{
                    name: 'Recommended',
                    data: response.data.cashflowOpt1Response.cashflowSecondPart.nettAmt
                }, {
                    name: 'Ongoing + surplus',
                    data: response.data.cashflowOpt2Response.cashflowSecondPart.nettAmt
                },
                {
                    name: 'Desired',
                    data: response.data.cashflowOpt3Response.cashflowSecondPart.nettAmt
                }]);

                setAnnotationImg(response.data.cashflowOpt1Response.annotationImg);
                setSrno(response.data.cashflowOpt1Response.cashflowFirstPart.srno);
                setSrnoretire(response.data.cashflowOpt1Response.cashflowSecondPart.srno);
                setAnnotationImgRetire(response.data.cashflowOpt1Response.annotationRetire);



                setChartData({
                    series: [{
                        name: 'Recommended',
                        data: response.data.cashflowOpt1Response.cashflowFirstPart.nettAmt
                    }],
                    options: {
                        chart: {
                            height: 650,
                            type: 'area',
                            id: 'assetDistribution',
                            toolbar: {
                                show: false
                            }
                        },
                        annotations: {
                            yaxis: [],
                            xaxis: [],
                            points: response.data.cashflowOpt1Response.annotationImg
                        },

                        colors: ['#0D6CCA', '#BB883A', '#FB923C'],
                        stroke: {
                            curve: 'smooth',
                            width: 1.5
                        },
                        xaxis: {
                            type: 'string',
                            categories: response.data.cashflowOpt1Response.cashflowFirstPart.srno
                        },
                        dataLabels: {
                            formater(val, opt) {
                                return 'NAAAA'
                            }
                        }
                        /*tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {

                                console.log(' dataPointIndex : ', w);

                                console.log(' dataPointIndex : ', w.config.annotations);

                                return (
                                    '<div className="arrow_box">' +
                                    "<span>" +
                                    w.globals.labels[dataPointIndex] +
                                    ": " +
                                    series[seriesIndex][dataPointIndex] +
                                    "</span>" +
                                    "</div>"
                                );
                            }
                        }*/
                    },
                });








                setChartData2({
                    series: [{
                        name: 'Recommended',
                        data: response.data.cashflowOpt1Response.cashflowSecondPart.nettAmt
                    }],
                    options: {
                        chart: {
                            height: 650,
                            type: 'area',
                            id: 'retireDistribution',
                            toolbar: {
                                show: false
                            }
                        },
                        annotations: {
                            yaxis: [],
                            xaxis: [],
                            points: response.data.cashflowOpt1Response.annotationRetire
                        },
                        dataLabels: {
                            enabled: false
                        },
                        colors: ['#0D6CCA', '#BB883A', '#FB923C'],
                        stroke: {
                            curve: 'smooth',
                            width: 1.5
                        },
                        xaxis: {
                            type: 'string',
                            categories: response.data.cashflowOpt1Response.cashflowSecondPart.srno
                        }
                    },
                });

            });

    }, []);



    const [desirechecked, setDesirechecked] = useState(false);
    const [ongoingchecked, setOngoingchecked] = useState(false);

    const [retiredesirechecked, setRetiredesirechecked] = useState(false);
    const [retireongoingchecked, setRetireongoingchecked] = useState(false);

    const onChange = (e) => {

        if (e.target.name === "desire") {
            if (e.target.checked) {
                setDesirechecked(true);
                setRetiredesirechecked(true);
            } else {
                setDesirechecked(false);
                setRetiredesirechecked(false);
            }
        }

        if (e.target.name === "yearly") {
            if (e.target.checked) {
                setOngoingchecked(true);
                setRetireongoingchecked(true);
            } else {
                setRetireongoingchecked(false);
                setOngoingchecked(false);
            }
        }
    };

    const onChangeRetire = (e) => {

        if (e.target.name === "retiredesire") {
            if (e.target.checked) {
                setDesirechecked(true);
                setRetiredesirechecked(true);
            } else {
                setDesirechecked(false);
                setRetiredesirechecked(false);
            }
        }

        if (e.target.name === "retireyearly") {
            if (e.target.checked) {
                setOngoingchecked(true);
                setRetireongoingchecked(true);
            } else {
                setOngoingchecked(false);
                setRetireongoingchecked(false);
            }
        }


    }

    useEffect(() => {

        // eslint-disable-next-line
        var searchData = graphLine.filter((item) => {

            if (desirechecked === true) {
                if (
                    item.name
                        .toString()
                        .includes("Desired")
                ) {
                    return item;
                }
            }


            if (ongoingchecked === true) {
                if (
                    item.name
                        .toString()
                        .includes("Ongoing + surplus")
                ) {
                    return item;
                }
            }

            if (
                item.name
                    .toString()
                    .includes("Recommended")
            ) {
                return item;
            }


        });

        if (searchData) {


            setChartData({
                series: searchData,
                options: {
                    chart: {
                        height: 650,
                        type: 'area',
                        id: 'assetDistribution',
                        toolbar: {
                            show: false
                        }
                    },
                    annotations: {
                        yaxis: [],
                        xaxis: [],
                        points: annotationImg
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ['#0D6CCA', '#BB883A', '#FB923C'],
                    stroke: {
                        curve: 'smooth',
                        width: 1.5
                    },
                    xaxis: {
                        type: 'string',
                        categories: srno
                    }
                },
            });

        }

    }, [desirechecked, ongoingchecked, annotationImg, graphLine, srno])








    useEffect(() => {

        // eslint-disable-next-line
        var searchDataRetire = graphLineRetire.filter((item) => {

            if (retiredesirechecked === true) {
                if (
                    item.name
                        .toString()
                        .includes("Desired")
                ) {
                    return item;
                }
            }


            if (retireongoingchecked === true) {
                if (
                    item.name
                        .toString()
                        .includes("Ongoing + surplus")
                ) {
                    return item;
                }
            }

            if (
                item.name
                    .toString()
                    .includes("Recommended")
            ) {
                return item;
            }


        });

        if (searchDataRetire) {


            setChartData2({
                series: searchDataRetire,
                options: {
                    chart: {
                        height: 650,
                        type: 'area',
                        id: 'retireDistribution',
                        toolbar: {
                            show: false
                        }
                    },
                    annotations: {
                        yaxis: [],
                        xaxis: [],
                        points: annotationImgRetire
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ['#0D6CCA', '#BB883A', '#FB923C'],
                    stroke: {
                        curve: 'smooth',
                        width: 1.5
                    },
                    xaxis: {
                        type: 'string',
                        categories: srnoretire
                    }
                },
            });

        }

    }, [retiredesirechecked, retireongoingchecked, annotationImgRetire, graphLineRetire, srnoretire])

    return (
        <Fragment>
            <div className="dashboard_right">
                <div className="graphvalue">
                    <div className="graphvalue_left">
                        <div className="graphvalue_count font22 fw500">
                            <span className='font16'>{retirement.target_amt ? retirement.target_amt : 'NA'}</span>
                        </div>
                        <div className="ml15 font12 fw500 color6d7">
                            Retirement corpus required at {retirement.achived_by}
                        </div>
                    </div>
                    <div className="graphvalue_right">
                        <div className="graphvalue_count font22 fw500">

                            <span className='font16'>{ontrack}</span>
                        </div>
                        <div className="ml15 font12 fw500 color6d7">
                            Your are on track to achieve {ontrack} of {total} financial life goal
                        </div>
                    </div>
                </div>


                <div className="graphtabs_outer mt25">
                    <div className="graphtabs">
                        <nav className="font12">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link active" id="nav-activeyears-tab" data-toggle="tab" href="#nav-activeyears" role="tab" aria-controls="nav-activeyears" aria-selected="true">Active years</a>
                                <a className="nav-item nav-link" id="nav-retiredyears-tab" data-toggle="tab" href="#nav-retiredyears" role="tab" aria-controls="nav-retiredyears" aria-selected="false">Retired years</a>
                                <a className="nav-item nav-link" id="nav-tabularview-tab" data-toggle="tab" href="#nav-tabularview" role="tab" aria-controls="nav-tabularview" aria-selected="false">Tabular view</a>
                            </div>
                        </nav>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-activeyears" role="tabpanel" aria-labelledby="nav-activeyears-tab">


                            <div className="graph_container">

                                {


                                    <div id="chart">
                                        <Chart options={chartData.options} series={chartData.series} height="450" type="line" id="chartFirst" />
                                    </div>


                                }




                            </div>

                            <div className="graph_list_outer">
                                <ul className="graph_detail_list">
                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolor0dc mr8" />
                                            Recommended
                                            <span className="listcheck ml10">
                                                <input type="checkbox" checked={true} readOnly />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolorfb9 mr8" />
                                            Desired target
                                            <span className="listcheck ml10">
                                                <input type="checkbox" value={desirechecked}
                                                    onClick={(e) => { onChange(e) }}
                                                    id="subscribe"
                                                    name="desire" checked={desirechecked} />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolorbb8 mr8" />
                                            Ongoing + surplus
                                            <span className="listcheck ml10">
                                                <input type="checkbox" value={ongoingchecked}
                                                    onClick={(e) => { onChange(e); }}
                                                    id="subscribe1"
                                                    name="yearly" checked={ongoingchecked} />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>


                                    <li>
                                        <span className="rupee_greenbox mr8" />
                                        Total savings
                                    </li>
                                    <li>
                                        <span className="flag_bluebox mr8" />
                                        Retirement goal
                                    </li>
                                    <li onClick={DownloadSVG}>
                                        <span className="resetofgoal_box mr8" />
                                        Rest of the goals
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-retiredyears" role="tabpanel" aria-labelledby="nav-retiredyears-tab">

                            <div className="graph_container">

                                {


                                    <div id="chart">
                                        <Chart options={chartData2.options} series={chartData2.series} height="350" type="line" />
                                    </div>


                                }


                            </div>

                            <div className="graph_list_outer">
                                <ul className="graph_detail_list">
                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolor0dc mr8" />
                                            Recommended
                                            <span className="listcheck ml10">
                                                <input type="checkbox" checked={true} readOnly />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolorfb9 mr8" />
                                            Desired target
                                            <span className="listcheck ml10">
                                                <input type="checkbox" value={retiredesirechecked}
                                                    onClick={(e) => { onChangeRetire(e) }}
                                                    id="retiredesire"
                                                    name="retiredesire" checked={retiredesirechecked} />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolorbb8 mr8" />
                                            Ongoing + surplus
                                            <span className="listcheck ml10">
                                                <input type="checkbox" value={retireongoingchecked}
                                                    onClick={(e) => { onChangeRetire(e) }}
                                                    id="retireyearly"
                                                    name="retireyearly" checked={retireongoingchecked} />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>


                                    <li>
                                        <span className="rupee_greenbox mr8" />
                                        Total savings
                                    </li>
                                    <li>
                                        <span className="flag_bluebox mr8" />
                                        Retirement goal
                                    </li>
                                    <li>
                                        <span className="resetofgoal_box mr8" />
                                        Rest of the goals
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-tabularview" role="tabpanel" aria-labelledby="nav-tabularview-tab">
                            <div className="graph_container">

                                <div className="dashboardTable">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Year</th>
                                                    <th scope="col"><span className="dotstatus">Fresh Inv.</span></th>
                                                    <th scope="col"><span className="dotstatus darkyellowdot">Existing Inv.</span></th>
                                                    <th scope="col"><span className="dotstatus greendot">FV</span></th>
                                                    <th scope="col"><span className="dotstatus bluedot">Other Inc</span></th>
                                                    <th scope="col">Outflows</th>
                                                    <th scope="col">Net Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {// eslint-disable-next-line
                                                    cashflow && cashflow.length > 0 ?
                                                        cashflow.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{item.image !== undefined ? <> <img src={item.image.path} alt='goals' width={21} height={21} /></> : ''}</td>
                                                                        <td>{item.year}</td>
                                                                        <td>{formatAmount(item.fresh_investment)}</td>
                                                                        <td>{formatAmount(item.actual_fund)}</td>
                                                                        <td>{formatAmount(item.fv)}</td>
                                                                        <td>{item.other_income}</td>
                                                                        <td>{formatAmount(item.outflow)}</td>
                                                                        <td className={item.nett < 0 ? 'text-danger' : ''}>{formatAmount(item.nett)}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }) : ''}
                                                <tr>
                                                    <td></td> {/* Empty cell for the image column */}
                                                    <td><strong>Total</strong></td>
                                                    <td><strong>{formatAmount(cashflow.reduce((acc, item) => acc + item.fresh_investment, 0))}</strong></td>
                                                    <td><strong>{formatAmount(cashflow.reduce((acc, item) => acc + item.actual_fund, 0))}</strong></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><strong>{formatAmount(cashflow.reduce((acc, item) => acc + item.outflow, 0))}</strong></td>
                                                    <td></td>
                                                </tr>

                                            </tbody>


                                        </table>
                                    </div>
                                </div>


                            </div>

                            <div className="graph_list_outer">
                                <ul className="graph_detail_list">
                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolor0dc mr8" />
                                            Recommended
                                            <span className="listcheck ml10">
                                                <input type="checkbox" checked={true} readOnly />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolorfb9 mr8" />
                                            Desired target
                                            <span className="listcheck ml10">
                                                <input type="checkbox" value={retiredesirechecked}
                                                    onClick={(e) => { onChange(e) }}
                                                    id="retireDesireTabular"
                                                    name="retireDesireTabular" checked={retiredesirechecked} readOnly />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label className="listcheck_outer">
                                            <span className="dashline bgcolorbb8 mr8" />
                                            Ongoing + surplus
                                            <span className="listcheck ml10">
                                                <input type="checkbox" value={retireongoingchecked}
                                                    onClick={(e) => { onChange(e) }}
                                                    id="retireYearlyTabular"
                                                    name="retireYearlyTabular" checked={retireongoingchecked} readOnly />
                                                <span className="listcheck_box" />
                                            </span>
                                        </label>
                                    </li>


                                    <li>
                                        <span className="rupee_greenbox mr8" />
                                        Total savings
                                    </li>
                                    <li>
                                        <span className="flag_bluebox mr8" />
                                        Retirement goal
                                    </li>
                                    <li>
                                        <span className="resetofgoal_box mr8" />
                                        Rest of the goals
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}
