import React, { useEffect, Fragment, useState } from 'react'
import axioInstance from '../axiosInstance';
import { useForm } from 'react-hook-form'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function EditRetireAssumptions({ toggleClass, setActive, roiOption, riskAssetMax }) {

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {


        const profile_id = sessionStorage.getItem('profile_id');


        const postData = { profile_id: profile_id, post_return: postReturn, post_inflation: postInflation };

        axioInstance.post(`profile/update-retirement-post-params`, postData)
            .then(function (response) {


                if (roiOption === "1") {

                    axioInstance.post(`summary/calculate-roi`, { profile_id: profile_id, asset_alloc: riskAssetMax }).then(
                        (response, data) => {
                            const summaryUpdate = {
                                profile_id: profile_id,
                                roiChange: 1,
                                roi: response.data.roi * 100,
                                asset_alloc: (response.data.asset_alloc * 100)
                            }

                            axioInstance.post(`goals/reset`, { profile_id: profile_id })
                                .then(function (response) {


                                    axioInstance.post(`profile/summary-update`, summaryUpdate).then(
                                        (response, data) => {
                                            window.location.reload();
                                        }
                                    );


                                })

                        });

                } else {



                    const gpostData = { profile_id: profile_id };
                    axioInstance.post(`goals/reset`, gpostData)
                        .then(function (response) {

                            axioInstance.post(`profile/summary-update`, { profile_id: profile_id }).then(
                                (response, data) => {
                                    window.location.reload();
                                }
                            );


                        })


                }




            })

    }

    const [postInflation, setPostInflation] = useState();
    const [postReturn, setPostReturn] = useState();

    const equityPerc = (e) => {
        setPostInflation(e);
    }

    const debtPerc = (e) => {
        setPostReturn(e);
    }

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.get(`profile/summary/${profile_id}`).then(
            (response, data) => {


                setPostInflation(parseInt(response.data.assumption.post_retire_inflation));
                setPostReturn(parseInt(response.data.assumption.debt));


            });

    }, []);


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Edit Retirement Assumption
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Edit Retirement Assumption</div>
                        <div className="font14 fw500 color485">plan in your financial journey</div>
                    </div>

                    <div className="form_prnt p20">
                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="rangesliderbox">
                                <div className="rangesliderbox_title">
                                    <div>
                                        <div className="font12 fw500 color8c9">Post Retirement Inflation</div>
                                    </div>
                                    <div className="tragetvalue font13 fw700">{postInflation}</div>
                                </div>
                                <div className="rangebox_bar mt12">
                                    <div className="form-group">


                                        <Slider step={1} min={5} max={20} onChange={e => equityPerc(e)} value={postInflation} />

                                    </div>
                                </div>
                                <div className="flex_center justify_center mt8">
                                    <div className="font12 fw500 color9ba">5</div>
                                    <div className="font12 fw500 color9ba">20</div>
                                </div>
                                <input type="hidden" className="font14 fw500 ml15 mr15" name='post_inflation' value={postInflation} {...register("post_inflation")} />
                            </div>


                            <div className="devider_line mt25 mb25" />

                            <div className="rangesliderbox">
                                <div className="rangesliderbox_title">
                                    <div>
                                        <div className="font12 fw500 color8c9">Post Retirement Return</div>
                                    </div>
                                    <div className="tragetvalue font13 fw700">{postReturn}</div>
                                </div>
                                <div className="rangebox_bar mt12">
                                    <div className="form-group">


                                        <Slider step={1} min={5} max={20} onChange={e => debtPerc(e)} value={postReturn} />

                                    </div>
                                </div>
                                <div className="flex_center justify_center mt8">
                                    <div className="font12 fw500 color9ba">5</div>
                                    <div className="font12 fw500 color9ba">20</div>
                                </div>
                                <input type="hidden" className="font14 fw500 ml15 mr15" name='post_return' value={postReturn} {...register("post_return")} />
                            </div>


                        

                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}
