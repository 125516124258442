import React, { useState, useEffect } from 'react'
import axioInstance from '../axiosInstance';
import { inWords } from '../global'

export default function CashflowAnalysis() {


  const [totalIncome, setTotalIncome] = useState(null);
  const [totalExpense, setTotalExpense] = useState(null);

  const [selfIncome, setSelfIncome] = useState(null);
  const [spouseIncome, setSpouseIncome] = useState(null);
  const [RentalIncome, setRentalIncome] = useState(null);
  const [InvestmentIncome, setInvestmentIncome] = useState(null);
  const [Bonus, setBonus] = useState(null);
  const [rentalExpense, setRentalExpense] = useState(null);
  const [Household, setHousehold] = useState(null);
  const [ChildrenEducation, setChildrenEducation] = useState(null);
  const [utilityBills, setUtilityBills] = useState(null);
  const [lifestyle, setLifestyle] = useState(null);
  const [vacation, setVacation] = useState(null);
  // eslint-disable-next-line
  const [totalLoan, setTotalLoan] = useState(null);
  const [totalLiGi, setTotalLiGi] = useState(null);

  const [liabilities, setLiabilities] = useState([]);


  const [termData, setTermData] = useState([]);
  const [commitmentTotal, setCommitmentTotal] = useState(0);

  useEffect(() => {


    const profile_id = sessionStorage.getItem('profile_id');

    axioInstance.get(`profile/summary/${profile_id}`).then(
      (response, data) => {
        if (response.data.status === 100) {



          setTotalIncome(response.data.incomeSum.incomeSum);
          setTotalExpense(response.data.expenseSum.expenseSum);

          setTotalLoan(response.data.emiSum.emiSum ? parseFloat(response.data.emiSum.emiSum) : 0);

          setTotalLiGi(response.data.ligiSum.lifeSum ? parseFloat(response.data.ligiSum.lifeSum) : 0);

          setLiabilities(response.data.liability);

          response.data.incomes.forEach(item => {

            switch (item.sub_type) {
              case "IncomeSelf":
                setSelfIncome(item.total_amount);
                break;
              case "IncomeSpouse":
                setSpouseIncome(item.total_amount);
                break;

              case 'RentalIncome':
                setRentalIncome(item.total_amount);
                break;

              case 'InvestmentIncome':
                setInvestmentIncome(item.total_amount);
                break;

              case 'Bonus':
                setBonus(item.total_amount);
                break;

              case 'Household':

                setHousehold(item.total_amount);
                break;

              case 'rentalExpense':

                setRentalExpense(item.total_amount);
                break;

              case 'ChildrenEducation':

                setChildrenEducation(item.total_amount);
                break;

              case 'UtilityBills':

                setUtilityBills(item.total_amount);
                break;


              case 'Lifestyle':

                setLifestyle(item.total_amount);
                break;


              case 'Vacation':

                setVacation(item.total_amount);
                break;


              default:
                break;
            }
          })

        }
      });


    axioInstance.post(`profile/term-data`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {

      console.log(response.data);
      setTermData(response.data[0]);
      setCommitmentTotal(parseInt(response.data[0].healthpremiumAmount ? response.data[0].healthpremiumAmount : 0) + parseInt(response.data[0].spousepremiumAmount ? response.data[0].spousepremiumAmount : 0) + parseInt(response.data[0].selfpremiumAmount ? response.data[0].selfpremiumAmount : 0));
    });


  }, []);

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Personal Information</span></div>

      <div className="font22 fw700 color0C4 pt30">Cashflow Analysis</div>

      <div className="font14 fw500 color182 mt20">
        The cash flow analysis is the projection of your Income, Expenses and Investments for the current year. A positive and consistent Cash flow is an essential to achieve your financial goals. The Savings that you make every year should be invested in such a way that you meet your financial goals.
      </div>

      <div className="inflowbox_outer mt30">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total income including returns and inheritance.</div>
          <div className="font24 fw500 color263 mt12">{totalIncome > 0 ? inWords(totalIncome) : '00'}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">
            <div>
              <div className="font16 fw500 color263">{selfIncome > 0 ? inWords(selfIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Self income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{spouseIncome > 0 ? inWords(spouseIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Spouse income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{RentalIncome > 0 ? inWords(RentalIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Rental income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{InvestmentIncome > 0 ? inWords(InvestmentIncome) : '00'}</div>
              <div className="font13 color7B8 fw500">Investment Income</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{Bonus > 0 ? inWords(Bonus) : '00'}</div>
              <div className="font13 color7B8 fw500">Variable/Bonus income</div>
            </div>
          </div>
        </div>
      </div>


      <div className="inflowbox_outer mt15">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title orangedot font12 fw600 color6d7">TOTAL OUTFLOW</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total expenses.</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalExpense)}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">
            <div>
              <div className="font16 fw500 color263">{inWords(Household)}</div>
              <div className="font13 color7B8 fw500">HouseHold</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(ChildrenEducation)}</div>
              <div className="font13 color7B8 fw500">Kids Education</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(rentalExpense)}</div>
              <div className="font13 color7B8 fw500">Rental Paid</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(vacation)}</div>
              <div className="font13 color7B8 fw500">Vacation</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(utilityBills)}</div>
              <div className="font13 color7B8 fw500">Utility Bill</div>
            </div>
            <div>
              <div className="font16 fw500 color263">{inWords(lifestyle)}</div>
              <div className="font13 color7B8 fw500">Lifestyle</div>
            </div>
          </div>
        </div>
      </div>

      <div className="inflowbox_outer mt15">
        <div className="inflowbox_outer_left">
          <div className="inflowbox_title orangedot font12 fw600 color6d7">EMI & Commitments</div>
          <div className="font12 fw500 color7B8 mt8">Your family’s total EMIs and Commitments.</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalLoan + totalLiGi)}</div>
        </div>
        <div className="inflowbox_outer_right">
          <div className="inflowdtl">




            {
              liabilities.map((data, index) => {
                return (
                  <>

                    <div>
                      <div className="font16 fw500 color263">{inWords((data.emi * 12))}</div>
                      <div className="font13 color7B8 fw500">{data.inv_name}</div>
                    </div>
                  </>
                )
              })
            }

            <div>
              <div className="font16 fw500 color263">{(!termData.healthpremiumAmount) ? '00' : inWords((termData.healthpremiumAmount * termData.healthFrequency))}</div>
              <div className="font13 color7B8 fw500">{"Health Insurance"}</div>
            </div>

            <div>
              <div className="font16 fw500 color263">{!termData.selfpremiumAmount ? '00' : inWords(parseFloat(termData.selfpremiumAmount * termData.selfFrequency))}</div>
              <div className="font13 color7B8 fw500">{"Term Insurance"}</div>
            </div>

            <div>
              <div className="font16 fw500 color263">{!termData.selfpremiumAmount ? '00' : inWords(parseFloat(termData.spousepremiumAmount * termData.spouseFrequency))}</div>
              <div className="font13 color7B8 fw500">{"Spouse Term Insurance"}</div>
            </div>


          </div>
        </div>
      </div>



      <div className="totalSaving_box mt15">
        <div>
          <div className="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalIncome)}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">-</div>
        </div>
        <div>
          <div className="inflowbox_title orangedot font12 fw600 color6d7">TOTAL OUTFLOW</div>
          <div className="font24 fw500 color263 mt12">{inWords(parseInt(totalExpense) + parseInt(totalLoan) + parseInt(commitmentTotal))}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">=</div>
        </div>
        <div>
          <div className="inflowbox_title bluedot font12 fw600 color6d7">TOTAL SAVING</div>
          <div className="font24 fw500 color263 mt12">{inWords(totalIncome - (parseInt(totalExpense) + parseInt(totalLoan) + parseInt(commitmentTotal)))}</div>
        </div>
      </div>


    </>
  )
}
