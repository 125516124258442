import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
import {  ageCalculate } from '../../global';
//import formatAmount from 'indian-currency-formatter';
import RangeSlider from 'react-bootstrap-range-slider';

export default function AddRetirement({ setAddGoal, aspire }) {

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

  const Back = () => {
    setAddGoal('');
  }



  const onSubmit = data => {

    const retire_age = input;
    const age = profileAge;
    const life_expectancy = parseInt(assumptions.life_expentancy);
    const relation = profileRelation;
    const required_after = retire_age - age;
    const required_till = life_expectancy - age;
    const aspire = "Retirement";

    const PostData = {
      "goal_type": "Retirement",
      "goal_name": "Retirement",
      "auto_reset": "0",
      "aspire": aspire,
      "profile_id": sessionStorage.getItem('profile_id'),
      "plan_for": {
        "profile_details_id": data.plan_for,
        "current_age": age,
        "relation": relation
      },
      "goal_params": {
        "age": age,
        "retire_age": retire_age,
        "life_expectancy": life_expectancy,
        "post_inflation": 7,
        "post_return": 8,
        "goal_inflation": 7,
        "required_after": required_after,
        "required_till": required_till
      },
      "risk_profile": {
        "available": false,
        "pre_return": 10,
        "risk_profile_id": sessionStorage.getItem('profile_id')
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": data.amount * data.moneyDigits,
        "frequency": 12,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };

    axioInstance.post(`goals/add`, PostData)
      .then(function (response) {
        setAddGoal('');

        const profile_id = sessionStorage.getItem('profile_id');
        const gpostData = { profile_id: profile_id };
        axioInstance.post(`goals/reset`, gpostData)
          .then(function (response) {
            axioInstance.post(`profile/summary-update`, { profile_id: profile_id }).then(
              (response, data) => {
                window.location.reload();
              }
            );
          })

      });

  }

  const [input, setInput] = useState(60);

  const onChange = (e) => {
    setInput(e.currentTarget.value);
  };



  const [goalFor, setGoalFor] = useState([]);
  const [selfDetails, setSelfDetails] = useState([]);

  // eslint-disable-next-line
  const [assumptions, setAssumptions] = useState([]);

  useEffect(() => {
    console.log("First call on mount..");

    axioInstance.get(`profile-details/getfamily/` + sessionStorage.getItem('profile_id')).then(function (response, data) {

      const filtered = response.data.filter(obj => {
        return obj.relation === 'self' || obj.relation === 'spouse';
      });
      setGoalFor(filtered)

      let self = response.data.find(product => product.relation === "self");
      setSelfDetails(self);


      const postData = {
        'profile_id': sessionStorage.getItem('profile_id')
      }
      axioInstance.post(`get_assumption_by_profile_id`, postData).then(function (response) {
        setAssumptions(response.data);
      })

    });

    return () => console.log("Cleanup..");
  }, []);


  // eslint-disable-next-line
  const [max, setMax] = useState();
  const [ageFlag, setAgeFlag] = useState(false);
  const [selfAge, setSelfAge] = useState();
  useEffect(() => {

    if (ageFlag === false) {
      setProfileAge(ageCalculate(selfDetails.dob));
      setSelfAge(ageCalculate(selfDetails.dob));
      setMax(parseInt(assumptions.life_expentancy) - 1);
    }

    console.log('assumptions.life_expentancy', assumptions.life_expentancy);
  }, [max, selfDetails, assumptions, ageFlag]);


  const [profileAge, setProfileAge] = useState();
  const [profileRelation, setProfileRelation] = useState();
  const [goalFound, setGoalFound] = useState(false);

  console.log('profileAge', profileAge);
  const profileSelect = (e) => {

    setProfileAge(ageCalculate(e.target[e.target.selectedIndex].getAttribute('g_dob')));
    setAgeFlag(true);
    setMax(parseInt((assumptions.life_expentancy - (selfAge - 1))) + ageCalculate(e.target[e.target.selectedIndex].getAttribute('g_dob')));
    setProfileRelation(e.target[e.target.selectedIndex].getAttribute('g_relation'));

    const postData = {
      'plan_for': e.target.value,
      'goal_type': "Retirement",
      'profile_id': sessionStorage.getItem('profile_id')
    }
    axioInstance.post(`goals/check-plan-for-goal`, postData)
      .then(function (response) {
        if (response.data.count > 0) {
          setGoalFound(true);
        } else {
          setGoalFound(false);
        }
      });

  };


  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
            </div>
            <div className="font14 fw500 color182">
              Add {aspire} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_otherGoals pl25">
            <div className="font18 fw600 color182 mt50">{aspire}</div>
            <div className="font14 fw500 color485">Add {aspire} plan in your financial journey</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="col-md-12 pr20">

                  <div className="font14 color212 fw600">Goal Focused On</div>
                  <div className="selectgoal propertysearch mt10">
                    <div className="input-group selectarrow">
                      <select className="custom-select font14 color212 fw500" id="inputGroupSelect01" name='plan_for' {...register("plan_for", { required: 'Required' })} onChange={profileSelect}>
                        <option value="">select</option>

                        {
                          goalFor.map((prof_det, i) => {

                            return (
                              <>
                                <option value={prof_det.profile_details_id} g_dob={prof_det.dob} g_relation={prof_det.relation}>{prof_det.full_name}</option>
                              </>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <span className="text-danger"> {goalFound === true ? 'You have already created goal for this profile.' : errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />

              <div className="form-row mt20">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Your current expenses</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="amount" {...register("amount", { required: 'Current expense is required' })} maxLength={2} minLength={1} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")}>
                      <option selected value="10000">Thous.</option>
                      <option selected value="100000">Lakhs</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />

              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">When you intend to retire</div>
                    <div className="font12 fw500 color8c9">Adjust the estimate year to achive this goal</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{input}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">
                    <RangeSlider
                      value={input}
                      onChange={onChange}
                      step={1}
                      min={parseInt(profileAge) + 1}
                      max={max}
                      className={"form-control-range"}
                      tooltip={"off"}
                      defaultValue={input}
                    />
                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">{parseInt(profileAge) + 1}</div>
                  <div className="font12 fw500 color9ba">{max}</div>
                </div>
              </div>


              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                <button type="submit" className="btn btn-primary" disabled={goalFound === false ? !isDirty && !isValid : true}>Save </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}