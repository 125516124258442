import React, { Fragment } from 'react'
import { inWords } from '../../global';

export default function HealthSuggestionSidebar({toggleClass, setActive, insuranceDetails}) {

    const Back = () => {
        setActive(false);
    }

    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">
                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Rational behind suggestions
                        </div>
                        <div>
                            <button type="button" className="btn btn-primary btn-sm">Save</button>
                        </div>
                    </div>

                    <div className="pl20 pr20 pt20">
                        <span className="bigrupeecoinicon" />
                        <div className="mt15 color182 font14 fw500">Suggested based on <span className="fw700">Income</span></div>
                        <div className="font22 fw600 color263 mt10">{inWords(insuranceDetails.max_suggested)}</div>
                        <div className="mt5 color6d7 font12 fw500">
                            Because the income may increase from year to year and based on the current trend, we predict that your income will go upto 82LPA in next 10 years. We see that if you increase the cover, you will be able to afford it in very short span.
                        </div>
                        <div className="font12 fw600 color0d6 mt10">Contact Us <span className="bluerightarrow" /></div>

                        <div className="devider_line mt20 mb20" />

                        <span className="bigrupeecoinicon" />
                        <div className="mt15 color182 font14 fw500">Suggested based on <span className="fw700">Expense</span></div>
                        <div className="font22 fw600 color263 mt10">{inWords(insuranceDetails.min_suggested)}</div>
                        <div className="mt5 color6d7 font12 fw500">
                            Because the expense may increase from year to year and based on the current trend, we predict that your expense will go upto 12LPA in next 10 years. We see that if you increase the cover, your family will be able to afford the lifestyle in case of undesired outcomes.
                        </div>
                        <div className="font12 fw600 color0d6 mt10">Contact Us <span className="bluerightarrow" /></div>

                        <div className="devider_line mt20 mb20" />

                        <span className="goalicon" />
                        <div className="mt15 color182 font14 fw500">Suggested based on <span className="fw700">Goals</span></div>
                        <div className="font22 fw600 color263 mt10">-</div>
                        <div className="mt5 color6d7 font12 fw500">
                            Because the goal’s nature may increase from year to year and based on the current trend, we predict that you might have newer goals in future. We see that if you increase the cover, your goals can go undisturbed in the event of any undesired outcome.
                        </div>
                        <div className="font12 fw600 color0d6 mt10">Contact Us <span className="bluerightarrow" /></div>

                        <div className="devider_line mt20 mb20" />





                    </div>



                </div>
            </div>
        </Fragment>
    )
}
