import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from './pages/About'
import Home from './pages/Home'
import ProfileStepper from './pages/ProfileStepper'
import Qna from './pages/tallyso/Qna'
import Register from './pages/Register'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import AdminDashboard from './pages/AdminDashboard'
import AutoLogin from './pages/AutoLogin'
import FpReport from './pages/FpReport'
import HistoryFpReport from './pages/HistoryFpReport'
import Example from './pages/Chart'
import ULogin from './pages/ULogin'
import ULoginForgotPassword from './pages/ULoginForgotPassword'
import CashflowWizard from './pages/CashflowWizard'
import ThirdPartyApi from './pages/ThirdPartyApi'
import AdmRiskHistory from './pages/AdmRiskHistory'
import RiskSet from './pages/RiskSet'
import FinancialOpd from './pages/FinancialOpd'
import QnaResponse from './pages/tallyso/QnaResponse'
import ReportProfileTimeline from './pages/ReportProfileTimeline'
import ReportProfilePlanStatus from './pages/ReportProfilePlanStatus'
import ReportPlanSummaryDrilldownMonth from './pages/ReportPlanSummaryDrilldownMonth'
import ReportPlanSummaryDrilldownRelMonthWise from './pages/ReportPlanSummaryDrilldownRelMonthWise'
import ReportPlanSummaryDrilldownRelManager from './pages/ReportPlanSummaryDrilldownRelManager'
import GuestUsersDashboard from './pages/GuestUsersDashboard'
import ProfileEventLogsDashboard from './pages/ProfileEventLogsDashboard'
import PlanLoginLogoutLogs from './pages/PlanLoginLogoutLogs'

export default function RouterPage() {

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<ProfileStepper />}></Route>
                    <Route path="/home" element={<Home />}></Route>
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/fp-report" element={<FpReport />}></Route>
                    <Route path="/history-fp-report" element={<HistoryFpReport />}></Route>
                    <Route path="/basic-profile" element={<ProfileStepper />}></Route>
                    <Route path="/finnfit" element={<Qna />}></Route>
                    <Route path="/finnfit-thank-you" element={<QnaResponse />}></Route>
                    <Route path="/dashboard" element={<Dashboard />}></Route>
                    <Route path="/register" element={<Register />}></Route>
                    <Route path="/sign-in" element={<Login />}></Route>
                    <Route path="/ulogin" element={<ULogin />}></Route>
                    <Route path="/auto-sign-in" element={<AutoLogin />}></Route>
                    <Route path="/lms-login" element={<AutoLogin />}></Route>
                    <Route path="/recharts" element={<Example />}></Route>
                    <Route path="/ulogin-forgot-password" element={<ULoginForgotPassword />}></Route>
                    <Route path="/cashflow-wizard" element={<CashflowWizard />}></Route>
                    <Route path="/finnovate-restapi" element={<ThirdPartyApi />}></Route>
                    <Route path="/risk-history" element={<AdmRiskHistory />}></Route>
                    <Route path="/risk-profile-set" element={<RiskSet />}></Route>
                    <Route path="/financialopt-leads" element={<FinancialOpd />}></Route>
                    <Route path="/plan-profiles-timeline" element={<ReportProfileTimeline />}></Route>
                    <Route path="/plan-profiles-status" element={<ReportProfilePlanStatus />}></Route>

                    <Route path="/admin-dashboard" element={<AdminDashboard />}></Route>
                    <Route path="/guest-users-dashboard" element={<GuestUsersDashboard />}></Route>
                    <Route path="/profile-event-logs-dashboard" element={<ProfileEventLogsDashboard />}></Route>
                    <Route path="/plan-login-logs-dashboard" element={<PlanLoginLogoutLogs />}></Route>

                    <Route path="/plan-summary-report-drilldown-month" element={<ReportPlanSummaryDrilldownMonth />}></Route>
                    <Route path="/plan-summary-report-drilldown-month-relmanagerwise" element={<ReportPlanSummaryDrilldownRelManager />}></Route>
                    <Route path="/plan-summary-report-drilldown-relmanager-monthwise" element={<ReportPlanSummaryDrilldownRelMonthWise />}></Route>

                </Routes>
            </BrowserRouter>
        </div>
    )
}
