import React, { Fragment, useState } from 'react'

import CashflowWizardIncome from './CashflowWizardIncome';
import CashflowWizardExpense from './CashflowWizardExpense';
import CashflowWizardStart from './CashflowWizardStart';

export default function CashflowWizard() {

    // eslint-disable-next-line
    const [substep, setSubstep] = useState(0);

    return (
        <Fragment>
            {substep === 0 && <CashflowWizardStart substep={substep} setSubstep={setSubstep}/>}
            {substep === 1 && <CashflowWizardIncome substep={substep} setSubstep={setSubstep}/>}
            {substep === 2 && <CashflowWizardExpense substep={substep} setSubstep={setSubstep}/>}
        </Fragment>
    )
}
