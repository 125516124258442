import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2';
import axioInstance from '../../axiosInstance';
import {  ageCalculate } from '../../global';
import formatAmount from 'indian-currency-formatter';

export default function EditIncome({ toggleClass, setActive, spouseLi, spouseDetails, assumptions }) {


    const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

    const Back = () => {
        setActive(false);
    }

    const onSubmit = data => {

        const Postdata = {
            profile_id: sessionStorage.getItem('profile_id'),
            type: 1,
            consider: 1,
            step: "2",
            income: {
                IncomeSelf: {
                    "sub_type": "IncomeSelf",
                    "amount": data.IncomeSelf.replace(/,/g, ""),
                    "frequency": data.IncomeSelfFreq
                },
                IncomeSpouse: {
                    "sub_type": "IncomeSpouse",
                    "amount": data.IncomeSpouse.replace(/,/g, ""),
                    "frequency": data.IncomeSpouseFreq
                },
                RentalIncome: {
                    "sub_type": "RentalIncome",
                    "amount": data.RentalIncome.replace(/,/g, ""),
                    "frequency": data.RentalIncomeFreq
                },
                OtherIncome: {
                    "sub_type": "Bonus",
                    "amount": data.Bonus.replace(/,/g, ""),
                    "frequency": data.BonusFreq
                },
                InvestmentIncome: {
                    "sub_type": "InvestmentIncome",
                    "amount": data.InvestmentIncome.replace(/,/g, ""),
                    "frequency": data.InvestmentIncomeFreq
                }
            }
        };

        axioInstance.post(`profile-income`, Postdata)
            .then(function (response) {

                if (data.IncomeSpouse.replace(/,/g, "") > 0) {
                    const goalParams = {
                        "profile_id": sessionStorage.getItem('profile_id'),
                        "spouse_prof_details_id": sessionStorage.getItem('spouse_prof_det_id'),
                        "admin_id":sessionStorage.getItem("admin_id"),
                        "dob": ageCalculate(spouseDetails.dob),
                        "inflation": assumptions.inflation,
                        "income_grow": assumptions.income_grow,
                        "api_type": "insert",
                        "yearly_expense": 0,
                        "spouse_income": data.IncomeSpouse.replace(/,/g, ""),
                        "post_retire_inflation": assumptions.post_retire_inflation,
                        "life_expentancy": assumptions.life_expentancy,
                        "gi_dependents": 0,
                        "income": 0,
                        "min": 100000,
                        "max": 1000000,
                        "retire_age": assumptions.retire_age,
                        "auto_reset": "1",
                        "select_opt": "1",
                        "goal_id": spouseLi ? spouseLi.goal_id : ''
                    }
                    axioInstance.post(`goals/add-spouse-life`, goalParams).then(function (response) {

                        axioInstance.post(`goals/reset`, { profile_id: sessionStorage.getItem('profile_id') })
                        .then(function (response) {
    
                            axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                                (response, data) => {
    
                                    window.location.reload(true)
    
                                }
                            );
                        })

                    })
                }else{
                    axioInstance.post(`goals/reset`, { profile_id: sessionStorage.getItem('profile_id') })
                    .then(function (response) {

                        axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                            (response, data) => {

                                window.location.reload(true)

                            }
                        );
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
                SweetAlert.fire(
                    'Income',
                    'Failed. Something went wrong. Retry again',
                    'error'
                )
            });
    }

    const [income, setIncome] = useState(null);

    const [IncomeSelf, setIncomeSelf] = useState(null);
    const [IncomeSelfFreq, setIncomeSelfFreq] = useState(null);
    const [incomeSelfId, setIncomeSelfId] = useState(null);

    const [IncomeSpouse, setIncomeSpouse] = useState(null);
    const [IncomeSpouseFreq, setIncomeSpouseFreq] = useState(null);
    const [IncomeSpouseId, setIncomeSpouseId] = useState(null);

    const [Bonus, setBonus] = useState(null);
    const [BonusFreq, setBonusFreq] = useState(null);
    const [IncomeBonusId, setIncomeBonusId] = useState(null);

    const [RentalIncome, setRentalIncome] = useState(null);
    const [RentalIncomeFreq, setRentalIncomeFreq] = useState(null);
    const [IncomeRentalIncomeId, setIncomeRentalIncomeId] = useState(null);

    const [InvestmentIncome, setInvestmentIncome] = useState(null);
    const [InvestmentIncomeFreq, setInvestmentIncomeFreq] = useState(null);
    const [IncomeInvestmentIncomeId, setIncomeInvestmentIncomeId] = useState(null);


    const [incomeSelfAmount, setIncomeSelfAmount] = useState();
    const [incomeSpouseAmount, setIncomeSpouseAmount] = useState();
    const [bonusAmount, setBonusAmount] = useState();
    const [rentalIncomeAmount, setRentalIncomeAmount] = useState();
    const [investmentIncomeAmount, setOnvestmentIncomeAmount] = useState();

    const handleChange = (e) => {

        const { value } = e.target;

        if (e.target.name === 'IncomeSelf') {
            setIncomeSelfAmount(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'IncomeSpouse') {
            setIncomeSpouseAmount(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'Bonus') {
            setBonusAmount(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'RentalIncome') {
            setRentalIncomeAmount(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'InvestmentIncome') {
            setOnvestmentIncomeAmount(formatAmount(value.replace(/,/g, "")));
        }

    };


    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        if (profile_id !== null) {
            axioInstance.post(`get-income-expense`, { "profile_id": profile_id, "type": 1 }).then(
                (response, data) => {
                    if (response.data.length > 0) {


                        console.log(response.data);

                        response.data.forEach(item => {

                            switch (item.type_name_sf) {

                                case 'IncomeSelf':
                                    setIncomeSelfId(item.income_id);
                                    setIncomeSelfFreq(item.frequency);
                                    setIncomeSelf(item.amount);
                                    break;

                                case 'IncomeSpouse':
                                    setIncomeSpouseId(item.income_id);
                                    setIncomeSpouseFreq(item.frequency);
                                    setIncomeSpouse(item.amount);
                                    break;

                                case 'RentalIncome':
                                    setIncomeRentalIncomeId(item.income_id);
                                    setRentalIncomeFreq(item.frequency);
                                    setRentalIncome(item.amount);
                                    break;

                                case 'InvestmentIncome':
                                    setIncomeInvestmentIncomeId(item.income_id);
                                    setInvestmentIncomeFreq(item.frequency);
                                    setInvestmentIncome(item.amount);
                                    break;

                                case 'Bonus':
                                    setIncomeBonusId(item.income_id);
                                    setBonusFreq(item.frequency);
                                    setBonus(item.amount);
                                    break;

                                default:
                                    break;

                            }

                        });

                        setIncome({ IncomeSelf: IncomeSelf > 0 ? formatAmount(IncomeSelf) : '', IncomeSelfFreq: IncomeSelfFreq > 0 ? IncomeSelfFreq : 12, incomeSelfId, IncomeSpouse: formatAmount(IncomeSpouse), IncomeSpouseFreq: IncomeSpouseFreq > 0 ? IncomeSpouseFreq : 12, IncomeSpouseId: IncomeSpouseId, Bonus: formatAmount(Bonus), BonusFreq: BonusFreq > 0 ? BonusFreq : 12, IncomeBonusId: IncomeBonusId, RentalIncome: formatAmount(RentalIncome), RentalIncomeFreq: RentalIncomeFreq > 0 ? RentalIncomeFreq : 12, IncomeRentalIncomeId: IncomeRentalIncomeId, InvestmentIncome: formatAmount(InvestmentIncome), InvestmentIncomeFreq: InvestmentIncomeFreq > 0 ? InvestmentIncomeFreq : 12, IncomeInvestmentIncomeId: IncomeInvestmentIncomeId })
                    }
                });
        }
    }, [IncomeSelf, IncomeSelfFreq, incomeSelfId, IncomeSpouse, IncomeSpouseFreq, IncomeSpouseId, Bonus, BonusFreq, IncomeBonusId, RentalIncome, RentalIncomeFreq, IncomeRentalIncomeId, InvestmentIncome, InvestmentIncomeFreq, IncomeInvestmentIncomeId]);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(income);
        // eslint-disable-next-line
    }, [income]);

    return (
        <Fragment>


            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Edit Earned Income
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner topBanner_editExpenses pl25">
                        <div className="font18 fw600 color182 mt50">Earned Income</div>
                        <div className="font14 fw500 color485">{sessionStorage.getItem('full_name')} family’s earned income</div>
                    </div>

                    <div className="form_prnt p20">
                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <input type="hidden" name="IncomeSelfId" {...register("IncomeSelfId")} />
                            <input type="hidden" name="IncomeSpouseId" {...register("IncomeSpouseId")} />
                            <input type="hidden" name="IncomeBonusId" {...register("IncomeBonusId")} />
                            <input type="hidden" name="IncomeRentalIncomeId" {...register("IncomeRentalIncomeId")} />
                            <input type="hidden" name="IncomeInvestmentIncomeId" {...register("IncomeInvestmentIncomeId")} />

                            <div className="form-row">
                                <div className="col-md-12 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Self Income</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="IncomeSelf" {...register("IncomeSelf", { required: 'Income Self is required' })} value={incomeSelfAmount} onChange={handleChange} />
                                        <select className="custom-select" id="inputGroupSelect01" name="IncomeSelfFreq" {...register("IncomeSelfFreq")}>
                                            <option value="12" selected>Monthly</option>
                                            <option value="1">Yearly</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.IncomeSelf && errors.IncomeSelf.message}</small>
                                </div>
                            </div>

                            <div className="form-row mt25">
                                <div className="col-md-12 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Variable/Bonus</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="Bonus" {...register("Bonus", { required: 'Other Income is required' })} value={bonusAmount} onChange={handleChange} />
                                        <select className="custom-select" id="inputGroupSelect01" name="BonusFreq" {...register("BonusFreq")}>
                                            <option value="12" selected>Monthly</option>
                                            <option value="1">Yearly</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.Bonus && errors.Bonus.message}</small>
                                </div>
                            </div>

                            <div className="form-row mt25">
                                <div className="col-md-12 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Spouse Income</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="IncomeSpouse" {...register("IncomeSpouse", { required: 'Income Spouse is required' })} value={incomeSpouseAmount} onChange={handleChange} />
                                        <select className="custom-select" id="inputGroupSelect01" name="IncomeSpouseFreq" {...register("IncomeSpouseFreq")}>
                                            <option value="12" selected>Monthly</option>
                                            <option value="1">Yearly</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.IncomeSpouse && errors.IncomeSpouse.message}</small>
                                </div>
                            </div>

                            <div className="form-row mt25">
                                <div className="col-md-12 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Rental Income</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="RentalIncome" {...register("RentalIncome", { required: 'Rental Income is required' })} value={rentalIncomeAmount} onChange={handleChange} />
                                        <select className="custom-select" id="inputGroupSelect01" name="RentalIncomeFreq" {...register("RentalIncomeFreq")}>
                                            <option value="12" selected>Monthly</option>
                                            <option value="1">Yearly</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.RentalIncome && errors.RentalIncome.message}</small>
                                </div>
                            </div>

                            <div className="form-row mt25">
                                <div className="col-md-12 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Investment Income</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="InvestmentIncome" {...register("InvestmentIncome", { required: 'Rental Income is required' })} value={investmentIncomeAmount} onChange={handleChange} />
                                        <select className="custom-select" id="inputGroupSelect01" name="InvestmentIncomeFreq" {...register("InvestmentIncomeFreq")}>
                                            <option value="12" selected>Monthly</option>
                                            <option value="1">Yearly</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <small id="emailHelp" className="form-text text-muted font11 text-danger">{errors.InvestmentIncome && errors.InvestmentIncome.message}</small>
                                </div>
                            </div>



                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
                            </div>
                        </form>
                    </div>



                </div>
            </div>

        </Fragment >
    )
}
