import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import SweetAlert from 'sweetalert2';
import formatAmount from 'indian-currency-formatter';
import SideImg from '../../img/Protection.png';

export default function ProtectionScreen2({ setSubstep, substep, nextsubStep, StepChange, steps, nextStep, prevsubStep, lifeStage, clientName }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [health, setHealth] = useState(null);
  const [healthFor, setHealthFor] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [premiumAmount, setPremiumAmount] = useState();
  const handleChange = (e) => {

    const { value } = e.target;

    if (e.target.name === 'premiumAmount') {
      setPremiumAmount(formatAmount(value.replace(/,/g, "")));
    }

  };

  useEffect(() => {

    axioInstance.post(`profile/term-data`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {
      const res = response.data[0];
      setHealth({ healthInsurance: res.healthInsurance, sumAssured: res.healthsumAssured > 0 ? res.healthsumAssured : '', selfMoneyType: res.healthMoneyType, premiumAmount: res.healthpremiumAmount > 0 ? formatAmount(res.healthpremiumAmount) : '', selfInvestId: res.healthInvestId, insured: res.healthCoverIds, selfFrequency: res.healthFrequency });
    });

    axioInstance.get(`profile-details/getfamily/` + sessionStorage.getItem('profile_id')).then(function (response, data) {
      setHealthFor(response.data);
    });


  }, []);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(health);
    // eslint-disable-next-line
  }, [health]);

  const onSubmit = data => {

    setButtonSpinner(true);

    if (data.healthInsurance === "yes") {

      const Postdata = {
        "prop_id": sessionStorage.getItem('profile_id'),
        "profile_id": sessionStorage.getItem('profile_id'),
        "profile_details_id": sessionStorage.getItem('profile_details_id'),
        "proposer": sessionStorage.getItem('profile_details_id'),
        "type": 9,
        "sub_type": 1,
        "owner": sessionStorage.getItem('profile_id'),
        "instr_name": 'Health',
        "invest_type": 0,
        "invest_amount": data.premiumAmount.replace(/,/g, ""),
        "amount": data.premiumAmount.replace(/,/g, ""),
        "cost_amount": data.premiumAmount.replace(/,/g, ""),
        "insured_value": data.selfMoneyType * data.sumAssured,
        "current_value": data.selfMoneyType * data.sumAssured,
        "frequency": data.selfFrequency,
        "moneyDigits": data.selfMoneyType,
        "ppt": 1,
        "policy_term": 1,
        "rider_id": Array.isArray(data.insured) ? data.insured : [data.insured],
        "admin_id":sessionStorage.getItem("admin_id")
      };

      axioInstance.post(`investment/add-investment`, Postdata)
        .then(function (response) {

          axioInstance.post(`profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), 'admin_id':sessionStorage.getItem('admin_id'), step: steps + 1 }).then(function (response, data) {
            setButtonSpinner(false);
            StepChange(nextStep(steps));
          });

        })
        .catch(function (error) {

          SweetAlert.fire({
            toast: true,
            icon: 'error',
            title: 'Failed. Something went wrong. Retry again.',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', SweetAlert.stopTimer)
              toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
            }
          });

        });

    } else {

      axioInstance.post(`profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), 'admin_id':sessionStorage.getItem('admin_id'), step: steps + 1 }).then(function (response, data) {
        setButtonSpinner(false);
        StepChange(nextStep(steps));
      });

    }

  }

  const [selfInput, setSelfInput] = useState(true);

  const validateSelf = (checkbox) => {

    if (checkbox === "no") {
      setSelfInput(false);
    } else {
      setSelfInput(true);
    }

  }

  return (
    <Fragment>

      <div className="mainwrapper">

        <div className="form_title">

          <div className="mt30 font20 fw600">{clientName} Protection</div>
          <div className="font15 color495">Few questions to understand your family's protection</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li onClick={() => setSubstep(prevsubStep(substep))}>1</li>
              <li className="act">2</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Is your health well protected?</div>
                  <div className="font15 color495 ">Give us a sneak of your health insurance details</div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600 d-none d-md-block" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}

              <div className="p35">

                <div className="form-row">
                  <div>
                    <div className="color212 font14 fw600">Do you have Health Insurance? <span className="colorfa8">*</span>
                    </div>
                    <ul className="steps_tag font14 fw500 color182 mt15">
                      <li>
                        <label>
                          <input type="radio" name="healthInsurance" {...register("healthInsurance", { required: 'Health Insurance is required' })} value="yes" onClick={() => validateSelf('yes')} checked={selfInput === true ? "checked":""}/>
                          <span className="rdo_text_box">
                            Yes <i className="fa-solid fa-check FaIcon"></i>
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type="radio" name="healthInsurance" {...register("healthInsurance", { required: 'Health Insurance is required' })} value="no" onClick={() => validateSelf('no')} {...selfInput === false ? "checked": ""}/>
                          <span className="rdo_text_box">
                            No <i className="fa-solid fa-xmark FaIcon"></i>
                          </span>
                        </label>
                      </li>
                    </ul>
                    <span className="text-danger"> {errors.healthInsurance && errors.healthInsurance.message}</span>
                  </div>
                </div>


                {
                  selfInput === true &&
                  (<>

                    <div className="form-row">
                      <div className="col-md-6 pr20">
                        <label htmlFor="inputEmail4" className="font14 fw600">Sum assured</label>
                        <div className="input-group selectarrow">
                          <input type="text" className="form-control font14 color212 fw500" name="sumAssured" aria-label="Text input with dropdown button" autoComplete='off' {...register("sumAssured", { required: 'Sum assured is required' })} maxLength={3} minLength={1} />
                          <select className="custom-select" id="inputGroupSelect01" name='selfMoneyType' {...register("selfMoneyType")}>
                            <option selected value="100000">Lakhs</option>
                            <option value="10000000">Crore</option>
                          </select>
                          <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                        </div>
                        <span className="text-danger"> {errors.sumAssured && errors.sumAssured.message}</span>
                      </div>
                      <div className="col-md-6 pl20">
                        <label htmlFor="inputEmail4" className="font14 fw600">Premium amount</label>
                        <div className="input-group selectarrow">
                          <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name="premiumAmount" autoComplete='off' {...register("premiumAmount", { required: 'Premium Amount is required' })} onChange={handleChange} value={premiumAmount} />
                          <select className="custom-select" id="inputGroupSelect01" name='selfFrequency' {...register("selfFrequency")}>
                            <option value="12">Monthly</option>
                            <option value="4">Quarterly</option>
                            <option value="2">Half Yearly</option>
                            <option value="1" selected>Yearly</option>
                            <option value="0">Lumpsum</option>
                          </select>
                          <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                        </div>
                        <span className="text-danger"> {errors.premiumAmount && errors.premiumAmount.message}</span>
                      </div>
                    </div>
                    <div className="formdevider mt35 mb35" />

                    <div className="form-row">
                      <div>
                        <div className="font14 color212 fw600">Health Insurance avaialble For</div>
                        <ul className="steps_tag font14 fw500 color182 mt15">

                          {healthFor.map((data, key) => {
                            return (
                              <li key={key}>
                                <label>
                                  <input type="checkbox" name="insured" {...register("insured", { required: 'Select at least one member' })} value={data.profile_details_id} />
                                  <span className="rdo_text_box">
                                    {data.first_name}
                                  </span>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                        <span className="text-danger"> {errors.insured && errors.insured.message}</span>
                      </div>
                    </div>
                  </>)
                }

              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary"> {buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"} </button>
              </div>

            </form>
            {/* Form End */}

          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              {"We care about your insurance because this comes first in your financial journey."}
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection'/></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
