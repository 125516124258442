import React, { useEffect, useState } from 'react'
import { ageCalculate } from '../global'

export default function HistoryPersonalInformation({ familyData }) {

  // eslint-disable-next-line
  const [self, setSelf] = useState([]);

  useEffect(() => {

    let selfData = familyData.find(product => product.profileDetails_relation === "self");
    setSelf(selfData);

  }, [familyData])

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Personal Information</span></div>

      <div className="font22 fw700 color0C4 pt30">Personal Information</div>

      <div className="personInfo_outer mt25">
        <div className="personInfo_title font16 fw600 color263">{self.profileDetails_full_name} <span className="color7B8">(you)</span></div>
        <div className="infotab_outer mt10">
          <span className="infotab font12 fw500 color263">{ageCalculate(self.profileDetails_dob)} yrs</span> <span className="infotab font12 fw500 color263">{self.profileDetails_dob}</span> <span className="infotab font12 fw500 color263">{self.profileDetails_email}</span> <span className="infotab font12 fw500 color263">+91 {self.profileDetails_phone}</span>
        </div>
      </div>

      <div className="person_info_tbl mt30">
        <table className="table font12">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date of birth</th>
              <th scope="col">Age</th>
              <th scope="col">Relationship</th>
            </tr>
          </thead>
          <tbody>

            {
              familyData.map((family, key) => {


                return (
                  <>
                    {
                      family.profileDetails_relation !== 'self' && (<>

                        <tr>
                          <th scope="row">{family.profileDetails_full_name}</th>
                          <td>{family.profileDetails_dob_flag === "1" ? "NA" : family.profileDetails_dob}</td>
                          <td>{family.age > 0 ? family.age + ' y' : family.age_month + ' m'} </td>
                          <td>{family.profileDetails_relation}</td>
                        </tr>
                      </>)
                    }

                  </>
                )
              })
            }

          </tbody>
        </table>
      </div>

      <div className="font14 fw500 color182 mt45">
        As per our life stages Wealth Creation can be divided in various phases,
      </div>

      <ul className="variousList mt15 font14 color182 fw500">
        <li>Foundation Phase - Age 20 to 30</li>
        <li>Accumulation Phase Age 30 to 55</li>
        <li>Preservation Phase Age 55 to 75</li>
        <li>Distribution Phase - Age 75+</li>
      </ul>

    </>
  )
}
