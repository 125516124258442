import React, { Fragment, useState, useEffect } from 'react'
import axioInstance from '../axiosInstance';
import {  inWords } from '../global';
import AddEmergencyFund from './EditInvestment/AddEmergencyFund';

export default function DashboardRightEmegencyFundGrid() {

    const [emergencyFund, setEmergencyFund] = useState([]);
    const [requiredFund, setRequiredFund] = useState(0);

    const [isActive, setActive] = useState(false);
    const toggleClass = (form) => {
        setActive(form);
    };

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.post(`emergency-fund/summary`, {profile_id:profile_id}).then(
            (response, data) => {
                setEmergencyFund(response.data);
            });


            axioInstance.post(`investment/recommended-assets`, { profile_id: profile_id, primary_flag: "Y", radio_type: 10 }).then((response, data) => {
    
                setRequiredFund(response.data.grand_total !== undefined ? response.data.grand_total.required_emergency_fund : false);
    
            });

    }, []);

    return (
        <Fragment>
            <div className="emergencyfund_card pl30 pr30">
                <div className="emergencyfund_title_flex mt30">
                    <div className="coveragetitle">
                        <div className="svgprogressbar">
                            <div className="progress-bar">
                                <progress value="75" min="0" max="100">75%</progress>
                            </div>
                        </div>
                        <div className="pl15">
                            <div className="font14 fw500 color6d7">Emergency funds</div>
                            <div className="font21 fw700 color182">{emergencyFund ? inWords(emergencyFund.total) : '00'}</div>
                        </div>
                    </div>
                    <div>
                        <div className="font14 fw500 color6d7">Total Goal</div>
                        <div className="font21 fw700 color182">{requiredFund ? inWords(requiredFund) : '00'}</div>
                    </div>
                </div>
                <div className="coveragechart_card mt20">
                    <div className="coveragechart_card_hdr font12 fw500 color949 pb10">
                        <div className="coveragechart_card_hdr_inner">Total Funds</div>
                        <div className="coveragechart_card_hdr_inner">Liquid MF</div>
                        <div className="coveragechart_card_hdr_inner">Short Term FD</div>
                        <div className="coveragechart_card_hdr_inner">Cash in bank</div>
                        <div className="coveragechart_card_hdr_inner">Add more</div>
                    </div>
                    <div className="coveragechart_card_row font14 fw600 color263">
                        <div className="coveragechart_card_row_inner">{emergencyFund ? inWords(emergencyFund.total) : '00'}</div>
                        <div className="coveragechart_card_row_inner">{emergencyFund ? inWords(emergencyFund.mf) : '00'} </div>
                        <div className="coveragechart_card_row_inner">{emergencyFund ? inWords(emergencyFund.fd) : '00'}</div>
                        <div className="coveragechart_card_row_inner">{emergencyFund ? inWords(emergencyFund.other) : '00'}</div>
                        <div className="coveragechart_card_row_inner">
                            <span className="color0d6 font12" onClick={() => toggleClass('fund')}>Add Funds <span className="bluerightarrow ml5"></span></span>
                        </div>
                    </div>
                </div>
                <span className="imgbanner"></span>
            </div>

            {
                isActive === "fund" && (<AddEmergencyFund toggleClass={toggleClass} setActive={setActive}/>)
            }

        </Fragment>
    )
}
