import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {  ageCalculate } from '../../global';
import formatAmount from 'indian-currency-formatter';
//import RangeSlider from 'react-bootstrap-range-slider';
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./../tabs/slider.css";
import axioInstance from '../../axiosInstance';

export default function EditRetirement({ setGoalType, goalId, planLatestStatus }) {

  const { register, handleSubmit, reset, setFocus, formState: { errors, isDirty, isValid } } = useForm();

  const Back = () => {
    setGoalType('');
  }
  // eslint-disable-next-line
  const [max, setMax] = useState();


  const onSubmit = data => {

    const retire_age = input;
    const age = data.profile_age;
    const life_expectancy = parseInt(assumptions.life_expentancy);
    const relation = data.relation;
    const required_after = parseInt(retire_age) - parseInt(age);
    const required_till = parseInt(life_expectancy) - parseInt(age);
    const aspire = "Retirement";

    const PostData = {
      "goal_type": "Retirement",
      "auto_reset": "0",
      "aspire": aspire,
      "goal_name": data.goal_name,
      "goal_id": data.goal_id,
      "profile_id": data.profile_id,
      "admin_id":sessionStorage.getItem("admin_id"),
      "plan_for": {
        "profile_details_id": data.plan_for,
        "current_age": age,
        "relation": relation
      },
      "goal_params": {
        "age": age,
        "retire_age": retire_age,
        "life_expectancy": life_expectancy,
        "post_inflation": assumptions.post_retire_inflation ? assumptions.post_retire_inflation : 7,
        "post_return": postReturn ? postReturn : 8,
        "goal_inflation": inflation,
        "required_after": required_after,
        "required_till": required_till
      },
      "risk_profile": {
        "available": false,
        "pre_return": 10,
        "risk_profile_id": data.profile_id
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": inflation,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": data.amount.replace(/,/g, ""),
        "frequency": 12,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };



    const assumptionData = { profile_id: data.profile_id, assumption: { retire_age: retire_age, life_expentancy: life_expectancy, inflation: inflation } };

    axioInstance.post(`profile-assumptions/update_assumption`, assumptionData)
      .then(function (response) {
        axioInstance.post(`goals/add`, PostData)
          .then(function (response) {


            const gpostData = { profile_id: sessionStorage.getItem('profile_id') };
            axioInstance.post(`goals/reset`, gpostData)
              .then(function (response) {
                axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                  (response, data) => {
                    window.location.reload();
                  }
                );
              })
          })

      })



    /*axioInstance.post(`goals/add`, PostData)
      .then(function (response) {
        setGoalType('');

        const gpostData = { profile_id: data.profile_id };
        axioInstance.post(`goals/reset`, gpostData)
            .then(function (response) {
                axioInstance.post(`profile/summary-update`, { profile_id: data.profile_id }).then(
                    (response, data) => {
                        window.location.reload();
                    }
                );
            })

      });*/



  }

  const [input, setInput] = useState(60);

  const onChange = (e) => {


    setInput(e[1]);

    setFocus("year");
  };

  const [state, setState] = useState(false);
  const [goalData, setGoalData] = useState([]);
  const [validate, setValidate] = useState(null);

  // eslint-disable-next-line
  const [currAge, setCurrAge] = useState();

  const [selfDetails, setSelfDetails] = useState([]);

  // eslint-disable-next-line
  const [assumptions, setAssumptions] = useState([]);

  const [checked, setChecked] = useState();
  const [auto_reset, setAuto_reset] = useState();

  const [postReturn, setPostReturn] = useState();

  useEffect(() => {

    setState(true);
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null && state !== false) {
      axioInstance.post(`goals/goalbyid`, { "profile_id": profile_id, "goal_id": goalId }).then(
        (response, data) => {


          setGoalData(response.data);
          setChecked(response.data.auto_reset === "1" ? '' : 'checked');
          setAuto_reset(response.data.auto_reset);
          setCurrAge(response.data.current_age)
          setPostReturn(response.data.post_return);
          setInput(parseInt(response.data.retire_age));

          setValidate({ goal_id: response.data.goal_id, plan_for: response.data.plan_for, profile_id: response.data.profile_id, profile_age: response.data.profile_age, amount: formatAmount(response.data.total_abcd / 12), year: response.data.retire_age, goal_name: response.data.goal_name, aspire: response.data.aspire });

          setInflation(Math.round(response.data.goal_inflation));

        });

      axioInstance.get(`profile-details/getfamily/` + sessionStorage.getItem('profile_id')).then(function (response, data) {


        let self = response.data.find(product => product.relation === "self");
        setSelfDetails(self);


        const postData = {
          'profile_id': sessionStorage.getItem('profile_id')
        }
        axioInstance.post(`get_assumption_by_profile_id`, postData).then(function (response) {
          setAssumptions(response.data);
        })

      });
    }
  }, [state, goalId]);

  const [profileAge, setProfileAge] = useState();

  useEffect(() => {

    setProfileAge(ageCalculate(selfDetails.dob));
    setMax((parseInt(assumptions.life_expentancy) - 1));

    console.log('assumptions.life_expentancy', assumptions.life_expentancy);
  }, [max, selfDetails, assumptions]);
  console.log('(parseInt(assumptions.life_expentancy) - 1)', max);
  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(validate);
    // eslint-disable-next-line
  }, [validate]);

  const [expenses, setExpenses] = useState();
  const handleChange = (e) => {

    const { value } = e.target;

    if (e.target.name === 'amount') {
      setExpenses(formatAmount(value.replace(/,/g, "")));
    }

  };

  const [inflation, setInflation] = useState();

  const changeInflation = (e) => {

    setInflation(e[1]);
  }


  const changeAutoReset = (e) => {

    setChecked(checked === 'checked' ? '' : 'checked');
    setAuto_reset(checked === 'checked' ? '1' : '0');

  }

  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
            </div>
            <div className="font14 fw500 color182">
              Edit {goalData.goal_name} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_otherGoals pl25">
            <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
            <div className="font18 fw600 color182">{goalData.goal_name}</div>
            <div className="font14 fw500 color485">Add {goalData.goal_name} plan in your financial journey</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="expandtab font12 color353 fw500">{goalData.full_name + ' ' + goalData.goal_name}</div>
              <br />
              <small className='text-danger'>{planLatestStatus === 7 && 'Plan Freezed'}</small>
              <input type={"hidden"} name="plan_for" {...register("plan_for")} />
              <input type={"hidden"} name="profile_id" {...register("profile_id")} />
              <input type={"hidden"} name="profile_age" {...register("profile_age")} />
              <input type={"hidden"} name="goal_id" {...register("goal_id")} />
              <input type={"hidden"} name="aspire" {...register("aspire")} />
              <input type={"hidden"} name="goal_name" {...register("goal_name")} />



              <div className="form-row">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Your current expenses</label>
                  <div className="input-group">
                    <input type="text" className="form-control font14 color212 fw500" name="amount" {...register("amount", { required: 'Current expense is required' })} maxLength={12} defaultValue={expenses} value={expenses} onChange={handleChange} />
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />


              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Retirement</div>
                    <div className="font12 fw500 color8c9">When you intend to retire?</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{input}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">


                    <RangeSlider id="range-slider-grey"
                      className="single-thumb"
                      value={[0, input]}
                      thumbsDisabled={[true, false]}
                      rangeSlideDisabled={false}
                      step={1}
                      min={parseInt(profileAge) + 1}
                      max={max}
                      onInput={e => onChange(e)}
                    />


                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">{parseInt(profileAge) + 1}</div>
                  <div className="font12 fw500 color9ba">{max}</div>
                </div>
                <input type="hidden" className="font14 fw500 ml15 mr15" name='year' defaultValue={input} {...register("year")} />
              </div>


              <div className="formdevider mt25 mb25" />


              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Inflation</div>
                    <div className="font12 fw500 color8c9">Assumed inflation for goals ?</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{inflation}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">


                    <RangeSlider id="range-slider-grey"
                      className="single-thumb"
                      value={[0, inflation]}
                      thumbsDisabled={[true, false]}
                      rangeSlideDisabled={false}
                      step={1}
                      min={5}
                      max={14}
                      onInput={e => changeInflation(e)}
                    />


                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">{5}</div>
                  <div className="font12 fw500 color9ba">{14}</div>
                </div>
                <input type="hidden" className="font14 fw500 ml15 mr15" name='year' defaultValue={input} {...register("year")} />
              </div>

              <div className="form-row mt50">
                <div className="col-md-12 pr20">
                  <input id="checkbox1" type="checkbox" name={"auto_reset"} checked={checked} value={auto_reset} onChange={changeAutoReset} className='autoReset mr5' />
                  <label className="text-muted font12" for="checkbox1"> Click to Disable Auto Reset </label>
                </div>
              </div>

              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                {planLatestStatus !== 7 && <button type="submit" className="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}