import { useState } from 'react';
//import axioInstance from '../components/axiosInstance';
import axios from 'axios';

export default function FinancialOpd() {

    const [posts, setPosts] = useState([]);

    const handleClick = () => {

        const data = JSON.stringify({
            "utm_id": 9402,
            "utm_source": "Testing",
            "utm_campaign": "Internal",
            "first_name": "Rahul",
            "last_name": "Tambe",
            "mobile": 9552154593,
            "email": "anojtambe211@finnovate.in"
        });

        try {
            const response = axios.post('https://lmslive.finnovate.in/php/tallyapi.php', data);

            setPosts(response);

        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <br /><br />
            <h1>API TEST</h1>
            <br /><br />
            <button onClick={handleClick} className='btn'>Add Lead</button>
            <br /><br />
            <div className="grid">
                <h2>server response</h2>
                {posts.status}
            </div>
        </div>
    );
}
