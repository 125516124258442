import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'
import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';
import axios from 'axios';

export default function GuestUsersDashboard() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {



      axioInstance.post(`admin/guest-users`).then(
        (response, data) => {
          setData(response.data);
          setTableRowsData(response.data);
        }
      );


      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);


  const columns = [
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <>         <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" variant="success" className='planstatus_dots'>
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => VerifyGuestUser(d.id, d.full_name, d.mobile, d.email, d.password, d.created_by)}> <i className="fas fa-user text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Verify User</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown></>,
    },
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true
    },
    {
      name: 'Name',
      selector: row => row.full_name,
      sortable: true
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      width: '265px' // Set the width of the column
    },
    {
      name: 'Phone',
      selector: row => row.mobile,
      sortable: true,
      width: '180px' // Set the width of the column
    },
    {
      name: 'Created On',
      selector: row => row.created,
      sortable: true,
    },
    {
      name: 'Created By',
      selector: row => row.admin_name,
      sortable: true,
    },
    {
      name: 'UTM Campaign',
      selector: row => row.utm_campaign,
      sortable: true,
    },
    {
      name: 'UTM Source',
      selector: row => row.utm_source,
      sortable: true,
    },
    {
      name: 'UTM Id',
      selector: row => row.utm_id,
      sortable: true,
    },
    {
      name: 'IP',
      selector: row => row.user_ip,
      sortable: true,
    },
    {
      name: 'ref_url',
      selector: row => row.ref_url,
      sortable: true,
    }

  ];


  const VerifyGuestUser = (guest_id, fullName, mobileNumber, emailAddress, password, created_by) => {

    SweetAlert.fire({
      title: "Verify User",
      text: "Are you sure to verify this user as profile ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {



          const OtpEmail = {
            "full_name": fullName,
            "mobile": mobileNumber,
            "email": emailAddress,
            "otp_send_on": 'email',
            "password": password,
            'guestId': guest_id,
            'notification': 'no'
          };

          axioInstance.post(`send-otp`, OtpEmail).then(
            function (response, data) {

              const OtpMobile = {
                "full_name": fullName,
                "email": emailAddress,
                "mobile": mobileNumber,
                "otp_send_on": 'mobile',
                'notification': 'no'
              };

              axioInstance.post(`send-otp`, OtpMobile).then(
                function (response, data) {

                  // normal flow start here to register

                  const Postdata = {
                    "name": fullName,
                    "mobile": mobileNumber,
                    "email": emailAddress,
                    "password": password,
                    "register": 1,
                    "registerVia": 1,
                    "rel_manager": created_by
                  };

                  const search = window.location.search;
                  const params = new URLSearchParams(search);


                  axioInstance.post(`register`, Postdata)
                    .then(function (response) {

                      sessionStorage.setItem('token', response.data.token);

                      axioInstance.post(`otp-verify-admin`, { email: emailAddress, admin_id: created_by, 'guest_id': guest_id });
                      axioInstance.post(`otp-verify-admin-mobile`, { mobile: mobileNumber, admin_id: created_by, 'guest_id': guest_id });

                      axioInstance.post(`profile/add`, Postdata).then(function (response) {

                        const registered_profile_id = response.data.profile_id;

                        sessionStorage.setItem('profile_id', response.data.profile_id);
                        sessionStorage.setItem('profile_det_id', response.data.profile_det_id.profile_details_id);
                        /*axioInstance.post(`default-risk-profile`, { profile_id: response.data.profile_id }).then(function (response) {
                        });

                        axioInstance.post(`profile/summary-add`, { profile_id: response.data.profile_id }).then(function (response) {
                        });*/

                        // eslint-disable-next-line
                        let fullname = fullName;
                        var names = fullname.split(' ');

                        if (window.location.hostname === "plan.finnovate.in") {

                          const relationship_manager = params.get('ref_by') !== '' ? params.get('ref_by') : (params.get('ref_by') !== null ? params.get('ref_by') : 'FINNOVATE');

                          const utm_source = params.get('utm_source') ? params.get('utm_source') : 'FinnPlan';
                          const utm_campaign = params.get('utm_campaign') ? params.get('utm_campaign') : 'FinnPlan';
                          const utm_id = params.get('utm_id') ? params.get('utm_id') : '9423';

                          const apiData = JSON.stringify({
                            "campaign_id": utm_id,
                            "campaign_type": 'TeleSales',
                            "check_condication_1": '0',
                            "check_condication_2": '0',
                            "ignore_duplicate": '1',
                            "ignore_open_task": '1',
                            "check_condication_task": '0',
                            "consider_same_account": '0',
                            "match_col": 'email_and_mobile',
                            "lead_source": utm_source,
                            "lead_caimpaign": utm_campaign,
                            "relationship_manager": relationship_manager,
                            "profile_details_id": response.data.profile_det_id.profile_details_id,
                            "tele_caller": '1000049',
                            "ref_url": window.location.href,
                            "import_data": {
                              0: {
                                "f_name": names[0] ? names[0] : '',
                                "m_name": '',
                                "l_name": names[1] ? names[1] : '',
                                "name": fullName,
                                "mobile": mobileNumber,
                                "email": emailAddress
                              }
                            }
                          });





                          Promise.all([
                            axioInstance.post(`default-risk-profile`, { profile_id: registered_profile_id }),
                            axioInstance.post(`profile/summary-add`, { profile_id: registered_profile_id })
                          ]).then(() => {

                            axios.post(`https://lmslive.finnovate.in/import/import-everything-api.php`, apiData).then(function (response) {

                              axioInstance.post(`profile/add-zoho-crm-profile`, { profile_id: registered_profile_id }).then(function (response) {

                                // eslint-disable-next-line
                                axioInstance.post(`get-plan-steps`, { profile_id: response.data.profile_id }).then(
                                  (response, data) => {
                                    if (response.data.status === 100) {

                                      SweetAlert.fire({
                                        toast: true,
                                        icon: 'success',
                                        title: 'Guest User is Verified as Profile',
                                        animation: false,
                                        position: 'top-right',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                                        }
                                      }).then(() => {
                                        window.location.href = "admin-dashboard";
                                      })

                                    }
                                  }
                                );

                              });

                            });


                          });



                        } else {
                          Promise.all([
                            axioInstance.post(`default-risk-profile`, { profile_id: registered_profile_id }),
                            axioInstance.post(`profile/summary-add`, { profile_id: registered_profile_id })
                          ]).then(() => {
                            window.location.reload();
                          });
                        }

                      });

                    })
                    .catch(e => {

                      if (e.response.data.data.hasOwnProperty("password")) {
                        SweetAlert.fire({
                          toast: true,
                          icon: 'error',
                          title: e.response.data.data.password[0],
                          animation: false,
                          position: 'top-right',
                          showConfirmButton: false,
                          timer: 3000,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                          }
                        });
                      } else {

                        SweetAlert.fire({
                          toast: true,
                          icon: 'error',
                          title: 'Something went wrong. try again',
                          animation: false,
                          position: 'top-right',
                          showConfirmButton: false,
                          timer: 3000,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                          }
                        });

                      }
                    });

                }
              )
            }
          )
        }
      });

  }

  const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });
    setTableRowsData(searchData);
  };




  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

  const addProfile = () => {

    console.log('hostname', window.location);

    const url = window.location.origin + "/sign-in?q=2&ref_by=" + sessionStorage.getItem('shortName') + "&s=" + sessionStorage.getItem('admin_id');

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

  }


  const planLogsRedirect = () => {
    window.location.href = 'plan-login-logs-dashboard';
    return;
  }

  const planEventsRedirect = () => {
    window.location.href = 'profile-event-logs-dashboard';
    return;
  }

  const AdminDashboardRedirect = () => {
    window.location.href = 'admin-dashboard';
    return;
  }

  return (
    <Fragment>
      <AdminDashboardHeader />



      <div className="innercontainer isPlanProfilesTimelinePage mb40">
        <div className="mainwrapper">
          <div className='form_title'><div className="mt30 font21 fw600 color384">Guest Users Dashboard</div></div>

          <div className='row mt25'>



            <div className='col-md-3'>
              <div className="font14 color212 fw600">Search</div>
              <input
                type="text"
                placeholder="Name Search"
                value={title}
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
                className="form-control"
              />

            </div>


          </div>

          <div class="justify-content-left row mt35">

            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => AdminDashboardRedirect()}>Admin Dashboard</button>
            </div>

            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={addProfile}>Add Profile</button>
            </div>
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => { setActive(true); setAction('addTestProfile') }}> Add Testing Profile </button>
            </div>
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => planLogsRedirect()}>Plan Login Logs</button>
            </div>





            {
              sessionStorage.getItem('admin_id') === '1' && (
                <>
                  <div class="col-lg-2 col-md-2">
                    <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => planEventsRedirect()}>Plan Events Logs</button>
                  </div>
                  <div class="col-lg-2 col-md-2">
                    <Dropdown className="">
                      <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots dropdown-toggle btn btn-success">
                        Plan Reports
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href='plan-profiles-timeline'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Profile Timeline</Dropdown.Item>
                        <Dropdown.Item href='plan-profiles-status'><i className="fas fa-users text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Profile Plan Status</Dropdown.Item>
                        <Dropdown.Item href='plan-summary-report-drilldown-month'><i className="fas fa-bar-chart text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Summary Month Wise</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>


                </>
              )
            }
            <div class="col-lg-2 col-md-2 mt35">

            </div>
          </div>


          <div className="">
            <div className="admin_dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableRowsData}
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      }

    </Fragment>
  );
}