import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';

export default function EditEpfById({ investment, i }) {

    console.log('EditInvestment ', investment);

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    // eslint-disable-next-line
    const [maxZero, setMaxZero] = useState(3);
    // eslint-disable-next-line
    const [maxOne, setMaxOne] = useState(3);
    // eslint-disable-next-line
    const [maxTwo, setMaxTwo] = useState(3);
    // eslint-disable-next-line
    const [maxThree, setMaxThree] = useState(3);
    // eslint-disable-next-line
    const [maxFour, setMaxFour] = useState(3);
    // eslint-disable-next-line
    const [maxFive, setMaxFive] = useState(3);
    // eslint-disable-next-line
    const [maxSix, setMaxSix] = useState(3);
    // eslint-disable-next-line
    const [maxSeven, setMaxSeven] = useState(3);
    // eslint-disable-next-line
    const [maxEight, setMaxEight] = useState(3);


    const setMaxNumber = (value, index) => {

        if (value.indexOf('.') !== -1) {

            switch (index) {
                case 0:
                    setMaxZero(5);
                    break;

                case 1:
                    setMaxOne(5);
                    break;

                case 2:
                    setMaxTwo(5);
                    break;

                case 3:
                    setMaxThree(5);
                    break;

                case 4:
                    setMaxFour(5);
                    break;

                case 5:
                    setMaxFive(5);
                    break;

                case 6:
                    setMaxSix(5);
                    break;

                case 7:
                    setMaxSeven(5);
                    break;

                case 8:
                    setMaxEight(5);
                    break;

                default:
            }

        } else {

            switch (index) {
                case 0:
                    setMaxZero(3);
                    break;

                case 1:
                    setMaxOne(3);
                    break;

                case 2:
                    setMaxTwo(3);
                    break;

                case 3:
                    setMaxThree(3);
                    break;

                case 4:
                    setMaxFour(3);
                    break;

                case 5:
                    setMaxFive(3);
                    break;

                case 6:
                    setMaxSix(3);
                    break;

                case 7:
                    setMaxSeven(3);
                    break;

                case 8:
                    setMaxEight(3);
                    break;

                default:
            }

        }
    }

    // Function to calculate the formatted investment amount
    const calculateFormattedInvestment = (amount, frequency) => {
        // Calculate inv_amount based on amount and frequency
        const inv_amount = (amount !== null && frequency !== null) ? amount / frequency : amount ? amount : 0;

        if (inv_amount === 0) {
            // If inv_amount is 0, return 0
            return '0';
        } else {
            const numDigits = inv_amount.toString().length;
            // Determine the divisor based on the number of digits
            let divisor;
            if (numDigits >= 8) {
                divisor = 10000000;
            } else if (numDigits >= 6 && numDigits <= 7) {
                divisor = 100000;
            } else {
                divisor = 1000;
            }
            // Calculate the final formatted amount
            const formattedAmount = inv_amount / divisor;

            return formattedAmount;
        }
    };

    /*

    sub_type: 1
investor_name: Z0xxTkVHcGVwNUJZcG1uK3dhd0NSZz09
inv_till_date: 1,00,000
frequency: 1
your_cont: 5,000
emp_cont: 5000
value_date: 10 May 2023
value_total: 5,00,000
portfolio: Y
profInvestmentName: provident-fund-form
check: K0c0OTZMNXFpUEpOME9Fd0lFaXNmUT09
action: form

    */
    const onSubmit = data => {

        const Postdata = {
            "prop_id": data.profileId,
            "profile_id": data.profileId,
            "profile_details_id": data.profile_details_id,
            "type": data.type,
            "owner": data.profileId,
            "invest_type": 0,
            "sub_type": data.sub_type,
            "instr_name": data.instr_name,
            "frequency":1,
            "current_value": data.currentVal * data.moneyDigits,
            "cost_amount": data.currentVal * data.moneyDigits,
            "amount": data.buyVal * data.moneyDigitsBuyValue,
            "invest_amount": data.buyVal * data.moneyDigitsBuyValue,
            "other_amount":data.EmpVal * data.moneyDigitsEmp,
            "tenure": 1,
            "invest_id": data.invest_id,
            "moneyDigits": data.moneyDigits,
            "value_total": data.currentVal * data.moneyDigits,
            "linked": data.linked,
            "admin_id":sessionStorage.getItem("admin_id")
        };

        axioInstance.post(`investment/add-investment`, Postdata)
            .then(function (response) {
                window.location.reload(true)
            })

    }



    return (
        <>
            <Fragment>
                <div className="card card-body">
                    <div className="form_prnt">

                        <div className="expandtab font12 color353 fw500">{investment.asset_type + " - " + investment.type_name}</div>


                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <input type="hidden" name="type" {...register("type")} defaultValue={investment.type} />
                            <input type="hidden" name="sub_type" {...register("sub_type")} defaultValue={investment.sub_type} />
                            <input type="hidden" name="instr_name" {...register("instr_name")} defaultValue={investment.instr_name} />
                            <input type="hidden" name="invest_id" {...register("invest_id")} defaultValue={investment.invest_id} />
                            <input type="hidden" name="linked" {...register("linked")} defaultValue={investment.linked} />
                            <input type="hidden" name="frequency" {...register("frequency")} defaultValue={investment.frequency} />
                            <input type="hidden" name="profileId" {...register("profileId")} defaultValue={investment.profile_id !== null ? investment.profile_id : sessionStorage.getItem("profile_id")} />
                            <input type="hidden" name="profile_details_id" {...register("profile_details_id")} defaultValue={investment.profile_details_id !== null ? investment.profile_details_id : sessionStorage.getItem('profile_details_id')} />


                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Your Current Contribution</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="buyVal" {...register("buyVal", { required: 'Required.' })} defaultValue={calculateFormattedInvestment(investment.amount, investment.frequency)}
                                            onChange={(e) => { setMaxNumber(e.target.value, i); }}
                                            maxLength={
                                                i === 0 ? maxZero : i === 1 ? maxOne : i === 2 ? maxTwo : i === 3 ? maxThree : i === 4 ? maxFour : i === 5 ? maxFive : i === 6 ? maxSix : i === 7 ? maxSeven : i === 8 ? maxEight : maxZero
                                            }
                                            minLength={1} />

                                        <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigitsBuyValue' {...register("moneyDigitsBuyValue")} defaultValue={investment.periodic.toString().length >= 8 ? 10000000 : (investment.periodic.toString().length >= 6 && investment.periodic.toString().length < 8) ? 100000 : (investment.periodic.toString().length >= 3 && investment.periodic.toString().length < 6) ? 1000 : 100000}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                            <option value="1000">Thous.</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.buyVal && errors.buyVal.message}</span>
                                </div>
                            </div>


                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Employers Current Contribution</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="EmpVal" {...register("EmpVal", { required: 'Required.' })} defaultValue={(investment && investment.other_amount > 0) ? investment.other_amount.toString().length >= 8 ? investment.other_amount / 10000000 : investment.other_amount / 100000 : 0} maxLength={3} minLength={1} />
                                        <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigitsEmp' {...register("moneyDigitsEmp")} defaultValue={(investment && investment.other_amount > 0) ? investment.other_amount.toString().length >= 8 ? 10000000 : 100000 : 100000}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                            <option value="1000">Thous.</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.EmpVal && errors.EmpVal.message}</span>
                                </div>
                            </div>


                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label for="inputEmail4" className="font14 fw500">Current Total Value</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="currentVal" {...register("currentVal", { required: 'Required.' })} defaultValue={(investment && investment.current_value > 0) ? investment.current_value.toString().length >= 8 ? investment.current_value / 10000000 : investment.current_value / 100000 : 0} maxLength={3} minLength={1} />
                                        <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")} defaultValue={investment.periodic.toString().length >= 8 ? 10000000 : (investment.periodic.toString().length >= 6 && investment.periodic.toString().length < 8) ? 100000 : (investment.periodic.toString().length >= 3 && investment.periodic.toString().length < 6) ? 1000 : 100000}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                            <option value="1000">Thous.</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.currentVal && errors.currentVal.message}</span>
                                </div>
                            </div>


                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary">Cancel </button>
                                <button type="submit" className="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
                            </div>

                        </form>
                    </div>
                </div>
            </Fragment>
        </>
    )
}
