import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import PlanSummaryChart from '../components/PlanSummaryChart';
import { Dropdown } from 'react-bootstrap'

export default function ReportPlanSummaryDrilldownRelManager() {

  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [expandedMonths, setExpandedMonths] = useState([]);
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [rawData, setRawData] = useState([]);

  const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });

  };

  const relmanagerFilter = async (e) => {
    // eslint-disable-next-line
    var searchData = data.filter((item) => {

      if (
        parseInt(item.admin_id) === parseInt(e.target.value)
      ) {
        return item;
      }


    });

  }

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {

      axioInstance.post(`admin/get-plan-summary-month-wise`, {report:'2', 'rel_manager': sessionStorage.getItem('admin_id') }).then(
        (response, data) => {
          //console.log(response.data);
          const transformedData = transformData(response.data.rows);
          setData(transformedData);
          setRawData(response.data);
        }
      );

    }
    // eslint-disable-next-line
  }, [navigate]);


  const transformData = (data) => {
    const result = [];
    for (const month in data) {
      const monthData = {
        month,
        weeks: [],
        new_created: 0,
        plan_completed: 0,
        plan_shared: 0,
        freezed: 0,
        exection_done: 0
      };
      for (const week in data[month]) {
        const weekData = {
          week,
          managers: [],
          new_created: 0,
          plan_completed: 0,
          plan_shared: 0,
          freezed: 0,
          exection_done: 0
        };
        for (const manager in data[month][week]) {
          const managerData = {
            manager,
            ...aggregateManagerData(data[month][week][manager])
          };
          weekData.managers.push(managerData);
          weekData.new_created += managerData.new_created;
          weekData.plan_completed += managerData.plan_completed;
          weekData.plan_shared += managerData.plan_shared;
          weekData.freezed += managerData.freezed;
          weekData.exection_done += managerData.exection_done;
        }
        monthData.weeks.push(weekData);
        monthData.new_created += weekData.new_created;
        monthData.plan_completed += weekData.plan_completed;
        monthData.plan_shared += weekData.plan_shared;
        monthData.freezed += weekData.freezed;
        monthData.exection_done += weekData.exection_done;
      }
      result.push(monthData);
    }
    return result;
  };

  const aggregateManagerData = (managerData) => {
    return managerData.reduce((acc, item) => ({
      new_created: acc.new_created + item.new_created,
      plan_completed: acc.plan_completed + item.plan_completed,
      plan_shared: acc.plan_shared + item.plan_shared,
      freezed: acc.freezed + item.freezed,
      exection_done: acc.exection_done + item.exection_done
    }), { new_created: 0, plan_completed: 0, plan_shared: 0, freezed: 0, exection_done: 0 });
  };

  const columns = [
    {
      name: 'Month',
      cell: row => (
        <div onClick={() => toggleMonth(row.month)} style={{ cursor: 'pointer' }}>
          {expandedMonths.includes(row.month) ? '▼' : '▶'} {row.month}
        </div>
      ),
      sortable: true
    },
    { name: 'New Created', selector: row => row.new_created, sortable: true },
    { name: 'Plan Completed', selector: row => row.plan_completed, sortable: true },
    { name: 'Plan Shared', selector: row => row.plan_shared, sortable: true },
    { name: 'Freezed', selector: row => row.freezed, sortable: true },
    { name: 'Execution Done', selector: row => row.exection_done, sortable: true }
  ];


  const managerColumns = [
    { name: 'Week', selector: manager => manager.manager, sortable: true },
    { name: 'New Created', selector: manager => manager.new_created, sortable: true },
    { name: 'Plan Completed', selector: manager => manager.plan_completed, sortable: true },
    { name: 'Plan Shared', selector: manager => manager.plan_shared, sortable: true },
    { name: 'Freezed', selector: manager => manager.freezed, sortable: true },
    { name: 'Execution Done', selector: manager => manager.exection_done, sortable: true }
  ];

  const toggleMonth = (month) => {
    setExpandedMonths(prevState => (
      prevState.includes(month) ? prevState.filter(m => m !== month) : [...prevState, month]
    ));
  };

  const toggleWeek = (month, week) => {
    const key = `${month}-${week}`;
    setExpandedWeeks(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const renderExpandedRow = (row) => (
    <div>
      {row.weeks.map(week => (
        <div key={week.week} style={{ paddingLeft: '20px' }}>
          <div onClick={() => toggleWeek(row.month, week.week)} style={{ cursor: 'pointer' }}>
            {expandedWeeks[`${row.month}-${week.week}`] ? '▼' : '▶'} RM {week.week}
          </div>
          {expandedWeeks[`${row.month}-${week.week}`] && (
            <DataTable
              columns={managerColumns}
              data={week.managers}
              noHeader
              dense
            />
          )}
        </div>
      ))}
    </div>
  );


  return (
    <Fragment>
      <AdminDashboardHeader />

      <div className="innercontainer isPlanProfilesTimelinePage mb40">
        <div className="mainwrapper">
          <div className='form_title'><div className="mt30 font21 fw600 color384">Plan Summary Report</div></div>

          <div className='row mt25'>



            <div className='col-md-3'>
              <div className="font14 color212 fw600">Search</div>
              <input
                type="text"
                placeholder="Name Search"
                value={title}
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
                className="form-control"
              />

            </div>


            <div className='col-md-3'>
              <div className="font14 color212 fw600">Rel Manager Filter</div>
              <select className="custom-select font14 color212 fw500" name='relManager' onChange={(e) => relmanagerFilter(e)}>
                <option value="">Select Rel Manager</option>

              </select>

            </div>

            <div className='col-md-3'>
              <Dropdown className="mt-3">
                <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots">
                  Plan Reports
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='admin-dashboard'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Admin Dashboard</Dropdown.Item>
                  <Dropdown.Item href='plan-profiles-timeline'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Profile Timeline</Dropdown.Item>
                  <Dropdown.Item href='plan-profiles-status'><i className="fas fa-users text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Profile Plan Status</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>


          </div>

          <div className="button-group mt-3">
            <a href="plan-summary-report-drilldown-month" className="btn btn-outline-primary ml5 mt50">Month Wise <i className="fas fa-arrow-right font8"></i> Weekly <i className="fas fa-arrow-right font8"></i> Rel Manager</a>
            <a href="plan-summary-report-drilldown-month-relmanagerwise" className="btn btn-primary ml5 mt50">Month Wise <i className="fas fa-arrow-right font8"></i> Rel Manager <i className="fas fa-arrow-right font8"></i> Weekly</a>
            <a href="plan-summary-report-drilldown-relmanager-monthwise" className="btn btn-outline-primary ml5 mt50">Rel Manager <i className="fas fa-arrow-right font8"></i> Month Wise <i className="fas fa-arrow-right font8"></i> Weekly</a>
          </div>

          <div className="">
            <div className="dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">

                      <div>
                        <PlanSummaryChart rawData={rawData}/>
                      </div>

                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={data}
                          expandableRows
                          expandableRowsComponent={({ data }) => renderExpandedRow(data)}
                          onRowExpandToggled={(expanded, row) => toggleMonth(row.month)}
                          expandableRowExpanded={row => expandedMonths.includes(row.month)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
}