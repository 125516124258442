import React, { useEffect, useState } from 'react'
import { inWords } from '../../global';
import axioInstance from '../../axiosInstance';
//import EditInvestmentById from './EditInvestmentById';
import EditInvestmentAll from './EditInvestmentAll';
import EditPropertiesById from './EditPropertiesById';
import EditEpfById from './EditEpfById';
import SweetAlert from 'sweetalert2'


export default function EditInvestment({ toggleClass, setActive, assetClass, heading, subheading }) {

    console.log("on click assetClass is " + assetClass);

    const Back = () => {
        setActive(false);
    }

    const name = sessionStorage.getItem("full_name");
    const profile_id = sessionStorage.getItem("profile_id");
    const profile_details_id = sessionStorage.getItem("profile_details_id");

    // eslint-disable-next-line 
    const [investMaster, setInvestMaster] = useState([{
        "id": 1,
        "asset_type": "Mutual Fund",
        "type": 3,
        "sub_type": 1,
        "instr_name": 'self MF',
        "full_name": name,
        "current_value": '',
        "amount": 0,
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id
    }, {
        "id": 2,
        "asset_type": "Fix Deposits",
        "type": 2,
        "sub_type": 1,
        "instr_name": 'Deposits',
        "full_name": name,
        "current_value": '',
        "amount": '',
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id,
        "tenure": 1,
        "month_year": "Y"
    }, {
        "id": 3,
        "asset_type": "Stocks",
        "type": 1,
        "sub_type": 1,
        "instr_name": 'Stock',
        "full_name": name,
        "current_value": '',
        "amount": '',
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id
    }, {
        "id": 4,
        "asset_type": "Gold",
        "type": 6,
        "sub_type": 2,
        "instr_name": 'Gold',
        "full_name": name,
        "current_value": '',
        "amount": '',
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id
    },
    {
        "id": 5,
        "asset_type": "PPF/EPF",
        "type": 5,
        "sub_type": 3,
        "instr_name": 'PPF/EPF',
        "full_name": name,
        "current_value": '',
        "amount": '',
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id
    },
    {
        "id": 6,
        "asset_type": "NPS Fund",
        "type": 11,
        "sub_type": 1,
        "instr_name": 'NPS',
        "full_name": name,
        "current_value": '',
        "amount": '',
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id,
    },
    {
        "id": 7,
        "asset_type": "Residential",
        "type": 7,
        "sub_type": 2,
        "instr_name": 'Primary',
        "full_name": name,
        "current_value": '',
        "amount": '',
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id,
    },
    {
        "id": 8,
        "asset_type": "Land",
        "type": 7,
        "sub_type": 2,
        "instr_name": 'Land',
        "full_name": name,
        "current_value": '',
        "amount": '',
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id,
    },
    {
        "id": 9,
        "asset_type": "Commercial",
        "type": 7,
        "sub_type": 1,
        "instr_name": 'Secondary',
        "full_name": name,
        "current_value": '',
        "amount": '',
        "frequency": 12,
        "moneyDigits": 100000,
        "profile_id": profile_id,
        "profile_details_id": profile_details_id,
    }]);



    const [investArr, setInvestArr] = useState([]);

    // eslint-disable-next-line
    const [addInvestArr, setAddInvestArr] = useState([]);

    const [isFirstCall, setIsFirstCall] = useState(true);

    useEffect(() => {

        if (!isFirstCall) return; // Stop further calls if the first call has already been made

        console.log("First call on mount with assetClass:", assetClass);

        //const typeArr = assetClass === "equity" ? [1, 2, 3, 5, 7, 11] : (assetClass === "debt" ? [1, 2, 3, 5, 7, 11] : [1, 2, 3, 5, 7, 11]);

        const typeArr = assetClass === "equity" ? [1] : (assetClass === "debt" ? [2] : (assetClass === "epf" ? [5] : [3, 4]));

        const profile_id = sessionStorage.getItem('profile_id')
        axioInstance.post(`investment/get-investment`, { profile_id: profile_id, type: typeArr }).then((response, data) => {

            console.log(response.data.length);
            if (response.data.length > 0) {

                // if you want to be more clever...
                //let result = investMaster.filter(o1 => !response.data.some(o2 => o1.type === o2.type));
                //console.log('result ', result)
                setInvestArr(response.data);
                //setAddInvestArr(result);
            } else {
                setInvestArr([]);
            }

        });

        setIsFirstCall(false); // Mark the first call as completed

        return () => console.log("Cleanup with assetClass:", assetClass);
    }, [assetClass, isFirstCall]);


    const MapAsset = (id, linked) => {

        SweetAlert.fire({
            title: "Are you sure?",
            text: linked === "1" ? "you want to Link this Asset?" : "you want to unLink this Asset?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#09a635",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            closeOnConfirm: false,
            closeOnCancel: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axioInstance.post(`investment/map`, { profile_id: sessionStorage.getItem('profile_id'), 'invest_id': id, linked: linked }).then(
                    function (response) {


                        window.location.reload();
                    }
                )
            }
        })

    }

    const [activeTab, setActiveTab] = useState('');

    // eslint-disable-next-line
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="rightSide_menu_outer">
            <div className="rightSide_menu">
                <div className="rightSide_menu_header">
                    <div>
                        <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                    </div>
                    <div className="font14 fw500 color182">
                        Edit {heading}
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div className="topBanner pl25">
                    <div className="font18 fw600 color182 mt50">{heading}</div>
                    <div className="font14 fw500 color485">{sessionStorage.getItem('full_name')} family’s {subheading}</div>
                </div>
                <div className="pl20 pr20">

                    {

                        investArr.length > 0 && (
                            <>
                                {
                                    investArr.map((investment, i) => {

                                        return (
                                            <>

                                                <div className={(activeTab === i && expanded === true) ? "collapse_outer mt20 activeshow" : expanded === false ? "collapse_outer mt20" : "collapse_outer mt20 activehide"}>
                                                    <div className="collapse_title">
                                                        <div className="font16 fw600 color263">{expanded === false ? <>{investment.asset_type}</> : ''}</div>
                                                        <div>
                                                            <button className="btn btn-outline-primary font12 fw500 expandBtn" type="button" data-toggle="collapse" data-target={"#collapseExample" + i} aria-expanded="false" aria-controls="collapseExample" data-collapse={"#article" + i} onClick={() => { setActiveTab(i); setExpanded((prevExpanded) => (prevExpanded = !prevExpanded)); }}>
                                                                + Expand
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="expandtab_outer mt12">

                                                        {
                                                            (activeTab === i && expanded === true) ? '' : (<><div className="expandtab font12 color353 fw500">{investment.asset_type === "Property" ? investment.instr_name + ' ' + investment.type_name : (investment.full_name !== "" ? investment.full_name : name)} <b>{inWords(investment.current_value)}</b></div></>)
                                                        }


                                                        {
                                                            (investment.type === 7 && investment.sub_type === 1) ? (<>



                                                            </>) :
                                                                (
                                                                    <>

                                                                        {
                                                                            assetClass === "other" ? (<>{(investment.linked === 1 && assetClass === "other") ? <span onClick={() => MapAsset(investment.invest_id, '0')}><i className="fas fa-unlink"></i></span> : <span onClick={() => MapAsset(investment.invest_id, '1')}><i className="fas fa-link"></i></span>}</>) : ''


                                                                        }
                                                                    </>
                                                                )


                                                        }

                                                    </div>
                                                    <div class={"collapse"} id={"collapseExample" + i}>
                                                        {
                                                            expanded === true && (
                                                                <>
                                                                    {
                                                                        investment.asset_type === "Property" ? <EditPropertiesById investment={investment} /> : investment.type_name === "EPF" ? <EditEpfById investment={investment} /> : <EditInvestmentAll investment={investment} setActive={setActive} assetClassMain={investment.asset_class} />
                                                                    }
                                                                </>
                                                            )

                                                        }

                                                    </div>
                                                </div>



                                            </>
                                        )
                                    })
                                }
                            </>
                        )
                    }


                    {

                        addInvestArr.length > 0 && (
                            <>
                                {
                                    addInvestArr.map((investment, i) => {

                                        return (
                                            <>
                                                <div className="collapse_outer mt20">
                                                    <div className="collapse_title">
                                                        <div className="font16 fw600 color263">{investment.asset_type}</div>
                                                        <div>
                                                            <button className="btn btn-outline-primary font12 fw500 expandBtn" type="button" data-toggle="collapse" data-target={"#AddcollapseExample" + i} aria-expanded="false" aria-controls="AddcollapseExample">
                                                                + Expand
                                                            </button>

                                                            {investment.linked === 1 ? <span onClick={() => MapAsset(investment.invest_id, '0')}><i className="fas fa-unlink"></i></span> : <span onClick={() => MapAsset(investment.invest_id, '1')}><i className="fas fa-link"></i></span>}
                                                        </div>
                                                    </div>
                                                    <div className="expandtab_outer mt12">
                                                        <div className="expandtab font12 color353 fw500">{investment.full_name} <b>{inWords(investment.current_value)}</b></div>
                                                    </div>
                                                    <div className="collapse" id={"AddcollapseExample" + i}>
                                                        {
                                                            investment.asset_type === "Property" ? <EditPropertiesById investment={investment} /> : <EditInvestmentAll investment={investment} setActive={setActive} assetClass={investment.asset_type} onClick={() => setActiveTab(i)} />
                                                        }

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </>
                        )
                    }

                    {
                        expanded ? (<></>) : (<div className="saveBtn backsaveBtn text-center">
                            <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                        </div>)
                    }


                </div>

            </div>
        </div>
    )
}