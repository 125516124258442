import React, { useState, useEffect, Fragment } from 'react';
import axioInstance from './axiosInstance';
//import SweetAlert from 'sweetalert2';

const friendsArray = [
    {
        "ques_id": 152,
        "key": "gender",
        "ans_id": null
    },
    {
        "ques_id": 153,
        "key": "age",
        "ans_id": null
    },
    {
        "ques_id": 154,
        "key": "occupation",
        "ans_id": null
    },
    {
        "ques_id": 155,
        "key": "IncomeSelf",
        "ans_id": null
    },
    {
        "ques_id": 156,
        "key": "IncomeSpouse",
        "ans_id": null
    },
    {
        "ques_id": 157,
        "key": "expense_perc",
        "ans_id": null
    },
    {
        "ques_id": 158,
        "key": "emi_perc",
        "ans_id": null
    },
    {
        "ques_id": 159,
        "key": "dependents",
        "ans_id": null
    },
    {
        "ques_id": 160,
        "key": "exp_inv_equity_mf",
        "ans_id": null
    },
    {
        "ques_id": 161,
        "key": "illustrative_portfolio",
        "ans_id": null
    },
    {
        "ques_id": 162,
        "key": "worry",
        "ans_id": null
    },
    {
        "ques_id": 163,
        "key": "financial_decission",
        "ans_id": null
    },
    {
        "ques_id": 164,
        "key": "equity_perc",
        "ans_id": null
    },
    {
        "ques_id": 165,
        "key": "acco_status",
        "ans_id": null
    },
    {
        "ques_id": 166,
        "key": "invest_knowledge",
        "ans_id": null
    }
];

export default function RiskProfile() {
    const [riskq, setriskq] = useState([])
    const [steps, setSteps] = useState(0);

    // eslint-disable-next-line
    const [friends, setFriends] = useState(friendsArray); // Setting default value

    const StepChange = (step) => {
        setSteps(step);
    }

    const previousButton = () => {
        if (steps === 0) return;
        StepChange(steps - 1);
    }


    const stepNext = (e) => {

        
        let ques_id = e.target.getAttribute("ques_id");
        setFriends(
            friends.map((friend) =>
                friend.ques_id === parseInt(ques_id)
                    ? { ...friend, ans_id: e.target.value }
                    : { ...friend }
            )
        );
            console.log(steps, 'steps');
            setSteps(steps + 1);

    }

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');

        const PostData = { weight: 1, set_id: 3, goaltype_id: 7, profile_id:profile_id }
        axioInstance.post(`risk-question`, PostData).then(
            (response, data) => {
                setriskq(response.data.response_data.question);
                sessionStorage.setItem('questions', JSON.stringify(response.data.response_data.question));
            }
        );
    }, []);

    return (
        <Fragment>




            <ul className="risk_tag font14 fw500 color182 mt15">

                {riskq.map((item, index) => {
                    return (
                        <>

                            <div className={steps === index ? 'show' : 'hide'} >

                                <div className="color212 font14 fw600 mb25">{item.label} <span className="colorfa8">*</span></div>

                                {item.answers.map((company, index) => {
                                    return (
                                        <>
                                            <li>
                                                <label>
                                                    <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onClick={stepNext} />
                                                    <span className="rdo_text_box">{company.name}</span>
                                                </label>
                                            </li>
                                        </>
                                    );
                                }
                                )}
                            </div>


                        </>)
                })}
            </ul>

            <div className="bottombtn mt-7">
                <button className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={previousButton}><i className="fas fa-angle-left" /></button>
            </div>

        </Fragment>
    );
}
