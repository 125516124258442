import React from 'react'
import '../css/percentageloanform.css';

export default function PercentageLoanform(){
    return(
        <> 
            <div className="p25">
                <div>
                    <div className="color212 font15 fw600">Own a house</div>
                    <div className="form_prnt mt20">
                        <form className="custome_form">
                            <div className="form-row justify-content-between">
                                <div className="col-md-4 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Sum assured</label>
                                    <div className="input-group selectarrow select50">
                                        <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Lakhs</option>
                                            <option value="1">1 Lakh</option>
                                            <option value="2">2 Lakh</option>
                                            <option value="3">3 Lakh</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <div className="col-md-4 pl20">
                                    <div className="font14 color212 fw600">Achieving year</div>
                                    <div className="mt8 quantity_wpr">
                                        <button>-</button>
                                        <input type="text" className="font14 fw500 ml15 mr15" value="00" />
                                        <button>+</button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="font14 color212 fw600">Percentage of loan</div>
                                    <div className="rangeslider_wpr mt8">
                                        <div className="rangeslider_left">
                                        <form>
                                            <div className="form-group">
                                                <input type="range" className="form-control-range" id="formControlRange" />
                                            </div>
                                            </form>
                                        </div>
                                        <div className="rangeslider_right">
                                            <input type="text" className="font14 fw500 ml15 mr15" value="0%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </form>
                    </div>
                </div>

                <div className="formdevider mt35 mb35" />
                
                <div>
                    <div className="flex_center justify_center">
                        <div className="entergoalname_wpr">
                            <div className="entergoalname">
                                <form className="custome_form searchinput">
                                    <div className="form-row">
                                        <div className="form-group m0">
                                            <input type="email" className="form-control font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter goal name" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="selectgoal">
                            <label htmlFor="inputEmail4" className="font14 fw500 color6d7 m0">Goal focused on</label>
                            <div className="input-group selectarrow ml10">
                                <select className="custom-select font14 color212 fw500" id="inputGroupSelect01">
                                    <option selected>Goal focused</option>
                                    <option value="1">Naveen</option>
                                    <option value="2">Anoj</option>
                                    <option value="3">Rais</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="form_prnt mt20">
                        <form className="custome_form">
                            <div className="form-row justify-content-between">
                                <div className="col-md-4 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Cost of the goal</label>
                                    <div className="input-group selectarrow select50">
                                        <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Lakhs</option>
                                            <option value="1">1 Lakh</option>
                                            <option value="2">2 Lakh</option>
                                            <option value="3">3 Lakh</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <div className="col-md-4 pl20">
                                    <div className="font14 color212 fw600">Achieving year</div>
                                    <div className="mt8 quantity_wpr">
                                        <button>-</button>
                                        <input type="text" className="font14 fw500 ml15 mr15" value="2034" />
                                        <button>+</button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="font14 color212 fw600">Percentage of loan</div>
                                    <div className="rangeslider_wpr mt8">
                                        <div className="rangeslider_left">
                                        <form>
                                            <div className="form-group">
                                                <input type="range" className="form-control-range" id="formControlRange" />
                                            </div>
                                            </form>
                                        </div>
                                        <div className="rangeslider_right">
                                            <input type="text" className="font14 fw500 ml15 mr15" value="0%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </form>
                    </div>
                </div>
                
                <div className="formdevider mt35 mb35" />

                <div>
                    <div className="color212 font16"><b>Add a new goal</b> (select one of the lifestyle goals)
                    </div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                            <li>
                                <label>
                                    <input type="radio" Name="00" />
                                    <span className="rdo_text_box">
                                        Big vacation
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="00" />
                                    <span className="rdo_text_box">
                                        Big wedding
                                    </span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" Name="00" />
                                    <span className="rdo_text_box">
                                        Add custom goal
                                    </span>
                                </label>
                            </li>
                        </ul>
                </div>

                <div className="mt25">
                    <div className="font16 color212 fw600">Number of properties owned</div>
                    <div className="mt15 quantity_wpr">
                        <button>-</button>
                        <input type="text" className="font14 fw500 ml15 mr15" value="00" />
                        <button>+</button>
                    </div>
                    <div className="flex_center justify_center mt30">
                        <div className="entergoalname_wpr widthauto">
                            <div className="entergoalname">
                                <form className="custome_form searchinput">
                                    <div className="form-row">
                                        <div className="form-group m0">
                                            <input type="email" className="form-control font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter property name" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="form_prnt mt20">
                        <form className="custome_form">
                            <div className="form-row justify-content-between">
                                <div className="col-md-3 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Property buying value</label>
                                    <div className="input-group selectarrow select50">
                                        <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Crores</option>
                                            <option value="1">1 cr</option>
                                            <option value="2">2 cr</option>
                                            <option value="3">3 cr</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <div className="col-md-3 pl20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Property buying value</label>
                                    <div className="input-group selectarrow select50">
                                        <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Crores</option>
                                            <option value="1">1 cr</option>
                                            <option value="2">2 cr</option>
                                            <option value="3">3 cr</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <div className="col-md-6 pl30">
                                    <div className="font14 color212 fw600">Property type</div>
                                    <div className="selectgoal propertysearch mt10">
                                        <div className="input-group selectarrow">
                                            <select className="custom-select font14 color212 fw500" id="inputGroupSelect01">
                                                <option selected>Primary</option>
                                                <option value="1">Primary 1</option>
                                                <option value="2">Primary 2</option>
                                                <option value="3">Primary 3</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </form>
                    </div>
                </div>

                <div className="mt25">
                    <div className="font16 color212 fw600">Number of properties owned</div>
                    <div className="mt15 quantity_wpr">
                        <button>-</button>
                        <input type="text" className="font14 fw500 ml15 mr15" value="00" />
                        <button>+</button>
                    </div>
                    <div className="flex_center justify_center mt30">
                        <div className="entergoalname_wpr widthauto">
                            <div className="entergoalname">
                                <form className="custome_form searchinput">
                                    <div className="form-row">
                                        <div className="form-group m0">
                                            <input type="email" className="form-control font15 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter loan name" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="linkproperty font12 fw500 color6d7">
                            Link to your property
                            <input type="text" className="form-control ml10 font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                        </div>
                    </div>
                    <div className="form_prnt mt20">
                        <form className="custome_form">
                            <div className="form-row justify-content-between">
                                <div className="col-md-4 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Total loan <span className="colorfa8">*</span></label>
                                    <div className="input-group selectarrow select50">
                                        <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Lakhs</option>
                                            <option value="1">1 Lakh</option>
                                            <option value="2">2 Lakh</option>
                                            <option value="3">3 Lakh</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <div className="col-md-4 pl20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">EMI <span className="colorfa8">*</span></label>
                                    <div className="input-group inputround">
                                        <input type="email" className="form-control font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter"></input>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <div className="col-md-4 pl20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Remaining loan <span className="colorfa8">*</span></label>
                                    <div className="input-group selectarrow select50">
                                        <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" />
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option selected>Lakhs</option>
                                            <option value="1">1 Lakh</option>
                                            <option value="2">2 Lakh</option>
                                            <option value="3">3 Lakh</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </form>
                    </div>
                </div>



            </div>
        </>
    )
}