import React, { Fragment, useState } from 'react'

import BasicDetailsScreen1 from '../../components/wizard/BasicDetailsScreen1'
import BasicDetailsScreen2 from '../../components/wizard/BasicDetailsScreen2'
import { nextsubStep, prevsubStep, nextStep } from '../../components/global'

export default function BasicDetails({ StepChange, steps, childCount, setChildCount, lifeStage, setLifeStage}) {

    const [substep, setSubstep] = useState(1);

    return (
        <Fragment>
            {substep === 1 && (<BasicDetailsScreen1 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} childCount={childCount} setChildCount={setChildCount} lifeStage={lifeStage} setLifeStage={setLifeStage}/>)}
            {substep === 2 && (<BasicDetailsScreen2 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} StepChange={StepChange} steps={steps} nextStep={nextStep} prevsubStep={prevsubStep} childCount={childCount} lifeStage={lifeStage}/>)}

        </Fragment>
    )
}
