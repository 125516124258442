import React, { Fragment } from 'react'
import './admAssets/css/admstyle.css'

export default function AdmRiskHistory() {
    return (
        <Fragment>
            <div className="innercontainer mb40">
                <div className="mainwrapper">

                <div className='form_title'><div className="mt30 font21 fw600 color384">Admin Dashboard</div></div>
                
                    <div className="content container-fluid">

                        <div className="page-header">
                            <div className="content-page-header ">
                                <h5>Ticket Overview</h5>
                                <div className="list-btn">
                                    <ul className="filter-list">
                                        <li>
                                            <a className="btn btn-primary popup-toggle rounded-circle d-inline-flex p-2" href="/#"> btn<i className="fe fe-filter" aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className="ticket-details-group">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="customer-details">
                                        <div className="d-flex align-items-center">
                                            <span className="ticket-widget-img rounded-circle d-inline-flex">
                                                <img src="assets/img/icons/ticket.svg" alt="" />
                                            </span>
                                            <div className="ticket-details-cont">
                                                <p>TK-105</p>
                                                <h6>New Support Ticket</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="ticket-details text-lg-end text-md-end">
                                        <span className="badge bg-warning-light text-warning-light">Medium</span>
                                        <span className="badge bg-success-light ms-2">Paid</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ticket-description-group">
                            <h6>Description</h6>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            <p className="mb-0">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        <div className="ticket-information">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="customer-details">
                                        <div className="d-flex align-items-center">
                                            <span className="customer-widget-img d-inline-flex">
                                                <img className="rounded-circle" src="assets/img/profiles/avatar-05.jpg" alt="" />
                                            </span>
                                            <div className="customer-details-cont">
                                                <h6>Requester</h6>
                                                <p>John Smith</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="customer-details">
                                        <div className="d-flex align-items-center">
                                            <span className="customer-widget-icon rounded-circle d-inline-flex">
                                                <i className="fe fe-calendar"></i>
                                            </span>
                                            <div className="customer-details-cont">
                                                <h6>Requested Date</h6>
                                                <p>30 Dec, 2022</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="customer-details border-0">
                                        <div className="d-flex align-items-center">
                                            <span className="customer-widget-icon rounded-circle d-inline-flex">
                                                <i className="fe fe-file-text"></i>
                                            </span>
                                            <div className="customer-details-cont">
                                                <h6>Subject</h6>
                                                <p>Support Ticket</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ticket-history ticket-information pb-0">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="card-inform">
                                        <div className="ticket-info d-flex align-items-center justify-content-between">
                                            <h6>Attachments</h6>
                                            <label className="ticket-upload upload-doc mb-0">
                                                <span className="report-info"><i className="fe fe-paperclip me-2"></i></span>Add File
                                                <input type="file" multiple="" id="image_personal" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="support-details d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <span className="support-widget-icon rounded-circle d-inline-flex">
                                                <i className="fe fe-file-text"></i>
                                            </span>
                                            <div className="support-details-cont">
                                                <h6>New Support Ticket</h6>
                                                <p>3.7MB</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <a className="btn-action-icon me-2" href="/#" download=""><i className="fe fe-download"></i></a>
                                            <div className="dropdown dropdown-action">
                                                <a href="/#" className="btn-action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-edit me-2"></i>Edit</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-trash-alt me-2"></i>Delete</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-eye me-2"></i>View</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="support-details d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <span className="support-widget-icon rounded-circle d-inline-flex">
                                                <i className="fe fe-file-text"></i>
                                            </span>
                                            <div className="support-details-cont">
                                                <h6>New Support Ticket</h6>
                                                <p>3.7MB</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <a className="btn-action-icon me-2" href="/#" download=""><i className="fe fe-download"></i></a>
                                            <div className="dropdown dropdown-action">
                                                <a href="/#" className="btn-action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-edit me-2"></i>Edit</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-trash-alt me-2"></i>Delete</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-eye me-2"></i>View</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="support-details d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <span className="support-widget-icon rounded-circle d-inline-flex">
                                                <i className="fe fe-file-text"></i>
                                            </span>
                                            <div className="support-details-cont">
                                                <h6>New Support Ticket</h6>
                                                <p>3.7MB</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <a className="btn-action-icon me-2" href="/#;" download=""><i className="fe fe-download"></i></a>
                                            <div className="dropdown dropdown-action">
                                                <a href="/#" className="btn-action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-edit me-2"></i>Edit</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-trash-alt me-2"></i>Delete</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-eye me-2"></i>View</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="support-details d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <span className="support-widget-icon rounded-circle d-inline-flex">
                                                <i className="fe fe-file-text"></i>
                                            </span>
                                            <div className="support-details-cont">
                                                <h6>New Support Ticket</h6>
                                                <p>3.7MB</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <a className="btn-action-icon me-2" href="/#" download=""><i className="fe fe-download"></i></a>
                                            <div className="dropdown dropdown-action">
                                                <a href="/#" className="btn-action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-edit me-2"></i>Edit</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-trash-alt me-2"></i>Delete</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/#"><i className="far fa-eye me-2"></i>View</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="card-inform">
                                        <div className="ticket-info d-flex align-items-center justify-content-between">
                                            <h6>History</h6>
                                        </div>
                                    </div>
                                    <div className="card-inform d-block mt-lg-5 mt-0">
                                        <ul className="activity-feed">
                                            <li className="feed-item">
                                                <span className="feed-text"><strong className="text-gray-dark me-2">John Smith</strong> Created a new response Created a new response</span>
                                                <p>30 Dec 2022 01:24AM</p>
                                            </li>
                                            <li className="feed-item">
                                                <span className="feed-text"><strong className="text-gray-dark me-2">Forest Kroch </strong> Lorem ipsum dolor sit amet,</span>
                                                <p>24 Dec 2022 10:36AM</p>
                                            </li>
                                            <li className="feed-item">
                                                <span className="feed-text"><strong className="text-gray-dark me-2">Townsend Seary </strong> Sed ut perspiciatis unde</span>
                                                <p>18 Dec 2022 09:18PM</p>
                                            </li>
                                            <li className="feed-item">
                                                <span className="feed-text"><strong className="text-gray-dark me-2">Margaretta Worvell </strong> Nemo enim ipsam voluptatem</span>
                                                <p>19 Jan 2022 09:18PM</p>
                                            </li>
                                            <li className="feed-item">
                                                <span className="feed-text"><strong className="text-gray-dark me-2">Harald Kowalski </strong> Neque porro quisquam est, qui dolorem</span>
                                                <p>12 May 2022 09:18PM</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="comments">
                            <div className="comments-head"><h5>Comments</h5></div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="comments-details d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <span className="comments-widget-img rounded-circle d-inline-flex">
                                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-01.jpg" alt="User 1" />
                                            </span>
                                            <div className="comments-details-cont">
                                                <h6>Dennis</h6>
                                                <p>a week ago</p>
                                            </div>
                                        </div>
                                        <a href="/#" className="reply-comment d-flex"><span><i className="fe fe-corner-down-left me-2"></i></span>Reply</a>
                                    </div>
                                    <div className="card-describe">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt labore dolore magna aliqua. Ut enim minim veniam, nostrud exercitation ullamco laboris nisi ut aliquip ex commodo consequat. Duis aute non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="card">
                                <div className="card-body">
                                    <div className="comments-details d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <span className="comments-widget-img rounded-circle d-inline-flex">
                                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-02.jpg" alt="User 2" />
                                            </span>
                                            <div className="comments-details-cont">
                                                <h6>Alexandr</h6>
                                                <p>a week ago</p>
                                            </div>
                                        </div>
                                        <a href="/#" className="reply-comment d-flex"><span><i className="fe fe-corner-down-left me-2"></i></span>Reply</a>
                                    </div>
                                    <div className="card-describe">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt labore dolore magna aliqua. Ut enim minim veniam, nostrud exercitation ullamco laboris nisi ut aliquip ex commodo consequat. Duis aute non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="card">
                                <div className="card-body">
                                    <div className="comments-details d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <span className="comments-widget-img rounded-circle d-inline-flex">
                                                <img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-04.jpg" alt="User Image1" />
                                            </span>
                                            <div className="comments-details-cont">
                                                <h6>Doris Brown</h6>
                                                <p>a week ago</p>
                                            </div>
                                        </div>
                                        <a href="/#" className="reply-comment d-flex"><span><i className="fe fe-corner-down-left me-2"></i></span>Reply</a>
                                    </div>
                                    <div className="card-describe">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt labore dolore magna aliqua. Ut enim minim veniam, nostrud exercitation ullamco laboris nisi ut aliquip ex commodo consequat. Duis aute non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </Fragment>
    )
}
