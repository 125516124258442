
import React, { Fragment } from 'react'

import img_1 from '../../img/qna-response/1_0-10.png';
import img_2 from '../../img/qna-response/2_11-15.png';
import img_3 from '../../img/qna-response/3_16-19.png';
import img_4 from '../../img/qna-response/4_20.png';
import SideImg from '../../img/BasicProfile.png';
import { req_params } from '../../components/global';
import { useEffect } from 'react';

export default function QnaResponse() {



    let json = '';
    if (sessionStorage.getItem("userData") !== null) {
        json = JSON.parse(sessionStorage.getItem("userData"));
    } else {

        const queryString = window.location.search;
        window.location.href = 'finnfit' + queryString;
    }

    useEffect(() => {
        // Trigger Google Analytics event when component mounts
        window.gtag('event', 'conversion', {
            send_to: 'AW-10845728926/ryzzCPnMiL0YEJ7h0rMo',
        });
    }, []);


    // let FinnFit_Score = json.score / 20;
    /* let FinnFit_Score_text = '';
     let bg_class = '';
        if (FinnFit_Score <= 0.5) {
            FinnFit_Score_text = 'Your Financial Fitness needs urgent intervention.';
            bg_class = 'box-bg-red'; //#ff5942
    
        }
    
        if (FinnFit_Score > 0.5 && FinnFit_Score < 0.8) {
            FinnFit_Score_text = 'Your Financial Fitness can be better.';
            bg_class = 'box-bg-blue'; // #FD9D4F 
        }
        if (FinnFit_Score >= 0.8) {
            FinnFit_Score_text = 'Your Financial Fitness is in decent shape.';
            bg_class = 'box-bg-green'; // #e3b922eb yello
    
        }
        if (FinnFit_Score === 1) {
            FinnFit_Score_text = 'Your Financial Fitness is top notch!!'
            bg_class = 'box-bg-green'; // #22af4a green
    
        } */


    const params = () => {
        const f = JSON.parse(sessionStorage.getItem("QnaFirst"));
        const l = JSON.parse(sessionStorage.getItem("QnaLast"));
        sessionStorage.setItem("user_params", JSON.stringify({
            "name": f.fullName,
            "mobile": l.mobileNumber,
            "email": l.emailAddress,
        }));
        const ref_by = (req_params.get('ref_by') ? req_params.get('ref_by') : 'FINNOVATE');
        const s = (req_params.get('s') ? req_params.get('s') : '');

        window.location = '/sign-in?q=2&utm_source=FinnFitScoreEndScreen&utm_campaign=FinnFitQuiz&utm_id=9423&ref_by=' + ref_by + '&s=' + s;
    }

    const bookSlot = () => {
        const f = JSON.parse(sessionStorage.getItem("QnaFirst"));
        const l = JSON.parse(sessionStorage.getItem("QnaLast"));

        const utm_source = (req_params.get('utm_source') ? req_params.get('utm_source') : '');
        const utm_medium = (req_params.get('utm_medium') ? req_params.get('utm_medium') : '');
        const utm_campaign = (req_params.get('utm_campaign') ? req_params.get('utm_campaign') : '');
        const utm_term = (req_params.get('utm_term') ? req_params.get('utm_term') : '');

        window.location = 'https://calendly.com/finnovate/financial-fitness-consultation?name=' + f.fullName + '&email=' + l.emailAddress + '&a1=+91' + l.mobileNumber+ '&utm_source=' + utm_source + '&utm_medium=' + utm_medium+ '&utm_campaign=' + utm_campaign+ '&utm_term=' + utm_term;
    }

    let image = img_1;
    let FinnFit_Score_text = '';
    let FinnFit_Score_text_2 = '';
    let qna_response_title_style = '';
    //json.score =7;
    if (json.score <= 10) {
        image = img_1;
        FinnFit_Score_text = 'Your Financial Fitness needs urgent intervention.';
        FinnFit_Score_text_2 = 'Sign up for your free session today';
        //#ff5942 red
        qna_response_title_style = {
            color: 'white',
            background: '#ff5942',
        }
    }
    if (json.score >= 11 && json.score <= 15) {
        image = img_2;
        FinnFit_Score_text = 'Your Financial Fitness can be better.';
        FinnFit_Score_text_2 = 'You have some building blocks in place';
        // #FD9D4F kesariya
        qna_response_title_style = {
            color: 'white',
            background: '#FD9D4F',
        }
    }
    if (json.score >= 16 && json.score <= 19) {
        image = img_3;
        FinnFit_Score_text = 'Your Financial Fitness is in decent shape.';
        FinnFit_Score_text_2 = 'A few tweaks and you will be on your way to a perfect 20/20 score.';
        //#fcd64f yellow
        qna_response_title_style = {
            color: 'white',
            background: '#fcd64f',
        }
    }
    if (json.score >= 20) {
        image = img_4;
        FinnFit_Score_text = 'Your Financial Fitness is top notch!!'
        FinnFit_Score_text_2 = 'Want a free session anyways?';
        // #22af4a green
        qna_response_title_style = {
            color: 'white',
            background: '#22af4a',
        }
    }

    const QnaFirst = JSON.parse(sessionStorage.getItem("QnaFirst"));
    //  const QnaLast = JSON.parse(sessionStorage.getItem("QnaLast"));
    useEffect(() => {
        // Set up a timeout to clear sessionStorage after 5 seconds
        const timeout = setTimeout(() => {
            sessionStorage.removeItem("userData");
        }, 5000);

        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeout);
    }, []); // Empty dependency array to run only once on component mount


    return (

        <Fragment>


            <>
                {/* 
                <script asyncsrc="https://www.googletagmanager.com/gtag/js?id=AW-10845728926"></script>
                <script id="google-analytics">
                    {`
				  window.dataLayer = window.dataLayer || [];
				  function gtag(){dataLayer.push(arguments);}
				  gtag('js', new Date());
				 gtag('config', 'AW-10845728926');
                `}
                </script>
                <script>{` gtag('event', 'conversion', {'send_to': 'AW-10845728926/ryzzCPnMiL0YEJ7h0rMo'});`}
                </script>
                
                <script id="meta-pixel-code">
                    {`
          !function(f,b,e,v,n,t,s)
          {
            if(f.fbq) return;
            n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq) f._fbq=n;
            n.push=n;
            n.loaded=!0;
            n.version='2.0';
            n.queue=[];
            t=b.createElement(e);
            t.async=!0;
            t.src=v;
            s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
          }(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2427914334198830');
          fbq('track', 'PageView');
        `}
                </script>
                <noscript>
                    <img
                        height="1"
                        width="1"
                        style={{ display: "none" }}
                        src="https://www.facebook.com/tr?id=2427914334198830&ev=PageView&noscript=1"
                        alt="pixel"
                    />
        </noscript>*/}
            </>
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className='form_title'>
                        <div className="mt30 font20 fw600">Get my FinnFit Report</div>
                        <div className="font15 color495">You will receive an email soon</div>

                    </div>

                    <div className="row formWpr mt25">
                        <div className="col-lg-8 formouter">


                            <div className="form_prnt pt25 pl35 pr35 pb35">



                                <div className='font20 fw600 mb-3'>
                                    {QnaFirst.fullName}, Your FinnFit score is  {json.score}/20
                                </div>

                                <div className={' qna-response-title'} style={{ qna_response_title_style }}>
                                    {FinnFit_Score_text}
                                </div>
                                <br />

                                <p>FinnFit is a customized financial fitness program that partners with doctors so that you can become well-off and enjoy the fruits of your labour, into your winter years.</p>
                                <br />
                                <div>


                                    <img src={image} alt=''></img>
                                    <br />
                                    <br />
                                    <p><strong> {FinnFit_Score_text}</strong></p>
                                    <p>{FinnFit_Score_text_2} </p>

                                    <p>Book a Complimentary Financial Fitness Counselling session </p>
                                    <p>and walk away with a Financial Plan worth 15k today </p>
                                    <br />
                                    <br />
                                    <row className="col-12 d-none d-md-block">
                                        <button type="button" className="btn-sm bluebtn font13 fw600 " style={{
                                            'background': '#EBEEF3',
                                            'color': '#182338',
                                            'margin-right': '20px',
                                            'font-weight': 'normal',
                                            'width': '200px'
                                        }} onClick={() => params()}> Register to Plan</button>
                                        <button type="button" className="bluebtn font13 fw600" onClick={() => bookSlot()} style={{ 'width': '200px' }}>Book a slot</button>
                                    </row>
                                    <br />
                                    <br />
                                    <p>Note: This score is based on data provided by you. </p>
                                </div>

                            </div>

                            <br />
                            <br />
                            <div className='p35'>


                            </div>


                            <div className="saveBtn backsaveBtn d-block d-sm-none">
                                <button type="button" className="btn btn-lg btn-green" onClick={() => params()} >Register to Plan </button>
                                <button type="button" className="btn btn-lg btn-primary" onClick={() => bookSlot()}>Book a slot </button>
                            </div>

                        </div>

                        <div className="col-lg-4 qouteouter d-none d-md-block">
                            <div className="qoutetext font18 fw500 color212 pl45 pr45">
                                <div className="qoutesign">
                                    &#8220;
                                </div>
                                Having a written financial plan gives you a measurable goal to work toward. Because you can track your progress, you can reduce doubt or uncertainty about your decisions and make adjustments to help overcome obstacles that could derail you.
                            </div>


                            <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                        </div>
                    </div>

                </div>

                <div className="row mt50">
                    <div className="col-lg-12">

                        <p className='font11'>Disclaimer:</p>

                        <p className='font11'>This financial fitness score is provided for educational and informational purposes only and is not intended to provide investment, tax, or legal advice. The score is calculated based on the information you provide and is not a guarantee of future performance or success. Your personal financial situation is unique, and the score may not reflect your true financial health. It is important to seek professional advice before making any financial decisions. The score is not a substitute for professional judgment and is subject to change without notice. The provider of this score assumes no responsibility for any errors or omissions in the information provided. By using this score, you agree to hold harmless the provider and its affiliates for any losses or damages that may result from any inaccuracies or incompleteness of the information.</p>

                        <p className='font11'> Registration granted by SEBI, membership of BASL and certification from NISM in no way
                            guarantee performance of the intermediary or provide any assurance of returns to investors</p>
                        <p className='font11'>Mutual Fund investments are subject to market risks, read all scheme related documents carefully.</p>
                        <p className='font11'>Past performance is not indicative of future returns. Please consider your specific investment
                            requirements, risk tolerance, goal, time frame, risk and reward balance and the cost associated with the
                            investment before choosing a fund, or designing a portfolio that suits your needs. Performance and returns
                            of any investment portfolio can neither be predicted<br />
                            nor guaranteed.</p>
                        <p className='font11'>SEBI RIA Registration No: INA000013518 | Type of Registration: Non-Individual | Validity of registration:
                            June 19, 2019 - Perpetual | Address: The Summit Business Park, 7th Floor, Unit No. 703, Andheri - Kurla
                            Rd, Behind Guru Nanak Petrol Pump, Opp. PVR Cinema, Andheri East, Mumbai – 400069 | Contact No. 9819022600
                            | Principal Officer details: Nehal Mota, Email : nehal.mota@finnovate.in, Phone: 9152022446 |
                            Corresponding Local SEBI Office : SEBI Office Address: Office of Investor Assistance and Education, SEBI
                            Bhavan Plot No.C4-A, G Block, Bandra Kurla Complex, Bandra (E), Mumbai - 400 051</p>
                        <p className='font11'>AMFI Registered Distributor - ARN 52128 | Platform Partner : BSE (Member code 17548) | IRDAI Corporate
                            Agency : CA0703 | CIN: U67190MH2007PTC172272 | GST No : 27AABCF1124C2ZC | Website – <a href="http://www.finnovate.in" target="_blank" rel='noreferrer'>www.finnovate.in</a> Email – <a href="mailto:hello@finnovate.in">hello@finnovate.in</a></p>

                    </div>

                </div>
            </div>
        </Fragment>



    )
}
