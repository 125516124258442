import React, { Fragment } from 'react';
import formatAmount from 'indian-currency-formatter';

export default function ViewCashflow({ toggleClass, goal, goalName, profileName, setActive, cashflow }) {

    console.log(cashflow);

    const Back = () => {
        setActive(false);
    }



    return (
        <Fragment>

            <div className="rightSide_menu_outer">
                <div className="rightSide_menu fullSidebar">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            {goalName} Cashflow for <strong>{profileName}</strong>
                        </div>
                        <div>&nbsp;</div>
                    </div>


                    <div className="form_prnt p20">



                        <div className="dashboardTable">

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Year</th>
                                        <th scope="col"><span className="dotstatus">Fresh Inv.</span></th>
                                        <th scope="col"><span className="dotstatus darkyellowdot">Existing Inv.</span></th>
                                        <th scope="col"><span className="dotstatus greendot">FV</span></th>
                                        <th scope="col"><span className="dotstatus bluedot">Other Inc</span></th>
                                        <th scope="col">Outflows</th>
                                        <th scope="col">Net Amount</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {// eslint-disable-next-line
                                        cashflow.length > 0 ?
                                            cashflow.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{item.year}</td>
                                                            <td>{formatAmount(item.fresh_investment)}</td>
                                                            <td>{formatAmount(item.actual_fund)}</td>
                                                            <td>{formatAmount(item.fv)}</td>
                                                            <td>{item.other_income}</td>
                                                            <td>{formatAmount(item.outflow)}</td>
                                                            <td className={item.nett < 0 ? 'text-danger' : ''}>{formatAmount(item.nett)}</td>
                                                        </tr>
                                                    </>
                                                )
                                            }) : ''}


                                </tbody>
                            </table>

                        </div>




                    </div>



                </div>
            </div>

        </Fragment >
    )
}
