import React, { Fragment } from 'react'
import SideImg from '../img/Protection.png';

export default function CashflowWizardExpense({ substep, setSubstep }) {


    return (
        <Fragment>
            <div className="innercontainer mb40">
                <div className="mainwrapper">

                    <div className='form_title'>
                        <div className="mt30 font20 fw600">{"clientName"} Cashflow</div>
                        <div className="font15 color495">we need to map a plan to match your goal. so we need your income infos.</div>
                    </div>

                    <div className="row formWpr mt25">
                        <div className="col-lg-8 formouter">
                            <ul className="form_swipe_tab font12 fw600">
                                <li onClick={() => setSubstep(1)}>1</li>
                                <li className="act">2</li>
                                <li>3</li>
                                <li>4</li>
                            </ul>


                            <form className="custome_form">




                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">Expenses</div>
                                        <div className="font15 color495 ">Also a bit approx but give us an idea of all the expenses you have.</div>
                                    </div>
                                    <div>

                                        <button className="bluebtn font13 fw600 d-none d-md-block">Save
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></button>
                                    </div>
                                </div>

                                {/* Form */}


                                <div className="p35">

                                    <div className="addincome_wpr">
                                        <div className="font16 fw600 color212">{"clientName"} family’s expenses</div>
                                        <div>
                                            <span className="addincome_btn font12 color212 fw600">Add Expense</span>
                                        </div>
                                    </div>


                                    <div className="form-row mt20">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Household expenses<span className="colorfa8">*</span></label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Household" />
                                                <select className="custom-select" id="inputGroupSelect01" name="HouseholdFreq">
                                                    <option value="12" selected>Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"> </span>
                                        </div>
                                        <div className="col-md-6 pl20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Rent</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="rentalExpense" />
                                                <select className="custom-select" id="inputGroupSelect01" name="rentalExpenseFreq">
                                                    <option value="12" selected>Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"> </span>
                                        </div>
                                    </div>

                                    <div className="form-row mt20">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Utility Bills</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="UtilityBills" />
                                                <select className="custom-select" id="inputGroupSelect01" name="UtilityBillsFreq">
                                                    <option value="12" selected>Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"> </span>
                                        </div>
                                        <div className="col-md-6 pl20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Lifestyle expenses</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Lifestyle" />
                                                <select className="custom-select" id="inputGroupSelect01" name="LifestyleFreq">
                                                    <option value="12" selected>Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>

                                    <div className="formdevider mt35 mb35" />


                                    <div className="form-row mt25">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Kids Education expenses</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="ChildrenEducation" />
                                                <select className="custom-select" id="inputGroupSelect01" name="ChildrenEducationFreq">
                                                    <option value="12" selected>Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="col-md-6 pl20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Vacation expenses</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Vacation" />
                                                <select className="custom-select" id="inputGroupSelect01" name="VacationFreq">
                                                    <option value="12" selected>Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>

                                        </div>
                                    </div>
                                </div>

                                <div className="saveBtn backsaveBtn d-block d-sm-none">
                                    <button type="button" className="btn btn-primary" onClick={() => setSubstep(1)}> Back </button>
                                    <button type='submit' className="btn btn-primary"> Save </button>
                                </div>

                            </form>

                            {/* Form End */}

                        </div>

                        <div className="col-lg-4 qouteouter d-none d-md-block">
                            <div className="qoutetext font18 fw500 color212 pl45 pr45">
                                <div className="qoutesign">
                                    &#8220;
                                </div>
                                If you have other expenses, please click add expenses button and add them.
                            </div>


                            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
