import React, { Fragment } from 'react'
import SideImg from '../img/Protection.png';

export default function CashflowWizardStart({ substep, setSubstep }) {


    const changeStep = () => {
        setSubstep(1)
    }



    return (
        <Fragment>
            <div className="innercontainer mb40">

                <div className="mainwrapper">

                    <div className="form_title">
                        <div className="mt30 font20 fw600">{"client"} Cashflow</div>
                        <div className="font15 color495">we need to map a plan to match your goal. so we need your income info</div>
                    </div>
                    <div className="row formWpr mt25">
                        <div className="col-lg-8 formouter">
                            <ul className="form_swipe_tab font12 fw600">

                                <li className="act">0</li>
                                <li onClick={() => setSubstep(2)}>1</li>
                                <li>2</li>
                                <li>3</li>
                            </ul>

                            <form className="custome_form" >


                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">Income</div>
                                        <div className="font15 color495 ">An approximate idea of your income is enough</div>
                                    </div>
                                    <div>

                                        <button className="bluebtn font13 fw600 d-none d-md-block" onClick={changeStep}>ADD CASHFLOW
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></button>
                                    </div>
                                </div>

                                {/* Form */}




                            </form>

                            {/* Form End */}

                        </div>

                        <div className="col-lg-4 qouteouter d-none d-md-block">
                            <div className="qoutetext font18 fw500 color212 pl45 pr45">
                                <div className="qoutesign">
                                    &#8220;
                                </div>
                                If your parents are getting pension, or if you inherit any other income, please just click add income.
                            </div>


                            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}
