import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap'
import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';

export default function PlanLoginLogoutLogs() {

  let navigate = useNavigate();

  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {



      axioInstance.post(`admin/get-login-logout-logs`).then(
        (response, data) => {
          setData(response.data.rows);
          setTableRowsData(response.data.rows);
        }
      );


    }
  }, [navigate]);


  const columns = [
    {
      name: 'ID',
      selector: row => row.log_id,
      sortable: true
    },
    {
      name: 'Client Name',
      selector: row => row.full_name,
      sortable: true
    },
    {
      name: 'Admin Name',
      selector: row => row.admin_name,
      sortable: true,
      width: '265px' // Set the width of the column
    },
    {
      name: 'Event Value',
      selector: row => row.event_value,
      sortable: true,
      width: '180px' // Set the width of the column
    },
    {
      name: 'Type',
      selector: row => row.user_type,
      sortable: true,
    },
    {
      name: 'IP',
      selector: row => row.ip_address,
      sortable: true,
    },
    {
      name: 'Location',
      selector: row => row.ip_address,
      sortable: true,
    },
    {
      name: 'Created',
      selector: row => row.created,
      sortable: true,
    }
  ];


  const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.full_name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.event_name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });
    setTableRowsData(searchData);
  };




  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

  const addProfile = () => {

    console.log('hostname', window.location);

    const url = window.location.origin + "/sign-in?q=2&ref_by=" + sessionStorage.getItem('shortName') + "&s=" + sessionStorage.getItem('admin_id');

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

  }

  const geuestUsersRedirect = () => {
    window.location.href = 'guest-users-dashboard';
    return;
  }


  const planEventsRedirect = () => {
    window.location.href = 'profile-event-logs-dashboard';
    return;
  }

  const AdminDashboardRedirect = () => {
    window.location.href = 'admin-dashboard';
    return;
  }

  return (
    <Fragment>
      <AdminDashboardHeader />



      <div className="innercontainer isPlanProfilesTimelinePage mb40">
        <div className="mainwrapper">
          <div className='form_title'><div className="mt30 font21 fw600 color384">PLAN Login Logout Logs Dashboard</div></div>

          <div className='row mt25'>



            <div className='col-md-3'>
              <div className="font14 color212 fw600">Search</div>
              <input
                type="text"
                placeholder="Name Search"
                value={title}
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
                className="form-control"
              />

            </div>


          </div>


          <div class="justify-content-left row mt35">
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={addProfile}>Add Profile</button>
            </div>
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => { setActive(true); setAction('addTestProfile') }}> Add Testing Profile </button>
            </div>
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => geuestUsersRedirect()}>Guest Users</button>
            </div>
            {
              sessionStorage.getItem('admin_id') === '1' && (
                <div class="col-lg-2 col-md-2">
                  <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => { setActive(true); setAction('addDirectProfile') }}>Add Direct Profile </button>
                </div>
              )
            }

            {
              sessionStorage.getItem('admin_id') === '1' && (
                <>
                  <div class="col-lg-2 col-md-2">
                    <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => planEventsRedirect()}>Plan Events Logs</button>
                  </div>

                  <div class="col-lg-2 col-md-2">
                    <Dropdown className="">
                      <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots dropdown-toggle btn btn-success">
                        Plan Reports
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href='plan-profiles-timeline'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Profile Timeline</Dropdown.Item>
                        <Dropdown.Item href='plan-profiles-status'><i className="fas fa-users text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Profile Plan Status</Dropdown.Item>
                        <Dropdown.Item href='plan-summary-report-drilldown-month'><i className="fas fa-bar-chart text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Summary Month Wise</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                </>
              )
            }

            <div class="col-lg-2 col-md-2 mt35">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => AdminDashboardRedirect()}>Admin Dashboard</button>
            </div>
          </div>


          <div className="">
            <div className="admin_dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableRowsData}
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      }

    </Fragment>
  );
}