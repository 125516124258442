import React, { Fragment, useState } from 'react'

import InvestmentScreen1 from '../../components/wizard/InvestmentScreen1'
import InvestmentScreen2 from '../../components/wizard/InvestmentScreen2'
import { nextStep, prevsubStep, nextsubStep } from '../../components/global'

export default function Investments({StepChange, steps, lifeStage, clientName}) {
   
  const [substep, setSubstep] = useState(1);
    // eslint-disable-next-line
    const [name, setName] = useState(sessionStorage.getItem('full_name'));
    return (
        <Fragment>
            {substep === 1 && (<InvestmentScreen1 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} clientName={name}/>)}
            {substep === 2 && (<InvestmentScreen2 setSubstep={setSubstep} substep={substep} nextsubStep={nextsubStep} StepChange={StepChange} steps={steps} nextStep={nextStep} prevsubStep={prevsubStep} lifeStage={lifeStage} clientName={name}/>)}
        </Fragment>
    )
}
