import React, { Fragment, useState, useEffect } from 'react'
import axioInstance from '../axiosInstance';
//import formatAmount from 'indian-currency-formatter';
import DashboardRightFamilyGrid from './DashboardRightFamilyGrid'
import DashboardRightInsuranceGrid from './DashboardRightInsuranceGrid'
import DashboardRightEmegencyFundGrid from './DashboardRightEmegencyFundGrid';
import DashboardRightInsuranceGraphGrid from './DashboardRightInsuranceGraphGrid';

export default function DashboardRightFamily({ verticalFamilyTab }) {

    const [retirement, setRetirement] = useState([]);
    const [ontrack, setOntrack] = useState();
    const [total, setTotal] = useState();

    const [familyActiveTab, setFamilyActiveTab] = useState('members');

    // eslint-disable-next-line
    const [insuranceGraph, setInsuranceGraph] = useState('hide');
    // eslint-disable-next-line
    const [insuranceGraphData, setInsuranceGraphData] = useState();
    // eslint-disable-next-line
    const [insuranceGraphType, setInsuranceGraphType] = useState();



    const familycheckTab = (tab) => {
        setFamilyActiveTab(tab);
    }


    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.get(`profile/summary/${profile_id}`).then(
            (response, data) => {

                let find_retirement = response.data.goals.find(product => product.goal_type === "Retirement");
                setRetirement(find_retirement);

                setOntrack(response.data.on_track);
                setTotal(response.data.goal_count);
            });

        setFamilyActiveTab(verticalFamilyTab ? verticalFamilyTab : 'members')
    }, [verticalFamilyTab]);



    return (
        <Fragment>
            <div className="dashboard_right family_right_dashboard">
                <div className="graphvalue family_right_graphvalue_hide">
                    <div className="graphvalue_left">
                        <div className="graphvalue_count font22 fw500">
                            <span className='font16'>{retirement.target_amt}</span>
                        </div>
                        <div className="ml15 font12 fw500 color6d7">
                            Retirement corpus required at {retirement.achived_by}
                        </div>
                    </div>
                    <div className="graphvalue_right">
                        <div className="graphvalue_count font22 fw500">

                            <span className='font16'>{ontrack}</span>
                        </div>
                        <div className="ml15 font12 fw500 color6d7">
                            Your are on track to achieve {ontrack} of {total} financial life goal
                        </div>
                    </div>
                </div>


                <div className="graphtabs_outer mt25">
                    <div className="graphtabs">
                        <nav className="font12">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className={familyActiveTab === "members" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-activeyears-tab" data-toggle="tab" href="#nav-activeyears" role="tab" aria-controls="nav-activeyears" aria-selected="true" onClick={() => familycheckTab('members')}>Family Members</a>
                                <a className={familyActiveTab === "insurance" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-retiredyears-tab" data-toggle="tab" href="#nav-retiredyears" role="tab" aria-controls="nav-retiredyears" aria-selected="false" onClick={() => familycheckTab('insurance')}>Insurance</a>
                                <a className={familyActiveTab === "emergency" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-tabularview-tab" data-toggle="tab" href="#nav-tabularview" role="tab" aria-controls="nav-tabularview" aria-selected="false" onClick={() => familycheckTab('emergency')}>Emergency funds</a>
                            </div>
                        </nav>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className={familyActiveTab === "members" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-activeyears" role="tabpanel" aria-labelledby="nav-activeyears-tab">
                            {familyActiveTab === 'members' && <DashboardRightFamilyGrid />}
                        </div>
                        <div className={familyActiveTab === "insurance" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-retiredyears" role="tabpanel" aria-labelledby="nav-retiredyears-tab">
                            {(familyActiveTab === 'insurance' && insuranceGraph === 'hide') && <DashboardRightInsuranceGrid setInsuranceGraph={setInsuranceGraph} setInsuranceGraphData={setInsuranceGraphData} setInsuranceGraphType={setInsuranceGraphType}/>}

                            {
                                insuranceGraph === 'show' && <DashboardRightInsuranceGraphGrid setInsuranceGraph={setInsuranceGraph} insuranceGraphData={insuranceGraphData} insuranceGraphType={insuranceGraphType}/>
                            }
                        </div>

                        <div className={familyActiveTab === "emergency" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-tabularview" role="tabpanel" aria-labelledby="nav-tabularview-tab">
                            {familyActiveTab === 'emergency' && <DashboardRightEmegencyFundGrid />}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
