import React, { Fragment } from 'react'
import axioInstance from './axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

export default function AddDirectProfileSidebar({ setActive }) {

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = Formdata => {


        const search = window.location.search;
        const params = new URLSearchParams(search);
        const utm_source = params.get('utm_source');
        const utm_campaign = params.get('utm_campaign');
        const utm_id = params.get('utm_id');

        const Postdata = {
            "full_name": Formdata.fullName,
            "mobile": Formdata.mobileNumber,
            "email": Formdata.emailAddress,
            "password": Formdata.password,
            "utm_source": utm_source,
            "utm_campaign": utm_campaign,
            "utm_id": utm_id,
            "a_from": "adminDashboard",
            "created_by": sessionStorage.getItem("admin_id")
        };

        axioInstance.post(`add-guestuser`, Postdata).then(function () {
            SweetAlert.fire({
                title: 'Successfully added Guest User',
                text: "Please verify Guest user with Admin to activate the profile.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OKAY'
            }).then((result) => {
                window.location.href = "admin-dashboard";
            });
        })

        /*const OtpEmail = {
            "full_name": FormData.fullName,
            "mobile": FormData.mobileNumber,
            "email": FormData.emailAddress,
            "otp_send_on": 'email',
            "password": FormData.password,
            'guestId': '',
            'notification': 'no'
        };

        axioInstance.post(`send-otp`, OtpEmail).then(
            function (response, data) {

                const OtpMobile = {
                    "full_name": FormData.fullName,
                    "email": FormData.emailAddress,
                    "mobile": FormData.mobileNumber,
                    "otp_send_on": 'mobile',
                    'notification': 'no'
                };

                axioInstance.post(`send-otp`, OtpMobile).then(
                    function (response, data) {

                        const admin_id = sessionStorage.getItem('admin_id');

                        // normal flow start here to register

                        const Postdata = {
                            "name": FormData.fullName,
                            "mobile": FormData.mobileNumber,
                            "email": FormData.emailAddress,
                            "password": FormData.password,
                            "register": 1,
                            "registerVia": 1,
                            "rel_manager": admin_id
                        };

                        const search = window.location.search;
                        const params = new URLSearchParams(search);


                        axioInstance.post(`register`, Postdata)
                            .then(function (response) {

                                sessionStorage.setItem('token', response.data.token);

                                axioInstance.post(`otp-verify-admin`, { email: FormData.emailAddress, admin_id: admin_id, 'guest_id': '' });
                                axioInstance.post(`otp-verify-admin-mobile`, { mobile: FormData.mobileNumber, admin_id: admin_id, 'guest_id': '' });

                                axioInstance.post(`profile/add`, Postdata).then(function (response) {

                                    const registered_profile_id = response.data.profile_id;

                                    sessionStorage.setItem('profile_id', response.data.profile_id);
                                    sessionStorage.setItem('profile_det_id', response.data.profile_det_id.profile_details_id);
                                    axioInstance.post(`default-risk-profile`, { profile_id: response.data.profile_id }).then(function (response) {
                                    });

                                    axioInstance.post(`profile/summary-add`, { profile_id: response.data.profile_id }).then(function (response) {
                                    });

                                    // eslint-disable-next-line
                                    let fullname = FormData.fullName;
                                    var names = fullname.split(' ');

                                    if (window.location.hostname === "plan.finnovate.in") {

                                        const relationship_manager = params.get('ref_by') !== '' ? params.get('ref_by') : (params.get('ref_by') !== null ? params.get('ref_by') : 'FINNOVATE');

                                        const utm_source = params.get('utm_source') ? params.get('utm_source') : 'FinnPlan';
                                        const utm_campaign = params.get('utm_campaign') ? params.get('utm_campaign') : 'FinnPlan';
                                        const utm_id = params.get('utm_id') ? params.get('utm_id') : '9423';

                                        const apiData = JSON.stringify({
                                            "campaign_id": utm_id,
                                            "campaign_type": 'TeleSales',
                                            "check_condication_1": '0',
                                            "check_condication_2": '0',
                                            "ignore_duplicate": '1',
                                            "ignore_open_task": '1',
                                            "check_condication_task": '0',
                                            "consider_same_account": '0',
                                            "match_col": 'email_and_mobile',
                                            "lead_source": utm_source,
                                            "lead_caimpaign": utm_campaign,
                                            "relationship_manager": relationship_manager,
                                            "profile_details_id": response.data.profile_det_id.profile_details_id,
                                            "tele_caller": '1000049',
                                            "ref_url": window.location.href,
                                            "import_data": {
                                                0: {
                                                    "f_name": names[0] ? names[0] : '',
                                                    "m_name": '',
                                                    "l_name": names[1] ? names[1] : '',
                                                    "name": FormData.fullName,
                                                    "mobile": FormData.mobileNumber,
                                                    "email": FormData.emailAddress
                                                }
                                            }
                                        });


                                        axios.post(`https://lmslive.finnovate.in/import/import-everything-api.php`, apiData).then(function (response) {

                                            axioInstance.post(`profile/add-zoho-crm-profile`, { profile_id: registered_profile_id }).then(function (response) {
                                            });

                                        });

                                        // eslint-disable-next-line
                                        axioInstance.post(`sendinblue/add-list`, { listid: 8, action: 'insert', email: FormData.emailAddress, attributes: { EMAIL: emailAddress, FULL_NAME: mobileNumber, MOBILE: '+91' + mobileNumber, SMS: '+91' + mobileNumber, LEAD_SOURCE: 4, WHATSAPP: '+91' + mobileNumber } }).then(function (response) {
                                        });
                                        // eslint-disable-next-line
                                        axioInstance.post(`get-plan-steps`, { profile_id: response.data.profile_id }).then(
                                            (response, data) => {
                                                if (response.data.status === 100) {

                                                    window.location.reload();

                                                }else{
                                                    window.location.reload();
                                                }
                                            }
                                        );

                                    }else{
                                        window.location.reload();
                                    }

                                });

                            })
                            .catch(e => {

                                if (e.response.data.data.hasOwnProperty("password")) {
                                    SweetAlert.fire({
                                        toast: true,
                                        icon: 'error',
                                        title: e.response.data.data.password[0],
                                        animation: false,
                                        position: 'top-right',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                                        }
                                    });
                                } else {

                                    SweetAlert.fire({
                                        toast: true,
                                        icon: 'error',
                                        title: e.response.data.data.email[0],
                                        animation: false,
                                        position: 'top-right',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                                        }
                                    });

                                }
                            });

                    }
                )
            }
        )*/


    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Add Guest User
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Add Guest User</div>
                        <div className="font14 fw500 color485">For Skipping OTP Purpose</div>
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label>Your full name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("fullName", { required: "Full name is required" })}
                                    placeholder="Enter full name"
                                    autoComplete="off"
                                />
                                {errors.fullName && <span className='redtext'>{errors.fullName.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    {...register("emailAddress", {
                                        required: "Email address is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                            message: "Enter a valid email address"
                                        }
                                    })}
                                    placeholder="Enter email address"
                                    autoComplete="nope"
                                />
                                {errors.emailAddress && <span className='redtext'>{errors.emailAddress.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Mobile number</label>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">+91</span>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        {...register("mobileNumber", {
                                            required: "Mobile number is required",
                                            pattern: {
                                                value: /^[0-9]{10}$/,
                                                message: "Enter a valid mobile number"
                                            }
                                        })}
                                        placeholder="Mobile Number"
                                        autoComplete="off"
                                    />
                                </div>
                                {errors.mobileNumber && <span className='redtext'>{errors.mobileNumber.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    {...register("password", { required: "Password is required" })}
                                    placeholder="Enter password"
                                    autoComplete="new-password"
                                />
                                {errors.password && <span className='redtext'>{errors.password.message}</span>}
                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
