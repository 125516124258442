import React, { useState, useEffect } from 'react'
import { inWords } from '../global'

export default function HistoryAssetswhatyouown({ investmentData }) {

    console.log(investmentData);

    // eslint-disable-next-line
    const [investment, setInvestment] = useState([]);
    // eslint-disable-next-line
    const [equity, setEquity] = useState();
    const [debt, setDebt] = useState();
    const [goldDebt, setGoldDebt] = useState();
    const [other, setOther] = useState();

    const [total, setTotal] = useState();

    useEffect(() => {

        setInvestment(investmentData.data);


        let find_equity = investmentData.sum.find(product => product.name === "Equity");
        setEquity(find_equity ? find_equity.subTotal : parseInt('00'));

        let find_debt = investmentData.sum.find(product => product.name === "Debt");
        setDebt(find_debt ? find_debt.subTotal : parseInt('00'));

        let gold_debt = investmentData.sum.find(product => product.name === "Gold");
        setGoldDebt(gold_debt ? gold_debt.subTotal : parseInt('00'));

        let find_others = investmentData.sum.find(product => product.name === "Property");
        setOther(find_others ? find_others.subTotal : parseInt('00'));

    }, [investmentData]);

    useEffect(() => {

        setTotal((parseInt(equity) + parseInt(debt) + parseInt(goldDebt) + parseInt(other)));

    }, [equity, debt, other, goldDebt]);


    return (
        <>
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Personal Information</span></div>

            <div className="font22 fw700 color0C4 pt30">Assets - What you own</div>

            <div className="font14 fw500 color182 mt20">
                Asset Allocation is an investment strategy that aims to balance risk and reward by apportioning a portfolio according to an individuals goals, risk tolerance and investment horizon. Over time, this is one of the most important factors in determining the return on your portfolio, as different assets have different risk reward profile. An appropriate asset allocation can help provide diversification, enhance return potential, lower overall risk & volatility and position your portfolio to take advantage of developing investment opportunities.
            </div>

            <div className="inflowbox_outer mt30">
                <div className="inflowbox_outer_left">
                    <div className="inflowbox_title font12 bluedot fw600 color6d7">Total Assets</div>
                    <div className="font24 fw500 color263 mt12">{inWords(total)}</div>
                </div>
                <div className="inflowbox_outer_right">
                    <div className="totalSaving_box p0 borderzero">
                        <div>
                            <div className="inflowbox_title lightgreendot font12 fw600 color6d7">Equity</div>
                            <div className="font24 fw500 color263 mt12">{inWords(equity)}</div>
                        </div>
                        <div className="circleBtn_outer">
                            <div className="circleBtn">+</div>
                        </div>
                        <div>
                            <div className="inflowbox_title greendot font12 fw600 color6d7">Debt</div>
                            <div className="font24 fw500 color263 mt12">{inWords(debt+goldDebt)}</div>
                        </div>
                        <div className="circleBtn_outer">
                            <div className="circleBtn">+</div>
                        </div>
                        <div>
                            <div className="inflowbox_title greendot font12 fw600 color6d7">Others</div>
                            <div className="font24 fw500 color263 mt12">{inWords(other)}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="font14 fw500 color182 mt30">Your Assets breakup analysis</div>

            <div className="inflowbox_outer mt15">
                <div className="inflowbox_outer_left">
                    <div className="inflowbox_title lightgreendot font12 fw600 color6d7">EQUITY</div>
                    <div className="font24 fw500 color263 mt12">{inWords(equity)}</div>
                </div>
                <div className="inflowbox_outer_right">
                    <div className="inflowdtl">
                        {investment.map((data, key) => {
                            return (<>
                                {
                                    data.cat_name === "Equity" && (<>
                                        <div>
                                            <div className="font13 color7B8 fw500">{data.type_name}</div>
                                            <div className="font18 fw500 color263">{inWords(data.current_value)}</div>
                                        </div>
                                    </>)
                                }
                            </>);
                        })}
                    </div>
                </div>
            </div>

            <div className="inflowbox_outer mt15">
                <div className="inflowbox_outer_left">
                    <div className="inflowbox_title greendot font12 fw600 color6d7">DEBT</div>
                    <div className="font24 fw500 color263 mt12">{inWords(debt+goldDebt)}</div>
                </div>
                <div className="inflowbox_outer_right">
                    <div className="inflowdtl">
                        {investment.map((data, key) => {
                            return (<>
                                {
                                    (data.cat_name === "Debt" || data.cat_name === "Gold") && (<>
                                        <div>
                                            <div className="font13 color7B8 fw500">{data.type_name}</div>
                                            <div className="font18 fw500 color263">{inWords(data.current_value)}</div>
                                        </div>
                                    </>)
                                }
                            </>);
                        })}
                    </div>
                </div>
            </div>

            <div className="inflowbox_outer mt15">
                <div className="inflowbox_outer_left">
                    <div className="inflowbox_title greendot font12 fw600 color6d7">Others</div>
                    <div className="font24 fw500 color263 mt12">{inWords(other)}</div>
                </div>
                <div className="inflowbox_outer_right">
                    <div className="inflowdtl">
                        {investment.map((data, key) => {
                            return (<>
                                {
                                    data.cat_name === "Property" && (<>
                                        <div>
                                            <div className="font13 color7B8 fw500">{data.type_name}</div>
                                            <div className="font18 fw500 color263">{inWords(data.current_value)}</div>
                                        </div>
                                    </>)
                                }
                            </>);
                        })}

                    </div>
                </div>
            </div>
        </>
    )
}
