import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
import formatAmount from 'indian-currency-formatter';

export default function EditTermInsurance({ commitments, self, spouse }) {


    const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

    const onSubmit = data => {

        console.log(data);

        const Postdata = {
            "prop_id": sessionStorage.getItem('profile_id'),
            "profile_id": sessionStorage.getItem('profile_id'),
            "profile_details_id": sessionStorage.getItem('profile_details_id'),
            "type": 4,
            "sub_type": 1,
            "owner": sessionStorage.getItem('profile_id'),
            "instr_name": 'Self Term',
            "invest_type": 0,
            "invest_amount": data.selfpremiumAmount.replace(/,/g, ""),
            "amount": data.selfpremiumAmount.replace(/,/g, ""),
            "cost_amount": data.selfMoneyType * data.selfsumAssured,
            "moneyDigits": data.selfMoneyType,
            "insured_value": data.selfMoneyType * data.selfsumAssured,
            "current_value": data.selfMoneyType * data.selfsumAssured,
            "frequency": data.selfFrequency,
            "ppt": 1,
            "policy_term": 1,
            "rider_id": [sessionStorage.getItem('profile_details_id')],
            "invest_id": data.selfInvestId,
            "admin_id":sessionStorage.getItem("admin_id")
        };

        axioInstance.post(`investment/add-investment`, Postdata)
            .then(function (response) {

                const Postdata = {
                    "prop_id": sessionStorage.getItem('profile_id'),
                    "profile_id": sessionStorage.getItem('profile_id'),
                    "profile_details_id": sessionStorage.getItem('spouse_prof_det_id'),
                    "type": 4,
                    "sub_type": 1,
                    "owner": sessionStorage.getItem('profile_id'),
                    "instr_name": 'Spouse Term',
                    "invest_type": 0,
                    "invest_amount": data.spousepremiumAmount.replace(/,/g, ""),
                    "amount": data.spousepremiumAmount.replace(/,/g, ""),
                    "cost_amount": data.spouseMoneyType * data.spousesumAssured,
                    "insured_value": data.spouseMoneyType * data.spousesumAssured,
                    "current_value": data.spouseMoneyType * data.spousesumAssured,
                    "moneyDigits": data.spouseMoneyType,
                    "frequency": data.spouseFrequency,
                    "ppt": 1,
                    "policy_term": 1,
                    "rider_id": [sessionStorage.getItem('spouse_prof_det_id')],
                    "invest_id": data.spouseInvestId,
                    "admin_id":sessionStorage.getItem("admin_id")
                };

                axioInstance.post(`investment/add-investment`, Postdata)
                    .then(function (response) {

                        window.location.reload();

                    })
            });

    }


    const [terms, setTerms] = useState(null);
    // eslint-disable-next-line
    const [selfName, setSelfName] = useState();
    // eslint-disable-next-line
    const [spouseName, setSpouseName] = useState();
    useEffect(() => {

        axioInstance.post(`profile/term-data`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {
            const res = response.data[0];

            setSelfName(res.selfName);
            setSpouseName(res.spouseName);

            console.log(res.selfsumAssured.toString().length);

            //const moneyDigits = res.selfsumAssured.toString().length >= 8 ? 10000000 : 100000;
            //const spousemoneyDigits = res.spousesumAssured.toString().length >= 8 ? 10000000 : 100000;

            setTerms({ selftermInsurance: res.selftermInsurance, selfsumAssured: res.selfsumAssured, selfMoneyType: res.selfMoneyType, selfpremiumAmount: formatAmount(res.selfpremiumAmount), spousetermInsurance: res.spousetermInsurance, spousesumAssured: res.spousesumAssured, spouseMoneyType: res.spouseMoneyType, spousepremiumAmount: formatAmount(res.spousepremiumAmount), selfInvestId: res.selfInvestId, spouseInvestId: res.spouseInvestId, spouseFrequency: res.spouseFrequency, selfFrequency: res.selfFrequency });
        });

    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(terms);
        // eslint-disable-next-line
    }, [terms]);


    const [selfsumAssured, setSelfsumAssured] = useState();

    const [selfpremiumAmount, setSelfpremiumAmount] = useState();
    const [spousepremiumAmount, setSpousepremiumAmount] = useState();

    // eslint-disable-next-line
    const [maxSelf, setMaxSelf] = useState(3);
    // eslint-disable-next-line
    const [maxSpouse, setMaxSpouse] = useState(3);


    const handleChange = (e) => {

        const { value } = e.target;

        if (e.target.name === 'selfpremiumAmount') {
            setSelfpremiumAmount(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'spousepremiumAmount') {
            setSpousepremiumAmount(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === "selfsumAssured") {
            setSelfsumAssured(e.target.value);
            
            if (e.target.value.indexOf('.') !== -1) {
                setMaxSelf(5);
            }else{
                setMaxSelf(3);
            }

        }else if (e.target.name === "spousesumAssured") {
           
            if (e.target.value.indexOf('.') !== -1) {
                setMaxSelf(5);
            }else{
                setMaxSelf(3);
            }

        }

    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>

                <input type="hidden" name="selfInvestId" {...register("selfInvestId")} value="" />
                <input type="hidden" name="spouseInvestId" {...register("spouseInvestId")} value="" />

                <div className="form_prnt">
                    <div className="expandtab font12 color353 fw500">{self.full_name} Term Insurance</div>

                    <div className="custome_form">
                        <div className="form-row mt20">
                            <div className="col-md-12 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw500">Sum assured</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" {...register("selfsumAssured", { required: 'Sum assured is required' })} defaultValue={selfsumAssured} onChange={handleChange} maxLength={maxSelf} minLength={1} />
                                    <select className="custom-select font14" id="inputGroupSelect01" name='selfMoneyType' {...register("selfMoneyType")}>
                                        <option value="100000">Lakhs</option>
                                        <option value="10000000">Crore</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                            <span className="text-danger"> {errors.selfsumAssured && errors.selfsumAssured.message}</span>
                        </div>
                        <div className="form-row mt20">
                            <div className="col-md-12 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw500">Premium amount</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" name="selfpremiumAmount" autoComplete='off' {...register("selfpremiumAmount", { required: 'Premium Amount is required' })} onChange={handleChange} value={selfpremiumAmount} />
                                    <select className="custom-select font14" id="inputGroupSelect01" name='selfFrequency' {...register("selfFrequency")}>
                                        <option value="12">Monthly</option>
                                        <option value="4">Quarterly</option>
                                        <option value="2">Half Yearly</option>
                                        <option value="1" selected>Yearly</option>
                                        <option value="0">Lumpsum</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="formdevider mt25 mb25" />

                {
                    spouse && (<><div className="form_prnt">
                        <div className="expandtab font12 color353 fw500">{spouse.full_name} Term Insurance</div>

                        <div className="custome_form">
                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw500">Sum assured</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="spousesumAssured" {...register("spousesumAssured", { required: 'Sum assured is required' })} onChange={handleChange} maxLength={maxSpouse} minLength={1} />
                                        <select className="custom-select font14" id="inputGroupSelect01" name='spouseMoneyType' {...register("spouseMoneyType")}>
                                            <option value="100000">Lakhs</option>
                                            <option value="10000000">Crore</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                </div>
                                <span className="text-danger">{errors.spousesumAssured && errors.spousesumAssured.message}</span>
                            </div>
                            <div className="form-row mt20">
                                <div className="col-md-12 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw500">Premium amount</label>
                                    <div className="input-group selectarrow">
                                        <input type="text" className="form-control font14 color212 fw500" name="spousepremiumAmount" {...register("spousepremiumAmount", { required: 'Sum assured is required' })} onChange={handleChange} value={spousepremiumAmount} />
                                        <select className="custom-select font14" id="inputGroupSelect01" name='spouseFrequency' {...register("spouseFrequency")}>
                                            <option value="12">Monthly</option>
                                            <option value="4">Quarterly</option>
                                            <option value="2">Half Yearly</option>
                                            <option value="1" selected>Yearly</option>
                                            <option value="0">Lumpsum</option>
                                        </select>
                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                    </div>
                                    <span className="text-danger"> {errors.spousepremiumAmount && errors.spousepremiumAmount.message}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>)
                }


                <div className="saveBtn backsaveBtn">
                    <button type="button" className="btn btn-primary">Cancel </button>
                    <button type="submit" className="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
                </div>
            </form>
        </Fragment >
    )
}
