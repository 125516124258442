import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import SweetAlert from 'sweetalert2'
import ReCAPTCHA from 'react-google-recaptcha'
export default function Ulogin() {

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();
    const recaptchaRef = React.useRef();
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
    /*const [password, setpassword] = useState('')
    const [togglePassword, setTogglePassword] = useState(false)
    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }

    const handleChange = (e) => {
        const { value } = e.target;
        if (e.target.name === 'password') {
            setpassword(value);
        }
    }*/

    const handleRecaptchaChange = (value) => {
        // Set the state to true when ReCAPTCHA is verified
        setIsRecaptchaVerified(true);
    }

    useEffect(() => {

        const search = window.location.search;
        const params = new URLSearchParams(search);


        const email = params.get('email');
        const token = params.get('token');

        if ((email !== undefined && email !== null) && (token !== undefined && token !== null)) {


            const Postdata = {
                "email": email,
                "password": token
            };
            axioInstance.post(`admin/login`, Postdata)
                .then(function (response) {

                    if (response.data.status === 101) {
                        SweetAlert.fire("Warning", "" + response.data.msg + "", "warning");

                    } else {

                        SweetAlert.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Signed in Successfully',
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                            }
                        }).then(function () {

                            localStorage.setItem('admin_id', response.data.admin_id);
                            localStorage.setItem('admin_id_role', 1);
                            //localStorage.setItem('admin_role', response.data.role);
                            localStorage.setItem('admin_email', email);

                            sessionStorage.setItem('admin_id', response.data.admin_id);
                            sessionStorage.setItem('admin_name', response.data.admin_name);
                            sessionStorage.setItem('admin_role', response.data.role);
                            sessionStorage.setItem('admin_email', email);


                            window.location.href = "/admin-dashboard";

                        });


                    }

                })
                .catch(e => {
                    SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
                });

        }





    }, []);


    const onSubmit = data => {

        if (!isRecaptchaVerified) {
            // If ReCAPTCHA is not verified, show error message
            SweetAlert.fire("Warning", "Please verify that you are not a robot.", "warning");
            return;
        }

         // eslint-disable-next-line 
        const Postdata = {
            "email": data.emailAddress,
            "password": data.password
        };

        axioInstance.post(`admin/login`, Postdata)
            .then(function (response) {

                if (response.data.status === 101) {
                    SweetAlert.fire("Warning", "" + response.data.msg + "", "warning");

                } else {

                    SweetAlert.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Signed in Successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    }).then(function () {


                        localStorage.setItem('admin_id', response.data.admin_id);
                        sessionStorage.setItem('admin_id', response.data.admin_id);
                        sessionStorage.setItem('admin_name', response.data.admin_name);
                        sessionStorage.setItem('admin_role', response.data.role);
                        sessionStorage.setItem('admin_email', response.data.email_id);
                        window.location.href = "/admin-dashboard";

                    });


                }

            })
            .catch(e => {
                SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });

    }


    return (
        <>
            <div className="logincard">
                <div className="logincard_title pt30 pb30 pl35 pr35">
                    <div className="font18 color212 fw600">Login your account</div>
                    <div className="font13 fw500 color626">Join us and set your path towards Financial Freedom</div>
                </div>
                <div className="p35">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="font14 fw600 color212">Email<span className="colorfa8">*</span></div>
                        <div className="mt12 mobilefield">
                            <input type="text" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address"/>
                        </div>
                        <span className="text-danger font11"> {errors.emailAddress && errors.emailAddress.message}</span>

                        <div className="font14 fw600 color212 mt35">Password<span className="colorfa8">*</span></div>
                        <div className="mt12 mobilefield">
                            <input type={"password"} name="password" autoComplete='off' {...register("password", { required: 'Password is required', minLength: { value: 5, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' } })} placeholder="Password" />
                            {/*<div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>*/}
                        </div>
                        <span className="text-danger font11"> {errors.password && errors.password.message}</span>

                        <div className="recaptcha-container mt-3">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LfLyqopAAAAAMrrNZ0gu0_VZZtRh6uIhQc4TVCM"
                            onChange={handleRecaptchaChange}
                        />
                        </div>

                        <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary btn-lg btn-block font13 fw500 mt30 continuebtn">Continue</button>

                        <div className="signuptext mt30 font14 color263 fw500">Unable to access account?  <span className="color06d ml5 fw600">Forgot Password</span></div>

                        <div className="logintnctext font12 fw500 color485 mt35">
                            By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.

                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}
