import React, { Fragment, useEffect, useState } from 'react'
import axioInstance from '../axiosInstance';
import {  inWords } from '../global';
//import formatAmount from 'indian-currency-formatter';
import Chart from 'react-apexcharts'

export default function DashboardRightInsuranceGraphGrid({ setInsuranceGraph, insuranceGraphData, insuranceGraphType }) {

  console.log('insuranceGraphData', insuranceGraphData);

  useEffect(() => {

    const profile_id = sessionStorage.getItem('profile_id');
    const required = insuranceGraphType === "income" ? insuranceGraphData.grow_till : Math.round((insuranceGraphData.life_expectancy - insuranceGraphData.profile_age), 0);
    const cover = insuranceGraphType === "income" ? insuranceGraphData.suggestedoption2 : insuranceGraphData.suggestedoption1;
    const withdrawal = insuranceGraphType === "income" ? insuranceGraphData.income : insuranceGraphData.expense;
    const PostData = {
      "profile_id": profile_id,
      "cover": cover,
      "withdrawal": withdrawal,
      "required": required,
      "grow_perc": insuranceGraphData.post_return,
      "return_perc": insuranceGraphType === "income" ? insuranceGraphData.income_grow : insuranceGraphData.goal_inflation
    }
    axioInstance.post(`get-life-insurance-graph-data`, PostData).then(
      (response, data) => {

        setchartData({
          series: [{
            name: 'Withdrawal',
            data: response.data.withdrawalArr,
          }, {
            name: 'Nett',
            data: response.data.nettArr,
          }],
          options: {
            chart: {
              type: 'bar',
              height: 600,
              stacked: true,
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              },
              dataLabels: {
                enabled: false
              }
            },
            colors: ['#BB883A', '#0D6CCA'],
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '25%',
                borderRadius: 2,
                dataLabels: {
                  enabled: false
                }
              },
            },
            xaxis: {
              categories: response.data.yearsArr,
            },
            legend: {
              position: 'bottom',
              offsetY: 10
            },
            fill: {
              opacity: 1,
              colors: ['#BB883A', '#0D6CCA']
            }
          }
        })
      });



  }, [insuranceGraphData, insuranceGraphType]);


  // eslint-disable-next-line
  const [chartData, setchartData] = useState({
    series: [{
      name: 'PRODUCT A',
      data: [44, 55, 41, 67, 22, 43]
    }, {
      name: 'PRODUCT B',
      data: [13, 23, 20, 8, 13, 27]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 600,
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          borderRadius: 2,
          dataLabels: {
            enabled: false
          }
        },
      },
      xaxis: {
        categories: ['1', '2', '3', '4',
          '5', '6'
        ],
      },
      yaxis: {
        title: {
          text: 'Amount in Cr.'
        }
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    }
  });


  return (
    <Fragment>
      <div className="familycard_container">
        <div className="familycardtabs">
          <div className="db_cashflow_outer pt20 pl30 pr30">

            <div className="familycard_title">

              <div className="mt15 color182 font14 fw500">Suggested based on {insuranceGraphType === "income" ? <span className="fw700">Income</span> : <span className="fw700">Expense</span>}<div className="apply_roi_right"></div></div>




              <div className="title_viewmore">
                <span className="font12 fw600 color0d6 pointer" onClick={() => setInsuranceGraph('hide')}>Back <span className="bluerightarrow"></span></span> </div>
            </div>
            <div className='familycard_title'>
              <div className="font21 fw700 color182">{insuranceGraphType === "income" ? inWords(insuranceGraphData.suggestedoption2) : inWords(insuranceGraphData.suggestedoption1)}</div>
            </div>
            <div className="familycard_title">

              {

                insuranceGraphType === "income" ? <><div className="mt5 color6d7 font12 fw500">
                  Your Current Income <strong>{inWords(insuranceGraphData.income)}</strong> will keep growing at <strong>{Math.round(insuranceGraphData.income_grow * 100)}%</strong> for next <strong>{Math.round(insuranceGraphData.grow_till, 0)}</strong> years.<br />
                  The Assumed return during this period is <strong>{Math.round(insuranceGraphData.post_return * 100)}%.</strong>
                </div></> :
                  <>
                    <div className="mt5 color6d7 font12 fw500">
                      The allocated current expense of <strong>{inWords(insuranceGraphData.total_abcd)}</strong> will keep growing at <strong>{Math.round(insuranceGraphData.goal_inflation * 100, 2)}%</strong> for next <strong>{Math.round((insuranceGraphData.life_expectancy - insuranceGraphData.profile_age), 0)}</strong> years.<br />
                      The loans/ liabilities allocated to you is <strong>{inWords(insuranceGraphData.loan)}</strong>.<br />
                      The Assumed return during this period is <strong>{Math.round(insuranceGraphData.post_return * 100)}%.</strong>
                    </div>
                  </>
              }





            </div>
            <div className="graph_container">



              {
                /*
    <div id="chart">
                    <Chart options={chartData1.options} series={chartData1.series} height="600" type="bar" id="chartFirst1" />
                  </div>
                */
              }

              <div id="chart">
                <Chart options={chartData.options} series={chartData.series} height="600" type="bar" id="chartFirst" />
              </div>


            </div>

          </div>
        </div>
      </div>


    </Fragment>
  )
}
