import React from 'react'
import axioInstance from '../components/axiosInstance';
import SweetAlert from 'sweetalert2'
import { useEffect } from 'react';

export default function AutoLogin() {

   useEffect(() => {

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const email = params.get('email');
      const step = params.get("step");
      const admin_id = params.get('s');
      const Postdata = {
         "email": email
      };

 
      const adminId = localStorage.getItem('admin_id');
      if (adminId) {

         axioInstance.post(`admin/auto-login`, Postdata)
            .then(function (response) {

               if (response.data.status === 101) {
                  SweetAlert.fire({
                     toast: true,
                     icon: 'success',
                     title: 'No Plan Found/Created.',
                     animation: false,
                     position: 'top-right',
                     showConfirmButton: false,
                     timer: 4000,
                     timerProgressBar: true,
                     didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                     }
                  }).then(function () {

                     window.location.href = "/sign-in";

                  });
               } else {
                  SweetAlert.fire({
                     toast: true,
                     icon: 'success',
                     title: 'Signed in Successfully',
                     animation: false,
                     position: 'top-right',
                     showConfirmButton: false,
                     timer: 2000,
                     timerProgressBar: true,
                     didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                     }
                  }).then(function () {

                     sessionStorage.setItem('token', response.data.token);
                     const pdata = { "email": response.data.dec_email };

                     axioInstance.post(`profile/view`, pdata).then(function (response) {
                        sessionStorage.setItem('profile_id', response.data.profile_id);
                        sessionStorage.setItem('full_name', response.data.first_name + '\'s');
                        sessionStorage.setItem('profile_details_id', response.data.profile_details_id);
                        sessionStorage.setItem('admin_id', admin_id);
                        axioInstance.post(`login-logout-logs`, { user_type: 'adminclient', action: 'login', profile_id: response.data.profile_id, admin_id: admin_id }).then(function (response, data) {

                           if (step <= 5) {
                              window.location.href = "https://plan.finnovate.in/basic-profile";
                           } else {
                              window.location.href = "https://plan.finnovate.in/dashboard";
                           }

                        });

                     });

                     //

                  });
               }

            })
            .catch(e => {
               SweetAlert.fire("Warning", "" + e.response.data.message + "", "warning");
            });

      } else {
         SweetAlert.fire("Warning", "Session Expired. Login and try", "warning").then(function () {
            window.location.href = "ulogin";
         });
      }


   });

   return (<> <div>auto login</div></>)
}
