const nextStep = (step) => {
    if (step === 6) return;
    return (step + 1)
}

const prevStep = (step) => {
    if (step === 1) return;
    return (step - 1)
}


const nextsubStep = (step, count) => {

    if (step === count) {
        return;
    } else {
        return (step + 1);
    }

}

const prevsubStep = (step) => {
    if (step === 1) return;
    return (step - 1)
}

const logOut = () => {

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("profile_id");
    sessionStorage.removeItem("full_name");
    
    window.location.href = "/sign-in";
}


const ageCalculate = (dob) => {

    var today = new Date();
    var birthDate = new Date(dob);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    console.log('m' + birthDate.getMonth());
    console.log('month minus' + today.getMonth() - birthDate.getMonth())
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    return age_now;
}

const investmentCategories = [
    {
        "id": 1,
        "name": "Mutual Fund",
        "type": 4,
        "sub_type": 9,
        "icon": "mutualfunds_tab",
        "checked": false
    },
    {
        "id": 2,
        "name": "Fix Deposits",
        "type": 4,
        "sub_type": 9,
        "icon": "pig_tab",
        "checked": false
    },
    {
        "id": 3,
        "name": "Stocks",
        "type": 4,
        "sub_type": 9,
        "icon": "chartbar_tab",
        "checked": false
    },
    {
        "id": 4,
        "name": "Gold",
        "type": 4,
        "sub_type": 9,
        "icon": "gold_tab",
        "checked": false
    },
    {
        "id": 5,
        "name": "Real Estate",
        "type": 4,
        "sub_type": 9,
        "icon": "office_tab",
        "checked": false
    },
    {
        "id": 6,
        "name": "PPF/EPF",
        "type": 4,
        "sub_type": 9,
        "icon": "coins_tab",
        "checked": false
    },
    {
        "id": 7,
        "name": "NPS Fund",
        "type": 4,
        "sub_type": 9,
        "icon": "moneytime_tab",
        "checked": false
    },
    {
        "id": 8,
        "name": "Other Invest.",
        "type": 4,
        "sub_type": 9,
        "icon": "plant_tab",
        "checked": false
    }
];


const stepData = [
    {
        "step": 1,
        "sub_steps": 2,
        "name": "Basic Details",
        "icon": "basicIcon",
        status: true
    }, {
        "step": 2,
        "sub_steps": 2,
        "name": "Protection",
        "icon": "protection",
        status: false
    }, {
        "step": 3,
        "sub_steps": 3,
        "name": "Goals",
        "icon": "goal",
        status: false
    },
    {
        "step": 4,
        "sub_steps": 4,
        "name": "Cashflow",
        "icon": "cashflow",
        status: false
    },
    {
        "step": 5,
        "sub_steps": 2,
        "name": "Investment",
        "icon": "rupee",
        status: false
    }
];


const apiUrl = window.location.hostname === "localhost"
    ? "https://fpapi.finnovate.in/api/" :  window.location.hostname === "plan.finnovate.in" ? "https://planapi.finnovate.in/api/" : "https://fpapi.finnovate.in/api/";

const inWords = (num) => {


    let amount = Math.round(num);
    let data = 0;
    let v = '';
    let digits_count = amount.toString().length;

    if (digits_count >= 8) {
        data = amount / 10000000;
        v = 'Cr';
    } else if (digits_count === 7 || digits_count === 6) {
        data = amount / 100000;
        v = 'L';
    } else if (digits_count === 5 || digits_count === 4) {
        data = amount / 1000;
        v = 'K';
    }

    return data.toFixed(2) + ' ' + v;


}

const inFrequency = (num) => {

    let number = parseInt(num);
    let freq = '';
    switch (number) {
        case 1:
            freq = 'Yearly';
            break;
        case 12:
            freq = 'Monthly';
            break;
        case 6:
            freq = 'Half Yearly';
            break;
        case 4:
            freq = 'quarterly';
            break;
        default:
            break;
    }

    return freq;
}

const stepMaster = (num) => {

    let number = parseInt(num);
    let stepName = '';
    switch (number) {
        case 1:
            stepName = 'BasicDetails';
            break;
        case 2:
            stepName = 'Protection';
            break;
        case 3:
            stepName = 'Goals';
            break;
        case 4:
            stepName = 'Cashflow';
            break;
        case 5:
            stepName = 'Investments';
            break;
        case 6:
            stepName = 'Compeleted';
            break;
        default:
            break;
    }

    return stepName;
}

const registerVia = (num) => {

    let number = parseInt(num);
    let Name = '';
    switch (number) {
        case 1:
            Name = 'Admin';
            break;
        case 2:
            Name = 'Web';
            break;
        default:
            break;
    }

    return Name;

}

const sanitizeNumber = (num) => {

    let Number = '';
    if (num) {
        Number = num.replace(/,/g, "");
    } else {
        Number = num;
    }

    return Number;
}

const responsePopup = ({ text, open, handleConfirm }) => {
    return (
        <>
            <div className={open ? 'confirm show' : 'confirm'}>
                <div className="confirm-content">
                    <h4>CONFIRM</h4>
                    <div>
                        <h2>{text}</h2>
                        <p>This action is final...</p>
                    </div>
                </div>
                <div className="confirm-btns">
                    <button onClick={() => handleConfirm(true)}>YES</button>
                    <button onClick={() => handleConfirm(false)}>NO</button>
                </div>
            </div>
            <div
                className="overlay"
                onClick={() => handleConfirm(false)}
            />
        </>
    )
}

const req_params = new URLSearchParams(window.location.search);

export { nextStep, prevStep, nextsubStep, prevsubStep, ageCalculate, apiUrl, logOut, stepData, investmentCategories, inWords, inFrequency, stepMaster, registerVia, sanitizeNumber, responsePopup, req_params }

