import React, { Fragment, useState } from 'react'
import axioInstance from '../components/axiosInstance';
import SideImg from '../img/BasicProfile.png';

export default function ThirdPartyApi() {

    const [responseStatus, setResponseStatus] = useState('');
    const [textColor, setTextColor] = useState('');

    const GetRiskQuestions = () => {

        setResponseStatus('');
        setTextColor('');
        
        const Postdata = {
            "email": "anojincome@finnovate.in"
        };

        axioInstance.post(`risk/getriskquestions`, Postdata)
            .then(function (response, data) {
                setResponseStatus(response.data.status_type === "Success" ? "Success" : "Failed");
                setTextColor(response.data.status_type === "Success" ? "text-success" : "text-danger");
            })

    }

    const GetRiskProfileDetails = () => {

        setResponseStatus('');
        setTextColor('');

        const Postdata = {
            "email": "anojincome@finnovate.in"
        };

        axioInstance.post(`risk/getriskquestions`, Postdata)
            .then(function (response, data) {
                setResponseStatus(response.data.status_type === "Success" ? "Success" : "Failed");
                setTextColor(response.data.status_type === "Success" ? "text-success" : "text-danger");
            })

    }

    const GetRiskProfileSummary = () => {

        setResponseStatus('');
        setTextColor('');

        const Postdata = {
            "email": "anojincome@finnovate.in"
        };

        axioInstance.post(`risk/getriskquestions`, Postdata)
            .then(function (response, data) {
                setResponseStatus(response.data.status_type === "Success" ? "Success" : "Failed");
                setTextColor(response.data.status_type === "Success" ? "text-success" : "text-danger");
            })

    }
    


    return (
        <Fragment className="Ulogin">
            <div className='Ulogin'>
                <div className="innercontainer mb40">
                    <div className="mainwrapper">

                        <div className='form_title'>
                            <div className="mt30 font20 fw600 colorFFF">FINNOVATE RestAPI ENV</div>
                        </div>

                        <div className="row formWpr mt25">
                            <div className="col-lg-8 formouter">



                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">GetRiskQuestions</div>
                                        <div className="font15 color495 ">api/risk/getriskquestions</div>
                                    </div>
                                    <div>

                                        <button onClick={GetRiskQuestions} className="bluebtn font13 fw600">POST
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></button>
                                    </div>
                                </div>


                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">GenerateRiskScore</div>
                                        <div className="font15 color495 ">api/risk/generateriskscore</div>
                                    </div>
                                    <div>

                                        <a href='/sign-in' className="bluebtn font13 fw600">POST
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></a>
                                    </div>
                                </div>


                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">GetRiskProfileDetails</div>
                                        <div className="font15 color495 ">api/risk/getriskprofiledetails</div>
                                    </div>
                                    <div>

                                        <button onClick={GetRiskProfileDetails} className="bluebtn font13 fw600">POST
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></button>
                                    </div>
                                </div>


                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">GetRiskProfileSummary</div>
                                        <div className="font15 color495 ">api/risk/getriskprofilesummary</div>
                                    </div>
                                    <div>

                                        <button onClick={GetRiskProfileSummary} className="bluebtn font13 fw600">POST
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></button>
                                    </div>
                                </div>



                            </div>

                            <div className="col-lg-4 qouteouter d-none d-md-block">
                                <div className="qoutetext font18 fw500 color212 pl45 pr45">

                                  {responseStatus && (<> <h4>Response Status: <strong className={textColor}>{responseStatus}</strong></h4></>)} 
                                    
                                    
                                </div>


                                <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
