/**
 * Request url -  https://planv2.finnovatelive.in/finnfit?ref_by=FINNOVATE&utm_source=WEB&utm_campaign=WEB&utm_id=9398
 */
import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { req_params } from '../../components/global';
import SideImg from '../../img/BasicProfile.png';

export default function QnaFirst({ setSteps }) {

    const ref_by = (req_params.get('ref_by') ? req_params.get('ref_by') : 'FINNOVATE');
    const utm_source = (req_params.get('utm_source') ? req_params.get('utm_source') : 'WEB');
    const utm_campaign = (req_params.get('utm_campaign') ? req_params.get('utm_campaign') : 'WEB');
    const utm_id = (req_params.get('utm_id') ? req_params.get('utm_id') : '9398');

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    const onSubmit = data => {
        sessionStorage.setItem("QnaFirst", JSON.stringify(data))
        setSteps(2);
    }

    return (
        <Fragment>

            <div className="innercontainer mb40">
                <div className="mainwrapper">



                    <div className="row formWpr mt25">
                        <div className="col-lg-8 formouter">
                            <ul className="form_swipe_tab font12 fw600">
                                <li className="act">1</li>
                                <li>2</li>
                            </ul>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="formtitle flex_center justify_center">
                                    <div>

                                        <div className="font20 fw600">Know your Financial Fitness Score</div>
                                        <div className="font15 color495 ">Participate in the Quiz to Win your complimentary 30 Minute Session </div>
                                    </div>
                                    <div className='d-none d-md-block'>

                                        <button className="bluebtn font13 fw600" type='submit' disabled={!isDirty && !isValid}>Next
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></button>
                                    </div>
                                </div>

                                {/* Form */}
                                <div className="form_prnt pt25 pl35 pr35 pb35">
                                    <div className="custome_form">

                                        <div className="form-row">

                                            <div className="form-group col-md-6 pr20">
                                                <label htmlFor="fullName" className="font14 fw600">Your full name</label>
                                                <input type="text" className="form-control" id="fullName" name="fullName" autoComplete='off' {...register("fullName", { required: 'Name is required', minLength: { value: 5, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter full name" />
                                                <span className="text-danger"> {errors.fullName && errors.fullName.message}</span>
                                            </div>


                                        </div>
                                        {/*}
                                        <div className="form-row">
                                            <div className="form-group col-md-6 pr20">
                                                <label htmlFor="birthDate" className="font14 fw600">Date of birth</label>
                                                <input type="date" maxLength="4" pattern="[1-9][0-9]{3}" max={"9999-12-31"} className="form-control" id="birthDate" name="birthDate" autoComplete='off' {...register("birthDate", { required: 'DOB is required' })} placeholder="Select the date" />
                                                <span className="text-danger"> {errors.birthDate && errors.birthDate.message}</span>
                                            </div>
                                            <div className="form-group col-md-6 pl20">

                                            </div>
                                        </div>  */}

                                        <div className="form-row">
                                            <div className="form-group col-md-6 pr20">
                                                <label htmlFor="birthDate" className="font14 fw600">Age Range</label>
                                                <ul className="steps_tag font14 fw500 color182 mt15">
                                                    <li>
                                                        <label>
                                                            <input type="radio" name="age_range" value="0-30" id="dob_range_0_30" {...register("age_range", { required: 'age_range required' })} />
                                                            <span className="rdo_text_box hover_effect">
                                                                0-30
                                                            </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label>
                                                            <input type="radio" name="age_range" value="31-45" id="dob_range_31_45" {...register("age_range", { required: 'age_range required' })} />
                                                            <span className="rdo_text_box hover_effect">
                                                                31-45
                                                            </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label>
                                                            <input type="radio" name="age_range" value="46-59" id="dob_range_46-59" {...register("age_range", { required: 'age_range required' })} />
                                                            <span className="rdo_text_box hover_effect">
                                                                46-59
                                                            </span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label>
                                                            <input type="radio" name="age_range" value="60+" id="dob_range_61" {...register("age_range", { required: 'age_range required' })} />
                                                            <span className="rdo_text_box hover_effect">
                                                                60+
                                                            </span>
                                                        </label>
                                                    </li>
                                                </ul>
                                                <span className="text-danger"> {errors.age_range && errors.age_range.message}</span>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <input type="hidden" value={ref_by} name="ref_by" {...register("ref_by", { required: false })} />
                                <input type="hidden" value={utm_source} name="utm_source" {...register("utm_source", { required: false })} />
                                <input type="hidden" value={utm_campaign} name="utm_campaign" {...register("utm_campaign", { required: false })} />
                                <input type="hidden" value={utm_id} name="utm_id" {...register("utm_id", { required: false })} />
                                <div className="saveBtn d-block d-sm-none">
                                    <button type="submit" className="btn btn-primary" disabled={!isDirty && !isValid}> Get my FinnFit Report  <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" stroke-width="1.30435" stroke-miterlimit="10"></path></svg></span></button>
                                </div>

                            </form>
                            {/* Form End */}

                        </div>

                        <div className="col-lg-4 qouteouter d-none d-md-block">
                            <div className="qoutetext font18 fw500 color212 pl45 pr45">
                                <div className="qoutesign">
                                    &#8220;
                                </div>
                                Financial fitness refers to being in control of one's finances and having a plan in place to achieve financial goals.
                            </div>


                            <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="row mt50">
                <div className="col-lg-12">

                <p className='font11'>Disclaimer:</p>

<p className='font11'>This financial fitness score is provided for educational and informational purposes only and is not intended to provide investment, tax, or legal advice. The score is calculated based on the information you provide and is not a guarantee of future performance or success. Your personal financial situation is unique, and the score may not reflect your true financial health. It is important to seek professional advice before making any financial decisions. The score is not a substitute for professional judgment and is subject to change without notice. The provider of this score assumes no responsibility for any errors or omissions in the information provided. By using this score, you agree to hold harmless the provider and its affiliates for any losses or damages that may result from any inaccuracies or incompleteness of the information.</p>
                    <p className='font11'> Registration granted by SEBI, membership of BASL and certification from NISM in no way
                        guarantee performance of the intermediary or provide any assurance of returns to investors</p>
                    <p className='font11'>Mutual Fund investments are subject to market risks, read all scheme related documents carefully.</p>
                    <p className='font11'>Past performance is not indicative of future returns. Please consider your specific investment
                        requirements, risk tolerance, goal, time frame, risk and reward balance and the cost associated with the
                        investment before choosing a fund, or designing a portfolio that suits your needs. Performance and returns
                        of any investment portfolio can neither be predicted<br />
                        nor guaranteed.</p>
                    <p className='font11'>SEBI RIA Registration No: INA000013518 | Type of Registration: Non-Individual | Validity of registration:
                        June 19, 2019 - Perpetual | Address: The Summit Business Park, 7th Floor, Unit No. 703, Andheri - Kurla
                        Rd, Behind Guru Nanak Petrol Pump, Opp. PVR Cinema, Andheri East, Mumbai – 400069 | Contact No. 9819022600
                        | Principal Officer details: Nehal Mota, Email : nehal.mota@finnovate.in, Phone: 9152022446 |
                        Corresponding Local SEBI Office : SEBI Office Address: Office of Investor Assistance and Education, SEBI
                        Bhavan Plot No.C4-A, G Block, Bandra Kurla Complex, Bandra (E), Mumbai - 400 051</p>
                    <p className='font11'>AMFI Registered Distributor - ARN 52128 | Platform Partner : BSE (Member code 17548) | IRDAI Corporate
                        Agency : CA0703 | CIN: U67190MH2007PTC172272 | GST No : 27AABCF1124C2ZC | Website – <a href="http://www.finnovate.in" target="_blank" rel='noreferrer'>www.finnovate.in</a> Email – <a href="mailto:hello@finnovate.in">hello@finnovate.in</a></p>

                </div>

            </div>
        </Fragment>
    )
}
